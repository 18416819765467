/* CSS */
import "@js/parts/css";

/* JS */
import "@js/parts/lazyloading";

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from "alpinejs";
window.Alpine = Alpine;
Alpine.start();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}

/*! For license information please see main.js.LICENSE.txt */
!(function () {
  "use strict";
  function e(e) {
    return null !== e && "object" == typeof e && "constructor" in e && e.constructor === Object;
  }
  function t(r, a) {
    void 0 === r && (r = {}),
    void 0 === a && (a = {}),
      Object.keys(a).forEach((s) => {
        void 0 === r[s] ? (r[s] = a[s]) : e(a[s]) && e(r[s]) && Object.keys(a[s]).length > 0 && t(r[s], a[s]);
      });
  }
  const r = {
    body: {},
    addEventListener() {},
    removeEventListener() {},
    activeElement: { blur() {}, nodeName: "" },
    querySelector() {
      return null;
    },
    querySelectorAll() {
      return [];
    },
    getElementById() {
      return null;
    },
    createEvent() {
      return { initEvent() {} };
    },
    createElement() {
      return {
        children: [],
        childNodes: [],
        style: {},
        setAttribute() {},
        getElementsByTagName() {
          return [];
        },
      };
    },
    createElementNS() {
      return {};
    },
    importNode() {
      return null;
    },
    location: { hash: "", host: "", hostname: "", href: "", origin: "", pathname: "", protocol: "", search: "" },
  };
  function a() {
    const e = "undefined" != typeof document ? document : {};
    return t(e, r), e;
  }
  const s = {
    document: r,
    navigator: { userAgent: "" },
    location: { hash: "", host: "", hostname: "", href: "", origin: "", pathname: "", protocol: "", search: "" },
    history: { replaceState() {}, pushState() {}, go() {}, back() {} },
    CustomEvent: function () {
      return this;
    },
    addEventListener() {},
    removeEventListener() {},
    getComputedStyle() {
      return {
        getPropertyValue() {
          return "";
        },
      };
    },
    Image() {},
    Date() {},
    screen: {},
    setTimeout() {},
    clearTimeout() {},
    matchMedia() {
      return {};
    },
    requestAnimationFrame(e) {
      return "undefined" == typeof setTimeout ? (e(), null) : setTimeout(e, 0);
    },
    cancelAnimationFrame(e) {
      "undefined" != typeof setTimeout && clearTimeout(e);
    },
  };
  function i() {
    const e = "undefined" != typeof window ? window : {};
    return t(e, s), e;
  }
  function n(e) {
    return (
      void 0 === e && (e = ""),
        e
          .trim()
          .split(" ")
          .filter((e) => !!e.trim())
    );
  }
  function o(e, t) {
    return void 0 === t && (t = 0), setTimeout(e, t);
  }
  function l() {
    return Date.now();
  }
  function d(e, t) {
    void 0 === t && (t = "x");
    const r = i();
    let a, s, n;
    const o = (function (e) {
      const t = i();
      let r;
      return t.getComputedStyle && (r = t.getComputedStyle(e, null)), !r && e.currentStyle && (r = e.currentStyle), r || (r = e.style), r;
    })(e);
    return (
      r.WebKitCSSMatrix
        ? ((s = o.transform || o.webkitTransform),
        s.split(",").length > 6 &&
        (s = s
          .split(", ")
          .map((e) => e.replace(",", "."))
          .join(", ")),
          (n = new r.WebKitCSSMatrix("none" === s ? "" : s)))
        : ((n = o.MozTransform || o.OTransform || o.MsTransform || o.msTransform || o.transform || o.getPropertyValue("transform").replace("translate(", "matrix(1, 0, 0, 1,")), (a = n.toString().split(","))),
      "x" === t && (s = r.WebKitCSSMatrix ? n.m41 : 16 === a.length ? parseFloat(a[12]) : parseFloat(a[4])),
      "y" === t && (s = r.WebKitCSSMatrix ? n.m42 : 16 === a.length ? parseFloat(a[13]) : parseFloat(a[5])),
      s || 0
    );
  }
  function c(e) {
    return "object" == typeof e && null !== e && e.constructor && "Object" === Object.prototype.toString.call(e).slice(8, -1);
  }
  function u() {
    const e = Object(arguments.length <= 0 ? void 0 : arguments[0]),
      t = ["__proto__", "constructor", "prototype"];
    for (let a = 1; a < arguments.length; a += 1) {
      const s = a < 0 || arguments.length <= a ? void 0 : arguments[a];
      if (null != s && ((r = s), !("undefined" != typeof window && void 0 !== window.HTMLElement ? r instanceof HTMLElement : r && (1 === r.nodeType || 11 === r.nodeType)))) {
        const r = Object.keys(Object(s)).filter((e) => t.indexOf(e) < 0);
        for (let t = 0, a = r.length; t < a; t += 1) {
          const a = r[t],
            i = Object.getOwnPropertyDescriptor(s, a);
          void 0 !== i && i.enumerable && (c(e[a]) && c(s[a]) ? (s[a].__swiper__ ? (e[a] = s[a]) : u(e[a], s[a])) : !c(e[a]) && c(s[a]) ? ((e[a] = {}), s[a].__swiper__ ? (e[a] = s[a]) : u(e[a], s[a])) : (e[a] = s[a]));
        }
      }
    }
    var r;
    return e;
  }
  function p(e, t, r) {
    e.style.setProperty(t, r);
  }
  function m(e) {
    let { swiper: t, targetPosition: r, side: a } = e;
    const s = i(),
      n = -t.translate;
    let o,
      l = null;
    const d = t.params.speed;
    (t.wrapperEl.style.scrollSnapType = "none"), s.cancelAnimationFrame(t.cssModeFrameID);
    const c = r > n ? "next" : "prev",
      u = (e, t) => ("next" === c && e >= t) || ("prev" === c && e <= t),
      p = () => {
        (o = new Date().getTime()), null === l && (l = o);
        const e = Math.max(Math.min((o - l) / d, 1), 0),
          i = 0.5 - Math.cos(e * Math.PI) / 2;
        let c = n + i * (r - n);
        if ((u(c, r) && (c = r), t.wrapperEl.scrollTo({ [a]: c }), u(c, r)))
          return (
            (t.wrapperEl.style.overflow = "hidden"),
              (t.wrapperEl.style.scrollSnapType = ""),
              setTimeout(() => {
                (t.wrapperEl.style.overflow = ""), t.wrapperEl.scrollTo({ [a]: c });
              }),
              void s.cancelAnimationFrame(t.cssModeFrameID)
          );
        t.cssModeFrameID = s.requestAnimationFrame(p);
      };
    p();
  }
  function f(e) {
    return e.querySelector(".swiper-slide-transform") || (e.shadowRoot && e.shadowRoot.querySelector(".swiper-slide-transform")) || e;
  }
  function h(e, t) {
    return void 0 === t && (t = ""), [...e.children].filter((e) => e.matches(t));
  }
  function g(e) {
    try {
      return void console.warn(e);
    } catch (e) {}
  }
  function v(e, t) {
    void 0 === t && (t = []);
    const r = document.createElement(e);
    return r.classList.add(...(Array.isArray(t) ? t : n(t))), r;
  }
  function y(e) {
    const t = i(),
      r = a(),
      s = e.getBoundingClientRect(),
      n = r.body,
      o = e.clientTop || n.clientTop || 0,
      l = e.clientLeft || n.clientLeft || 0,
      d = e === t ? t.scrollY : e.scrollTop,
      c = e === t ? t.scrollX : e.scrollLeft;
    return { top: s.top + d - o, left: s.left + c - l };
  }
  function w(e, t) {
    return i().getComputedStyle(e, null).getPropertyValue(t);
  }
  function b(e) {
    let t,
      r = e;
    if (r) {
      for (t = 0; null !== (r = r.previousSibling); ) 1 === r.nodeType && (t += 1);
      return t;
    }
  }
  function x(e, t) {
    const r = [];
    let a = e.parentElement;
    for (; a; ) t ? a.matches(t) && r.push(a) : r.push(a), (a = a.parentElement);
    return r;
  }
  function E(e, t) {
    t &&
    e.addEventListener("transitionend", function r(a) {
      a.target === e && (t.call(e, a), e.removeEventListener("transitionend", r));
    });
  }
  function S(e, t, r) {
    const a = i();
    return r
      ? e["width" === t ? "offsetWidth" : "offsetHeight"] +
      parseFloat(a.getComputedStyle(e, null).getPropertyValue("width" === t ? "margin-right" : "margin-top")) +
      parseFloat(a.getComputedStyle(e, null).getPropertyValue("width" === t ? "margin-left" : "margin-bottom"))
      : e.offsetWidth;
  }
  function T(e) {
    return (Array.isArray(e) ? e : [e]).filter((e) => !!e);
  }
  let M, C, L;
  function P() {
    return (
      M ||
      (M = (function () {
        const e = i(),
          t = a();
        return { smoothScroll: t.documentElement && t.documentElement.style && "scrollBehavior" in t.documentElement.style, touch: !!("ontouchstart" in e || (e.DocumentTouch && t instanceof e.DocumentTouch)) };
      })()),
        M
    );
  }
  function I(e) {
    return (
      void 0 === e && (e = {}),
      C ||
      (C = (function (e) {
        let { userAgent: t } = void 0 === e ? {} : e;
        const r = P(),
          a = i(),
          s = a.navigator.platform,
          n = t || a.navigator.userAgent,
          o = { ios: !1, android: !1 },
          l = a.screen.width,
          d = a.screen.height,
          c = n.match(/(Android);?[\s\/]+([\d.]+)?/);
        let u = n.match(/(iPad).*OS\s([\d_]+)/);
        const p = n.match(/(iPod)(.*OS\s([\d_]+))?/),
          m = !u && n.match(/(iPhone\sOS|iOS)\s([\d_]+)/),
          f = "Win32" === s;
        let h = "MacIntel" === s;
        return (
          !u &&
          h &&
          r.touch &&
          ["1024x1366", "1366x1024", "834x1194", "1194x834", "834x1112", "1112x834", "768x1024", "1024x768", "820x1180", "1180x820", "810x1080", "1080x810"].indexOf(`${l}x${d}`) >= 0 &&
          ((u = n.match(/(Version)\/([\d.]+)/)), u || (u = [0, 1, "13_0_0"]), (h = !1)),
          c && !f && ((o.os = "android"), (o.android = !0)),
          (u || m || p) && ((o.os = "ios"), (o.ios = !0)),
            o
        );
      })(e)),
        C
    );
  }
  var O = {
    on(e, t, r) {
      const a = this;
      if (!a.eventsListeners || a.destroyed) return a;
      if ("function" != typeof t) return a;
      const s = r ? "unshift" : "push";
      return (
        e.split(" ").forEach((e) => {
          a.eventsListeners[e] || (a.eventsListeners[e] = []), a.eventsListeners[e][s](t);
        }),
          a
      );
    },
    once(e, t, r) {
      const a = this;
      if (!a.eventsListeners || a.destroyed) return a;
      if ("function" != typeof t) return a;
      function s() {
        a.off(e, s), s.__emitterProxy && delete s.__emitterProxy;
        for (var r = arguments.length, i = new Array(r), n = 0; n < r; n++) i[n] = arguments[n];
        t.apply(a, i);
      }
      return (s.__emitterProxy = t), a.on(e, s, r);
    },
    onAny(e, t) {
      const r = this;
      if (!r.eventsListeners || r.destroyed) return r;
      if ("function" != typeof e) return r;
      const a = t ? "unshift" : "push";
      return r.eventsAnyListeners.indexOf(e) < 0 && r.eventsAnyListeners[a](e), r;
    },
    offAny(e) {
      const t = this;
      if (!t.eventsListeners || t.destroyed) return t;
      if (!t.eventsAnyListeners) return t;
      const r = t.eventsAnyListeners.indexOf(e);
      return r >= 0 && t.eventsAnyListeners.splice(r, 1), t;
    },
    off(e, t) {
      const r = this;
      return !r.eventsListeners || r.destroyed
        ? r
        : r.eventsListeners
          ? (e.split(" ").forEach((e) => {
            void 0 === t
              ? (r.eventsListeners[e] = [])
              : r.eventsListeners[e] &&
              r.eventsListeners[e].forEach((a, s) => {
                (a === t || (a.__emitterProxy && a.__emitterProxy === t)) && r.eventsListeners[e].splice(s, 1);
              });
          }),
            r)
          : r;
    },
    emit() {
      const e = this;
      if (!e.eventsListeners || e.destroyed) return e;
      if (!e.eventsListeners) return e;
      let t, r, a;
      for (var s = arguments.length, i = new Array(s), n = 0; n < s; n++) i[n] = arguments[n];
      return (
        "string" == typeof i[0] || Array.isArray(i[0]) ? ((t = i[0]), (r = i.slice(1, i.length)), (a = e)) : ((t = i[0].events), (r = i[0].data), (a = i[0].context || e)),
          r.unshift(a),
          (Array.isArray(t) ? t : t.split(" ")).forEach((t) => {
            e.eventsAnyListeners &&
            e.eventsAnyListeners.length &&
            e.eventsAnyListeners.forEach((e) => {
              e.apply(a, [t, ...r]);
            }),
            e.eventsListeners &&
            e.eventsListeners[t] &&
            e.eventsListeners[t].forEach((e) => {
              e.apply(a, r);
            });
          }),
          e
      );
    },
  };
  const k = (e, t, r) => {
      t && !e.classList.contains(r) ? e.classList.add(r) : !t && e.classList.contains(r) && e.classList.remove(r);
    },
    A = (e, t) => {
      if (!e || e.destroyed || !e.params) return;
      const r = t.closest(e.isElement ? "swiper-slide" : `.${e.params.slideClass}`);
      if (r) {
        let t = r.querySelector(`.${e.params.lazyPreloaderClass}`);
        !t &&
        e.isElement &&
        (r.shadowRoot
          ? (t = r.shadowRoot.querySelector(`.${e.params.lazyPreloaderClass}`))
          : requestAnimationFrame(() => {
            r.shadowRoot && ((t = r.shadowRoot.querySelector(`.${e.params.lazyPreloaderClass}`)), t && t.remove());
          })),
        t && t.remove();
      }
    },
    z = (e, t) => {
      if (!e.slides[t]) return;
      const r = e.slides[t].querySelector('[loading="lazy"]');
      r && r.removeAttribute("loading");
    },
    $ = (e) => {
      if (!e || e.destroyed || !e.params) return;
      let t = e.params.lazyPreloadPrevNext;
      const r = e.slides.length;
      if (!r || !t || t < 0) return;
      t = Math.min(t, r);
      const a = "auto" === e.params.slidesPerView ? e.slidesPerViewDynamic() : Math.ceil(e.params.slidesPerView),
        s = e.activeIndex;
      if (e.params.grid && e.params.grid.rows > 1) {
        const r = s,
          i = [r - t];
        return (
          i.push(...Array.from({ length: t }).map((e, t) => r + a + t)),
            void e.slides.forEach((t, r) => {
              i.includes(t.column) && z(e, r);
            })
        );
      }
      const i = s + a - 1;
      if (e.params.rewind || e.params.loop)
        for (let a = s - t; a <= i + t; a += 1) {
          const t = ((a % r) + r) % r;
          (t < s || t > i) && z(e, t);
        }
      else for (let a = Math.max(s - t, 0); a <= Math.min(i + t, r - 1); a += 1) a !== s && (a > i || a < s) && z(e, a);
    };
  var D = {
    updateSize: function () {
      const e = this;
      let t, r;
      const a = e.el;
      (t = void 0 !== e.params.width && null !== e.params.width ? e.params.width : a.clientWidth),
        (r = void 0 !== e.params.height && null !== e.params.height ? e.params.height : a.clientHeight),
      (0 === t && e.isHorizontal()) ||
      (0 === r && e.isVertical()) ||
      ((t = t - parseInt(w(a, "padding-left") || 0, 10) - parseInt(w(a, "padding-right") || 0, 10)),
        (r = r - parseInt(w(a, "padding-top") || 0, 10) - parseInt(w(a, "padding-bottom") || 0, 10)),
      Number.isNaN(t) && (t = 0),
      Number.isNaN(r) && (r = 0),
        Object.assign(e, { width: t, height: r, size: e.isHorizontal() ? t : r }));
    },
    updateSlides: function () {
      const e = this;
      function t(t, r) {
        return parseFloat(t.getPropertyValue(e.getDirectionLabel(r)) || 0);
      }
      const r = e.params,
        { wrapperEl: a, slidesEl: s, size: i, rtlTranslate: n, wrongRTL: o } = e,
        l = e.virtual && r.virtual.enabled,
        d = l ? e.virtual.slides.length : e.slides.length,
        c = h(s, `.${e.params.slideClass}, swiper-slide`),
        u = l ? e.virtual.slides.length : c.length;
      let m = [];
      const f = [],
        g = [];
      let v = r.slidesOffsetBefore;
      "function" == typeof v && (v = r.slidesOffsetBefore.call(e));
      let y = r.slidesOffsetAfter;
      "function" == typeof y && (y = r.slidesOffsetAfter.call(e));
      const b = e.snapGrid.length,
        x = e.slidesGrid.length;
      let E = r.spaceBetween,
        T = -v,
        M = 0,
        C = 0;
      if (void 0 === i) return;
      "string" == typeof E && E.indexOf("%") >= 0 ? (E = (parseFloat(E.replace("%", "")) / 100) * i) : "string" == typeof E && (E = parseFloat(E)),
        (e.virtualSize = -E),
        c.forEach((e) => {
          n ? (e.style.marginLeft = "") : (e.style.marginRight = ""), (e.style.marginBottom = ""), (e.style.marginTop = "");
        }),
      r.centeredSlides && r.cssMode && (p(a, "--swiper-centered-offset-before", ""), p(a, "--swiper-centered-offset-after", ""));
      const L = r.grid && r.grid.rows > 1 && e.grid;
      let P;
      L ? e.grid.initSlides(c) : e.grid && e.grid.unsetSlides();
      const I = "auto" === r.slidesPerView && r.breakpoints && Object.keys(r.breakpoints).filter((e) => void 0 !== r.breakpoints[e].slidesPerView).length > 0;
      for (let a = 0; a < u; a += 1) {
        let s;
        if (((P = 0), c[a] && (s = c[a]), L && e.grid.updateSlide(a, s, c), !c[a] || "none" !== w(s, "display"))) {
          if ("auto" === r.slidesPerView) {
            I && (c[a].style[e.getDirectionLabel("width")] = "");
            const i = getComputedStyle(s),
              n = s.style.transform,
              o = s.style.webkitTransform;
            if ((n && (s.style.transform = "none"), o && (s.style.webkitTransform = "none"), r.roundLengths)) P = e.isHorizontal() ? S(s, "width", !0) : S(s, "height", !0);
            else {
              const e = t(i, "width"),
                r = t(i, "padding-left"),
                a = t(i, "padding-right"),
                n = t(i, "margin-left"),
                o = t(i, "margin-right"),
                l = i.getPropertyValue("box-sizing");
              if (l && "border-box" === l) P = e + n + o;
              else {
                const { clientWidth: t, offsetWidth: i } = s;
                P = e + r + a + n + o + (i - t);
              }
            }
            n && (s.style.transform = n), o && (s.style.webkitTransform = o), r.roundLengths && (P = Math.floor(P));
          } else (P = (i - (r.slidesPerView - 1) * E) / r.slidesPerView), r.roundLengths && (P = Math.floor(P)), c[a] && (c[a].style[e.getDirectionLabel("width")] = `${P}px`);
          c[a] && (c[a].swiperSlideSize = P),
            g.push(P),
            r.centeredSlides
              ? ((T = T + P / 2 + M / 2 + E),
              0 === M && 0 !== a && (T = T - i / 2 - E),
              0 === a && (T = T - i / 2 - E),
              Math.abs(T) < 0.001 && (T = 0),
              r.roundLengths && (T = Math.floor(T)),
              C % r.slidesPerGroup == 0 && m.push(T),
                f.push(T))
              : (r.roundLengths && (T = Math.floor(T)), (C - Math.min(e.params.slidesPerGroupSkip, C)) % e.params.slidesPerGroup == 0 && m.push(T), f.push(T), (T = T + P + E)),
            (e.virtualSize += P + E),
            (M = P),
            (C += 1);
        }
      }
      if (
        ((e.virtualSize = Math.max(e.virtualSize, i) + y),
        n && o && ("slide" === r.effect || "coverflow" === r.effect) && (a.style.width = `${e.virtualSize + E}px`),
        r.setWrapperSize && (a.style[e.getDirectionLabel("width")] = `${e.virtualSize + E}px`),
        L && e.grid.updateWrapperSize(P, m),
          !r.centeredSlides)
      ) {
        const t = [];
        for (let a = 0; a < m.length; a += 1) {
          let s = m[a];
          r.roundLengths && (s = Math.floor(s)), m[a] <= e.virtualSize - i && t.push(s);
        }
        (m = t), Math.floor(e.virtualSize - i) - Math.floor(m[m.length - 1]) > 1 && m.push(e.virtualSize - i);
      }
      if (l && r.loop) {
        const t = g[0] + E;
        if (r.slidesPerGroup > 1) {
          const a = Math.ceil((e.virtual.slidesBefore + e.virtual.slidesAfter) / r.slidesPerGroup),
            s = t * r.slidesPerGroup;
          for (let e = 0; e < a; e += 1) m.push(m[m.length - 1] + s);
        }
        for (let a = 0; a < e.virtual.slidesBefore + e.virtual.slidesAfter; a += 1) 1 === r.slidesPerGroup && m.push(m[m.length - 1] + t), f.push(f[f.length - 1] + t), (e.virtualSize += t);
      }
      if ((0 === m.length && (m = [0]), 0 !== E)) {
        const t = e.isHorizontal() && n ? "marginLeft" : e.getDirectionLabel("marginRight");
        c.filter((e, t) => !(r.cssMode && !r.loop) || t !== c.length - 1).forEach((e) => {
          e.style[t] = `${E}px`;
        });
      }
      if (r.centeredSlides && r.centeredSlidesBounds) {
        let e = 0;
        g.forEach((t) => {
          e += t + (E || 0);
        }),
          (e -= E);
        const t = e - i;
        m = m.map((e) => (e <= 0 ? -v : e > t ? t + y : e));
      }
      if (r.centerInsufficientSlides) {
        let e = 0;
        if (
          (g.forEach((t) => {
            e += t + (E || 0);
          }),
            (e -= E),
          e < i)
        ) {
          const t = (i - e) / 2;
          m.forEach((e, r) => {
            m[r] = e - t;
          }),
            f.forEach((e, r) => {
              f[r] = e + t;
            });
        }
      }
      if ((Object.assign(e, { slides: c, snapGrid: m, slidesGrid: f, slidesSizesGrid: g }), r.centeredSlides && r.cssMode && !r.centeredSlidesBounds)) {
        p(a, "--swiper-centered-offset-before", -m[0] + "px"), p(a, "--swiper-centered-offset-after", e.size / 2 - g[g.length - 1] / 2 + "px");
        const t = -e.snapGrid[0],
          r = -e.slidesGrid[0];
        (e.snapGrid = e.snapGrid.map((e) => e + t)), (e.slidesGrid = e.slidesGrid.map((e) => e + r));
      }
      if (
        (u !== d && e.emit("slidesLengthChange"),
        m.length !== b && (e.params.watchOverflow && e.checkOverflow(), e.emit("snapGridLengthChange")),
        f.length !== x && e.emit("slidesGridLengthChange"),
        r.watchSlidesProgress && e.updateSlidesOffset(),
          e.emit("slidesUpdated"),
          !(l || r.cssMode || ("slide" !== r.effect && "fade" !== r.effect)))
      ) {
        const t = `${r.containerModifierClass}backface-hidden`,
          a = e.el.classList.contains(t);
        u <= r.maxBackfaceHiddenSlides ? a || e.el.classList.add(t) : a && e.el.classList.remove(t);
      }
    },
    updateAutoHeight: function (e) {
      const t = this,
        r = [],
        a = t.virtual && t.params.virtual.enabled;
      let s,
        i = 0;
      "number" == typeof e ? t.setTransition(e) : !0 === e && t.setTransition(t.params.speed);
      const n = (e) => (a ? t.slides[t.getSlideIndexByData(e)] : t.slides[e]);
      if ("auto" !== t.params.slidesPerView && t.params.slidesPerView > 1)
        if (t.params.centeredSlides)
          (t.visibleSlides || []).forEach((e) => {
            r.push(e);
          });
        else
          for (s = 0; s < Math.ceil(t.params.slidesPerView); s += 1) {
            const e = t.activeIndex + s;
            if (e > t.slides.length && !a) break;
            r.push(n(e));
          }
      else r.push(n(t.activeIndex));
      for (s = 0; s < r.length; s += 1)
        if (void 0 !== r[s]) {
          const e = r[s].offsetHeight;
          i = e > i ? e : i;
        }
      (i || 0 === i) && (t.wrapperEl.style.height = `${i}px`);
    },
    updateSlidesOffset: function () {
      const e = this,
        t = e.slides,
        r = e.isElement ? (e.isHorizontal() ? e.wrapperEl.offsetLeft : e.wrapperEl.offsetTop) : 0;
      for (let a = 0; a < t.length; a += 1) t[a].swiperSlideOffset = (e.isHorizontal() ? t[a].offsetLeft : t[a].offsetTop) - r - e.cssOverflowAdjustment();
    },
    updateSlidesProgress: function (e) {
      void 0 === e && (e = (this && this.translate) || 0);
      const t = this,
        r = t.params,
        { slides: a, rtlTranslate: s, snapGrid: i } = t;
      if (0 === a.length) return;
      void 0 === a[0].swiperSlideOffset && t.updateSlidesOffset();
      let n = -e;
      s && (n = e),
        a.forEach((e) => {
          e.classList.remove(r.slideVisibleClass, r.slideFullyVisibleClass);
        }),
        (t.visibleSlidesIndexes = []),
        (t.visibleSlides = []);
      let o = r.spaceBetween;
      "string" == typeof o && o.indexOf("%") >= 0 ? (o = (parseFloat(o.replace("%", "")) / 100) * t.size) : "string" == typeof o && (o = parseFloat(o));
      for (let e = 0; e < a.length; e += 1) {
        const l = a[e];
        let d = l.swiperSlideOffset;
        r.cssMode && r.centeredSlides && (d -= a[0].swiperSlideOffset);
        const c = (n + (r.centeredSlides ? t.minTranslate() : 0) - d) / (l.swiperSlideSize + o),
          u = (n - i[0] + (r.centeredSlides ? t.minTranslate() : 0) - d) / (l.swiperSlideSize + o),
          p = -(n - d),
          m = p + t.slidesSizesGrid[e],
          f = p >= 0 && p <= t.size - t.slidesSizesGrid[e];
        ((p >= 0 && p < t.size - 1) || (m > 1 && m <= t.size) || (p <= 0 && m >= t.size)) && (t.visibleSlides.push(l), t.visibleSlidesIndexes.push(e), a[e].classList.add(r.slideVisibleClass)),
        f && a[e].classList.add(r.slideFullyVisibleClass),
          (l.progress = s ? -c : c),
          (l.originalProgress = s ? -u : u);
      }
    },
    updateProgress: function (e) {
      const t = this;
      if (void 0 === e) {
        const r = t.rtlTranslate ? -1 : 1;
        e = (t && t.translate && t.translate * r) || 0;
      }
      const r = t.params,
        a = t.maxTranslate() - t.minTranslate();
      let { progress: s, isBeginning: i, isEnd: n, progressLoop: o } = t;
      const l = i,
        d = n;
      if (0 === a) (s = 0), (i = !0), (n = !0);
      else {
        s = (e - t.minTranslate()) / a;
        const r = Math.abs(e - t.minTranslate()) < 1,
          o = Math.abs(e - t.maxTranslate()) < 1;
        (i = r || s <= 0), (n = o || s >= 1), r && (s = 0), o && (s = 1);
      }
      if (r.loop) {
        const r = t.getSlideIndexByData(0),
          a = t.getSlideIndexByData(t.slides.length - 1),
          s = t.slidesGrid[r],
          i = t.slidesGrid[a],
          n = t.slidesGrid[t.slidesGrid.length - 1],
          l = Math.abs(e);
        (o = l >= s ? (l - s) / n : (l + n - i) / n), o > 1 && (o -= 1);
      }
      Object.assign(t, { progress: s, progressLoop: o, isBeginning: i, isEnd: n }),
      (r.watchSlidesProgress || (r.centeredSlides && r.autoHeight)) && t.updateSlidesProgress(e),
      i && !l && t.emit("reachBeginning toEdge"),
      n && !d && t.emit("reachEnd toEdge"),
      ((l && !i) || (d && !n)) && t.emit("fromEdge"),
        t.emit("progress", s);
    },
    updateSlidesClasses: function () {
      const e = this,
        { slides: t, params: r, slidesEl: a, activeIndex: s } = e,
        i = e.virtual && r.virtual.enabled,
        n = e.grid && r.grid && r.grid.rows > 1,
        o = (e) => h(a, `.${r.slideClass}${e}, swiper-slide${e}`)[0];
      let l, d, c;
      if (i)
        if (r.loop) {
          let t = s - e.virtual.slidesBefore;
          t < 0 && (t = e.virtual.slides.length + t), t >= e.virtual.slides.length && (t -= e.virtual.slides.length), (l = o(`[data-swiper-slide-index="${t}"]`));
        } else l = o(`[data-swiper-slide-index="${s}"]`);
      else n ? ((l = t.filter((e) => e.column === s)[0]), (c = t.filter((e) => e.column === s + 1)[0]), (d = t.filter((e) => e.column === s - 1)[0])) : (l = t[s]);
      l &&
      (n ||
        ((c = (function (e, t) {
          const r = [];
          for (; e.nextElementSibling; ) {
            const a = e.nextElementSibling;
            t ? a.matches(t) && r.push(a) : r.push(a), (e = a);
          }
          return r;
        })(l, `.${r.slideClass}, swiper-slide`)[0]),
        r.loop && !c && (c = t[0]),
          (d = (function (e, t) {
            const r = [];
            for (; e.previousElementSibling; ) {
              const a = e.previousElementSibling;
              t ? a.matches(t) && r.push(a) : r.push(a), (e = a);
            }
            return r;
          })(l, `.${r.slideClass}, swiper-slide`)[0]),
        r.loop && 0 === !d && (d = t[t.length - 1]))),
        t.forEach((e) => {
          k(e, e === l, r.slideActiveClass), k(e, e === c, r.slideNextClass), k(e, e === d, r.slidePrevClass);
        }),
        e.emitSlidesClasses();
    },
    updateActiveIndex: function (e) {
      const t = this,
        r = t.rtlTranslate ? t.translate : -t.translate,
        { snapGrid: a, params: s, activeIndex: i, realIndex: n, snapIndex: o } = t;
      let l,
        d = e;
      const c = (e) => {
        let r = e - t.virtual.slidesBefore;
        return r < 0 && (r = t.virtual.slides.length + r), r >= t.virtual.slides.length && (r -= t.virtual.slides.length), r;
      };
      if (
        (void 0 === d &&
        (d = (function (e) {
          const { slidesGrid: t, params: r } = e,
            a = e.rtlTranslate ? e.translate : -e.translate;
          let s;
          for (let e = 0; e < t.length; e += 1) void 0 !== t[e + 1] ? (a >= t[e] && a < t[e + 1] - (t[e + 1] - t[e]) / 2 ? (s = e) : a >= t[e] && a < t[e + 1] && (s = e + 1)) : a >= t[e] && (s = e);
          return r.normalizeSlideIndex && (s < 0 || void 0 === s) && (s = 0), s;
        })(t)),
        a.indexOf(r) >= 0)
      )
        l = a.indexOf(r);
      else {
        const e = Math.min(s.slidesPerGroupSkip, d);
        l = e + Math.floor((d - e) / s.slidesPerGroup);
      }
      if ((l >= a.length && (l = a.length - 1), d === i && !t.params.loop)) return void (l !== o && ((t.snapIndex = l), t.emit("snapIndexChange")));
      if (d === i && t.params.loop && t.virtual && t.params.virtual.enabled) return void (t.realIndex = c(d));
      const u = t.grid && s.grid && s.grid.rows > 1;
      let p;
      if (t.virtual && s.virtual.enabled && s.loop) p = c(d);
      else if (u) {
        const e = t.slides.filter((e) => e.column === d)[0];
        let r = parseInt(e.getAttribute("data-swiper-slide-index"), 10);
        Number.isNaN(r) && (r = Math.max(t.slides.indexOf(e), 0)), (p = Math.floor(r / s.grid.rows));
      } else if (t.slides[d]) {
        const e = t.slides[d].getAttribute("data-swiper-slide-index");
        p = e ? parseInt(e, 10) : d;
      } else p = d;
      Object.assign(t, { previousSnapIndex: o, snapIndex: l, previousRealIndex: n, realIndex: p, previousIndex: i, activeIndex: d }),
      t.initialized && $(t),
        t.emit("activeIndexChange"),
        t.emit("snapIndexChange"),
      (t.initialized || t.params.runCallbacksOnInit) && (n !== p && t.emit("realIndexChange"), t.emit("slideChange"));
    },
    updateClickedSlide: function (e, t) {
      const r = this,
        a = r.params;
      let s = e.closest(`.${a.slideClass}, swiper-slide`);
      !s &&
      r.isElement &&
      t &&
      t.length > 1 &&
      t.includes(e) &&
      [...t.slice(t.indexOf(e) + 1, t.length)].forEach((e) => {
        !s && e.matches && e.matches(`.${a.slideClass}, swiper-slide`) && (s = e);
      });
      let i,
        n = !1;
      if (s)
        for (let e = 0; e < r.slides.length; e += 1)
          if (r.slides[e] === s) {
            (n = !0), (i = e);
            break;
          }
      if (!s || !n) return (r.clickedSlide = void 0), void (r.clickedIndex = void 0);
      (r.clickedSlide = s),
        r.virtual && r.params.virtual.enabled ? (r.clickedIndex = parseInt(s.getAttribute("data-swiper-slide-index"), 10)) : (r.clickedIndex = i),
      a.slideToClickedSlide && void 0 !== r.clickedIndex && r.clickedIndex !== r.activeIndex && r.slideToClickedSlide();
    },
  };
  function G(e) {
    let { swiper: t, runCallbacks: r, direction: a, step: s } = e;
    const { activeIndex: i, previousIndex: n } = t;
    let o = a;
    if ((o || (o = i > n ? "next" : i < n ? "prev" : "reset"), t.emit(`transition${s}`), r && i !== n)) {
      if ("reset" === o) return void t.emit(`slideResetTransition${s}`);
      t.emit(`slideChangeTransition${s}`), "next" === o ? t.emit(`slideNextTransition${s}`) : t.emit(`slidePrevTransition${s}`);
    }
  }
  var B = {
      slideTo: function (e, t, r, a, s) {
        void 0 === e && (e = 0), void 0 === r && (r = !0), "string" == typeof e && (e = parseInt(e, 10));
        const i = this;
        let n = e;
        n < 0 && (n = 0);
        const { params: o, snapGrid: l, slidesGrid: d, previousIndex: c, activeIndex: u, rtlTranslate: p, wrapperEl: f, enabled: h } = i;
        if ((!h && !a && !s) || i.destroyed || (i.animating && o.preventInteractionOnTransition)) return !1;
        void 0 === t && (t = i.params.speed);
        const g = Math.min(i.params.slidesPerGroupSkip, n);
        let v = g + Math.floor((n - g) / i.params.slidesPerGroup);
        v >= l.length && (v = l.length - 1);
        const y = -l[v];
        if (o.normalizeSlideIndex)
          for (let e = 0; e < d.length; e += 1) {
            const t = -Math.floor(100 * y),
              r = Math.floor(100 * d[e]),
              a = Math.floor(100 * d[e + 1]);
            void 0 !== d[e + 1] ? (t >= r && t < a - (a - r) / 2 ? (n = e) : t >= r && t < a && (n = e + 1)) : t >= r && (n = e);
          }
        if (i.initialized && n !== u) {
          if (!i.allowSlideNext && (p ? y > i.translate && y > i.minTranslate() : y < i.translate && y < i.minTranslate())) return !1;
          if (!i.allowSlidePrev && y > i.translate && y > i.maxTranslate() && (u || 0) !== n) return !1;
        }
        let w;
        if ((n !== (c || 0) && r && i.emit("beforeSlideChangeStart"), i.updateProgress(y), (w = n > u ? "next" : n < u ? "prev" : "reset"), (p && -y === i.translate) || (!p && y === i.translate)))
          return i.updateActiveIndex(n), o.autoHeight && i.updateAutoHeight(), i.updateSlidesClasses(), "slide" !== o.effect && i.setTranslate(y), "reset" !== w && (i.transitionStart(r, w), i.transitionEnd(r, w)), !1;
        if (o.cssMode) {
          const e = i.isHorizontal(),
            r = p ? y : -y;
          if (0 === t) {
            const t = i.virtual && i.params.virtual.enabled;
            t && ((i.wrapperEl.style.scrollSnapType = "none"), (i._immediateVirtual = !0)),
              t && !i._cssModeVirtualInitialSet && i.params.initialSlide > 0
                ? ((i._cssModeVirtualInitialSet = !0),
                  requestAnimationFrame(() => {
                    f[e ? "scrollLeft" : "scrollTop"] = r;
                  }))
                : (f[e ? "scrollLeft" : "scrollTop"] = r),
            t &&
            requestAnimationFrame(() => {
              (i.wrapperEl.style.scrollSnapType = ""), (i._immediateVirtual = !1);
            });
          } else {
            if (!i.support.smoothScroll) return m({ swiper: i, targetPosition: r, side: e ? "left" : "top" }), !0;
            f.scrollTo({ [e ? "left" : "top"]: r, behavior: "smooth" });
          }
          return !0;
        }
        return (
          i.setTransition(t),
            i.setTranslate(y),
            i.updateActiveIndex(n),
            i.updateSlidesClasses(),
            i.emit("beforeTransitionStart", t, a),
            i.transitionStart(r, w),
            0 === t
              ? i.transitionEnd(r, w)
              : i.animating ||
              ((i.animating = !0),
              i.onSlideToWrapperTransitionEnd ||
              (i.onSlideToWrapperTransitionEnd = function (e) {
                i &&
                !i.destroyed &&
                e.target === this &&
                (i.wrapperEl.removeEventListener("transitionend", i.onSlideToWrapperTransitionEnd), (i.onSlideToWrapperTransitionEnd = null), delete i.onSlideToWrapperTransitionEnd, i.transitionEnd(r, w));
              }),
                i.wrapperEl.addEventListener("transitionend", i.onSlideToWrapperTransitionEnd)),
            !0
        );
      },
      slideToLoop: function (e, t, r, a) {
        void 0 === e && (e = 0), void 0 === r && (r = !0), "string" == typeof e && (e = parseInt(e, 10));
        const s = this;
        if (s.destroyed) return;
        void 0 === t && (t = s.params.speed);
        const i = s.grid && s.params.grid && s.params.grid.rows > 1;
        let n = e;
        if (s.params.loop)
          if (s.virtual && s.params.virtual.enabled) n += s.virtual.slidesBefore;
          else {
            let e;
            if (i) {
              const t = n * s.params.grid.rows;
              e = s.slides.filter((e) => 1 * e.getAttribute("data-swiper-slide-index") === t)[0].column;
            } else e = s.getSlideIndexByData(n);
            const t = i ? Math.ceil(s.slides.length / s.params.grid.rows) : s.slides.length,
              { centeredSlides: r } = s.params;
            let o = s.params.slidesPerView;
            "auto" === o ? (o = s.slidesPerViewDynamic()) : ((o = Math.ceil(parseFloat(s.params.slidesPerView, 10))), r && o % 2 == 0 && (o += 1));
            let l = t - e < o;
            if ((r && (l = l || e < Math.ceil(o / 2)), a && r && "auto" !== s.params.slidesPerView && !i && (l = !1), l)) {
              const a = r ? (e < s.activeIndex ? "prev" : "next") : e - s.activeIndex - 1 < s.params.slidesPerView ? "next" : "prev";
              s.loopFix({ direction: a, slideTo: !0, activeSlideIndex: "next" === a ? e + 1 : e - t + 1, slideRealIndex: "next" === a ? s.realIndex : void 0 });
            }
            if (i) {
              const e = n * s.params.grid.rows;
              n = s.slides.filter((t) => 1 * t.getAttribute("data-swiper-slide-index") === e)[0].column;
            } else n = s.getSlideIndexByData(n);
          }
        return (
          requestAnimationFrame(() => {
            s.slideTo(n, t, r, a);
          }),
            s
        );
      },
      slideNext: function (e, t, r) {
        void 0 === t && (t = !0);
        const a = this,
          { enabled: s, params: i, animating: n } = a;
        if (!s || a.destroyed) return a;
        void 0 === e && (e = a.params.speed);
        let o = i.slidesPerGroup;
        "auto" === i.slidesPerView && 1 === i.slidesPerGroup && i.slidesPerGroupAuto && (o = Math.max(a.slidesPerViewDynamic("current", !0), 1));
        const l = a.activeIndex < i.slidesPerGroupSkip ? 1 : o,
          d = a.virtual && i.virtual.enabled;
        if (i.loop) {
          if (n && !d && i.loopPreventsSliding) return !1;
          if ((a.loopFix({ direction: "next" }), (a._clientLeft = a.wrapperEl.clientLeft), a.activeIndex === a.slides.length - 1 && i.cssMode))
            return (
              requestAnimationFrame(() => {
                a.slideTo(a.activeIndex + l, e, t, r);
              }),
                !0
            );
        }
        return i.rewind && a.isEnd ? a.slideTo(0, e, t, r) : a.slideTo(a.activeIndex + l, e, t, r);
      },
      slidePrev: function (e, t, r) {
        void 0 === t && (t = !0);
        const a = this,
          { params: s, snapGrid: i, slidesGrid: n, rtlTranslate: o, enabled: l, animating: d } = a;
        if (!l || a.destroyed) return a;
        void 0 === e && (e = a.params.speed);
        const c = a.virtual && s.virtual.enabled;
        if (s.loop) {
          if (d && !c && s.loopPreventsSliding) return !1;
          a.loopFix({ direction: "prev" }), (a._clientLeft = a.wrapperEl.clientLeft);
        }
        function u(e) {
          return e < 0 ? -Math.floor(Math.abs(e)) : Math.floor(e);
        }
        const p = u(o ? a.translate : -a.translate),
          m = i.map((e) => u(e));
        let f = i[m.indexOf(p) - 1];
        if (void 0 === f && s.cssMode) {
          let e;
          i.forEach((t, r) => {
            p >= t && (e = r);
          }),
          void 0 !== e && (f = i[e > 0 ? e - 1 : e]);
        }
        let h = 0;
        if (
          (void 0 !== f &&
          ((h = n.indexOf(f)), h < 0 && (h = a.activeIndex - 1), "auto" === s.slidesPerView && 1 === s.slidesPerGroup && s.slidesPerGroupAuto && ((h = h - a.slidesPerViewDynamic("previous", !0) + 1), (h = Math.max(h, 0)))),
          s.rewind && a.isBeginning)
        ) {
          const s = a.params.virtual && a.params.virtual.enabled && a.virtual ? a.virtual.slides.length - 1 : a.slides.length - 1;
          return a.slideTo(s, e, t, r);
        }
        return s.loop && 0 === a.activeIndex && s.cssMode
          ? (requestAnimationFrame(() => {
            a.slideTo(h, e, t, r);
          }),
            !0)
          : a.slideTo(h, e, t, r);
      },
      slideReset: function (e, t, r) {
        void 0 === t && (t = !0);
        const a = this;
        if (!a.destroyed) return void 0 === e && (e = a.params.speed), a.slideTo(a.activeIndex, e, t, r);
      },
      slideToClosest: function (e, t, r, a) {
        void 0 === t && (t = !0), void 0 === a && (a = 0.5);
        const s = this;
        if (s.destroyed) return;
        void 0 === e && (e = s.params.speed);
        let i = s.activeIndex;
        const n = Math.min(s.params.slidesPerGroupSkip, i),
          o = n + Math.floor((i - n) / s.params.slidesPerGroup),
          l = s.rtlTranslate ? s.translate : -s.translate;
        if (l >= s.snapGrid[o]) {
          const e = s.snapGrid[o];
          l - e > (s.snapGrid[o + 1] - e) * a && (i += s.params.slidesPerGroup);
        } else {
          const e = s.snapGrid[o - 1];
          l - e <= (s.snapGrid[o] - e) * a && (i -= s.params.slidesPerGroup);
        }
        return (i = Math.max(i, 0)), (i = Math.min(i, s.slidesGrid.length - 1)), s.slideTo(i, e, t, r);
      },
      slideToClickedSlide: function () {
        const e = this;
        if (e.destroyed) return;
        const { params: t, slidesEl: r } = e,
          a = "auto" === t.slidesPerView ? e.slidesPerViewDynamic() : t.slidesPerView;
        let s,
          i = e.clickedIndex;
        const n = e.isElement ? "swiper-slide" : `.${t.slideClass}`;
        if (t.loop) {
          if (e.animating) return;
          (s = parseInt(e.clickedSlide.getAttribute("data-swiper-slide-index"), 10)),
            t.centeredSlides
              ? i < e.loopedSlides - a / 2 || i > e.slides.length - e.loopedSlides + a / 2
                ? (e.loopFix(),
                  (i = e.getSlideIndex(h(r, `${n}[data-swiper-slide-index="${s}"]`)[0])),
                  o(() => {
                    e.slideTo(i);
                  }))
                : e.slideTo(i)
              : i > e.slides.length - a
                ? (e.loopFix(),
                  (i = e.getSlideIndex(h(r, `${n}[data-swiper-slide-index="${s}"]`)[0])),
                  o(() => {
                    e.slideTo(i);
                  }))
                : e.slideTo(i);
        } else e.slideTo(i);
      },
    },
    N = {
      loopCreate: function (e) {
        const t = this,
          { params: r, slidesEl: a } = t;
        if (!r.loop || (t.virtual && t.params.virtual.enabled)) return;
        const s = () => {
            h(a, `.${r.slideClass}, swiper-slide`).forEach((e, t) => {
              e.setAttribute("data-swiper-slide-index", t);
            });
          },
          i = t.grid && r.grid && r.grid.rows > 1,
          n = r.slidesPerGroup * (i ? r.grid.rows : 1),
          o = t.slides.length % n != 0,
          l = i && t.slides.length % r.grid.rows != 0,
          d = (e) => {
            for (let a = 0; a < e; a += 1) {
              const e = t.isElement ? v("swiper-slide", [r.slideBlankClass]) : v("div", [r.slideClass, r.slideBlankClass]);
              t.slidesEl.append(e);
            }
          };
        o
          ? (r.loopAddBlankSlides
            ? (d(n - (t.slides.length % n)), t.recalcSlides(), t.updateSlides())
            : g("Swiper Loop Warning: The number of slides is not even to slidesPerGroup, loop mode may not function properly. You need to add more slides (or make duplicates, or empty slides)"),
            s())
          : l
            ? (r.loopAddBlankSlides
              ? (d(r.grid.rows - (t.slides.length % r.grid.rows)), t.recalcSlides(), t.updateSlides())
              : g("Swiper Loop Warning: The number of slides is not even to grid.rows, loop mode may not function properly. You need to add more slides (or make duplicates, or empty slides)"),
              s())
            : s(),
          t.loopFix({ slideRealIndex: e, direction: r.centeredSlides ? void 0 : "next" });
      },
      loopFix: function (e) {
        let { slideRealIndex: t, slideTo: r = !0, direction: a, setTranslate: s, activeSlideIndex: i, byController: n, byMousewheel: o } = void 0 === e ? {} : e;
        const l = this;
        if (!l.params.loop) return;
        l.emit("beforeLoopFix");
        const { slides: d, allowSlidePrev: c, allowSlideNext: u, slidesEl: p, params: m } = l,
          { centeredSlides: f } = m;
        if (((l.allowSlidePrev = !0), (l.allowSlideNext = !0), l.virtual && m.virtual.enabled))
          return (
            r &&
            (m.centeredSlides || 0 !== l.snapIndex
              ? m.centeredSlides && l.snapIndex < m.slidesPerView
                ? l.slideTo(l.virtual.slides.length + l.snapIndex, 0, !1, !0)
                : l.snapIndex === l.snapGrid.length - 1 && l.slideTo(l.virtual.slidesBefore, 0, !1, !0)
              : l.slideTo(l.virtual.slides.length, 0, !1, !0)),
              (l.allowSlidePrev = c),
              (l.allowSlideNext = u),
              void l.emit("loopFix")
          );
        let h = m.slidesPerView;
        "auto" === h ? (h = l.slidesPerViewDynamic()) : ((h = Math.ceil(parseFloat(m.slidesPerView, 10))), f && h % 2 == 0 && (h += 1));
        const v = m.slidesPerGroupAuto ? h : m.slidesPerGroup;
        let y = v;
        y % v != 0 && (y += v - (y % v)), (y += m.loopAdditionalSlides), (l.loopedSlides = y);
        const w = l.grid && m.grid && m.grid.rows > 1;
        d.length < h + y
          ? g(
            "Swiper Loop Warning: The number of slides is not enough for loop mode, it will be disabled and not function properly. You need to add more slides (or make duplicates) or lower the values of slidesPerView and slidesPerGroup parameters"
          )
          : w && "row" === m.grid.fill && g("Swiper Loop Warning: Loop mode is not compatible with grid.fill = `row`");
        const b = [],
          x = [];
        let E = l.activeIndex;
        void 0 === i ? (i = l.getSlideIndex(d.filter((e) => e.classList.contains(m.slideActiveClass))[0])) : (E = i);
        const S = "next" === a || !a,
          T = "prev" === a || !a;
        let M = 0,
          C = 0;
        const L = w ? Math.ceil(d.length / m.grid.rows) : d.length,
          P = (w ? d[i].column : i) + (f && void 0 === s ? -h / 2 + 0.5 : 0);
        if (P < y) {
          M = Math.max(y - P, v);
          for (let e = 0; e < y - P; e += 1) {
            const t = e - Math.floor(e / L) * L;
            if (w) {
              const e = L - t - 1;
              for (let t = d.length - 1; t >= 0; t -= 1) d[t].column === e && b.push(t);
            } else b.push(L - t - 1);
          }
        } else if (P + h > L - y) {
          C = Math.max(P - (L - 2 * y), v);
          for (let e = 0; e < C; e += 1) {
            const t = e - Math.floor(e / L) * L;
            w
              ? d.forEach((e, r) => {
                e.column === t && x.push(r);
              })
              : x.push(t);
          }
        }
        if (
          ((l.__preventObserver__ = !0),
            requestAnimationFrame(() => {
              l.__preventObserver__ = !1;
            }),
          T &&
          b.forEach((e) => {
            (d[e].swiperLoopMoveDOM = !0), p.prepend(d[e]), (d[e].swiperLoopMoveDOM = !1);
          }),
          S &&
          x.forEach((e) => {
            (d[e].swiperLoopMoveDOM = !0), p.append(d[e]), (d[e].swiperLoopMoveDOM = !1);
          }),
            l.recalcSlides(),
            "auto" === m.slidesPerView
              ? l.updateSlides()
              : w &&
              ((b.length > 0 && T) || (x.length > 0 && S)) &&
              l.slides.forEach((e, t) => {
                l.grid.updateSlide(t, e, l.slides);
              }),
          m.watchSlidesProgress && l.updateSlidesOffset(),
            r)
        )
          if (b.length > 0 && T) {
            if (void 0 === t) {
              const e = l.slidesGrid[E],
                t = l.slidesGrid[E + M] - e;
              o
                ? l.setTranslate(l.translate - t)
                : (l.slideTo(E + Math.ceil(M), 0, !1, !0), s && ((l.touchEventsData.startTranslate = l.touchEventsData.startTranslate - t), (l.touchEventsData.currentTranslate = l.touchEventsData.currentTranslate - t)));
            } else if (s) {
              const e = w ? b.length / m.grid.rows : b.length;
              l.slideTo(l.activeIndex + e, 0, !1, !0), (l.touchEventsData.currentTranslate = l.translate);
            }
          } else if (x.length > 0 && S)
            if (void 0 === t) {
              const e = l.slidesGrid[E],
                t = l.slidesGrid[E - C] - e;
              o
                ? l.setTranslate(l.translate - t)
                : (l.slideTo(E - C, 0, !1, !0), s && ((l.touchEventsData.startTranslate = l.touchEventsData.startTranslate - t), (l.touchEventsData.currentTranslate = l.touchEventsData.currentTranslate - t)));
            } else {
              const e = w ? x.length / m.grid.rows : x.length;
              l.slideTo(l.activeIndex - e, 0, !1, !0);
            }
        if (((l.allowSlidePrev = c), (l.allowSlideNext = u), l.controller && l.controller.control && !n)) {
          const e = { slideRealIndex: t, direction: a, setTranslate: s, activeSlideIndex: i, byController: !0 };
          Array.isArray(l.controller.control)
            ? l.controller.control.forEach((t) => {
              !t.destroyed && t.params.loop && t.loopFix({ ...e, slideTo: t.params.slidesPerView === m.slidesPerView && r });
            })
            : l.controller.control instanceof l.constructor && l.controller.control.params.loop && l.controller.control.loopFix({ ...e, slideTo: l.controller.control.params.slidesPerView === m.slidesPerView && r });
        }
        l.emit("loopFix");
      },
      loopDestroy: function () {
        const e = this,
          { params: t, slidesEl: r } = e;
        if (!t.loop || (e.virtual && e.params.virtual.enabled)) return;
        e.recalcSlides();
        const a = [];
        e.slides.forEach((e) => {
          const t = void 0 === e.swiperSlideIndex ? 1 * e.getAttribute("data-swiper-slide-index") : e.swiperSlideIndex;
          a[t] = e;
        }),
          e.slides.forEach((e) => {
            e.removeAttribute("data-swiper-slide-index");
          }),
          a.forEach((e) => {
            r.append(e);
          }),
          e.recalcSlides(),
          e.slideTo(e.realIndex, 0);
      },
    };
  function q(e, t, r) {
    const a = i(),
      { params: s } = e,
      n = s.edgeSwipeDetection,
      o = s.edgeSwipeThreshold;
    return !n || !(r <= o || r >= a.innerWidth - o) || ("prevent" === n && (t.preventDefault(), !0));
  }
  function H(e) {
    const t = this,
      r = a();
    let s = e;
    s.originalEvent && (s = s.originalEvent);
    const n = t.touchEventsData;
    if ("pointerdown" === s.type) {
      if (null !== n.pointerId && n.pointerId !== s.pointerId) return;
      n.pointerId = s.pointerId;
    } else "touchstart" === s.type && 1 === s.targetTouches.length && (n.touchId = s.targetTouches[0].identifier);
    if ("touchstart" === s.type) return void q(t, s, s.targetTouches[0].pageX);
    const { params: o, touches: d, enabled: c } = t;
    if (!c) return;
    if (!o.simulateTouch && "mouse" === s.pointerType) return;
    if (t.animating && o.preventInteractionOnTransition) return;
    !t.animating && o.cssMode && o.loop && t.loopFix();
    let u = s.target;
    if ("wrapper" === o.touchEventsTarget && !t.wrapperEl.contains(u)) return;
    if ("which" in s && 3 === s.which) return;
    if ("button" in s && s.button > 0) return;
    if (n.isTouched && n.isMoved) return;
    const p = !!o.noSwipingClass && "" !== o.noSwipingClass,
      m = s.composedPath ? s.composedPath() : s.path;
    p && s.target && s.target.shadowRoot && m && (u = m[0]);
    const f = o.noSwipingSelector ? o.noSwipingSelector : `.${o.noSwipingClass}`,
      h = !(!s.target || !s.target.shadowRoot);
    if (
      o.noSwiping &&
      (h
        ? (function (e, t) {
          return (
            void 0 === t && (t = this),
              (function t(r) {
                if (!r || r === a() || r === i()) return null;
                r.assignedSlot && (r = r.assignedSlot);
                const s = r.closest(e);
                return s || r.getRootNode ? s || t(r.getRootNode().host) : null;
              })(t)
          );
        })(f, u)
        : u.closest(f))
    )
      return void (t.allowClick = !0);
    if (o.swipeHandler && !u.closest(o.swipeHandler)) return;
    (d.currentX = s.pageX), (d.currentY = s.pageY);
    const g = d.currentX,
      v = d.currentY;
    if (!q(t, s, g)) return;
    Object.assign(n, { isTouched: !0, isMoved: !1, allowTouchCallbacks: !0, isScrolling: void 0, startMoving: void 0 }),
      (d.startX = g),
      (d.startY = v),
      (n.touchStartTime = l()),
      (t.allowClick = !0),
      t.updateSize(),
      (t.swipeDirection = void 0),
    o.threshold > 0 && (n.allowThresholdMove = !1);
    let y = !0;
    u.matches(n.focusableElements) && ((y = !1), "SELECT" === u.nodeName && (n.isTouched = !1)), r.activeElement && r.activeElement.matches(n.focusableElements) && r.activeElement !== u && r.activeElement.blur();
    const w = y && t.allowTouchMove && o.touchStartPreventDefault;
    (!o.touchStartForcePreventDefault && !w) || u.isContentEditable || s.preventDefault(), o.freeMode && o.freeMode.enabled && t.freeMode && t.animating && !o.cssMode && t.freeMode.onTouchStart(), t.emit("touchStart", s);
  }
  function j(e) {
    const t = a(),
      r = this,
      s = r.touchEventsData,
      { params: i, touches: n, rtlTranslate: o, enabled: d } = r;
    if (!d) return;
    if (!i.simulateTouch && "mouse" === e.pointerType) return;
    let c,
      u = e;
    if ((u.originalEvent && (u = u.originalEvent), "pointermove" === u.type)) {
      if (null !== s.touchId) return;
      if (u.pointerId !== s.pointerId) return;
    }
    if ("touchmove" === u.type) {
      if (((c = [...u.changedTouches].filter((e) => e.identifier === s.touchId)[0]), !c || c.identifier !== s.touchId)) return;
    } else c = u;
    if (!s.isTouched) return void (s.startMoving && s.isScrolling && r.emit("touchMoveOpposite", u));
    const p = c.pageX,
      m = c.pageY;
    if (u.preventedByNestedSwiper) return (n.startX = p), void (n.startY = m);
    if (!r.allowTouchMove) return u.target.matches(s.focusableElements) || (r.allowClick = !1), void (s.isTouched && (Object.assign(n, { startX: p, startY: m, currentX: p, currentY: m }), (s.touchStartTime = l())));
    if (i.touchReleaseOnEdges && !i.loop)
      if (r.isVertical()) {
        if ((m < n.startY && r.translate <= r.maxTranslate()) || (m > n.startY && r.translate >= r.minTranslate())) return (s.isTouched = !1), void (s.isMoved = !1);
      } else if ((p < n.startX && r.translate <= r.maxTranslate()) || (p > n.startX && r.translate >= r.minTranslate())) return;
    if (t.activeElement && u.target === t.activeElement && u.target.matches(s.focusableElements)) return (s.isMoved = !0), void (r.allowClick = !1);
    s.allowTouchCallbacks && r.emit("touchMove", u), (n.previousX = n.currentX), (n.previousY = n.currentY), (n.currentX = p), (n.currentY = m);
    const f = n.currentX - n.startX,
      h = n.currentY - n.startY;
    if (r.params.threshold && Math.sqrt(f ** 2 + h ** 2) < r.params.threshold) return;
    if (void 0 === s.isScrolling) {
      let e;
      (r.isHorizontal() && n.currentY === n.startY) || (r.isVertical() && n.currentX === n.startX)
        ? (s.isScrolling = !1)
        : f * f + h * h >= 25 && ((e = (180 * Math.atan2(Math.abs(h), Math.abs(f))) / Math.PI), (s.isScrolling = r.isHorizontal() ? e > i.touchAngle : 90 - e > i.touchAngle));
    }
    if (
      (s.isScrolling && r.emit("touchMoveOpposite", u),
      void 0 === s.startMoving && ((n.currentX === n.startX && n.currentY === n.startY) || (s.startMoving = !0)),
      s.isScrolling || ("touchmove" === u.type && s.preventTouchMoveFromPointerMove))
    )
      return void (s.isTouched = !1);
    if (!s.startMoving) return;
    (r.allowClick = !1), !i.cssMode && u.cancelable && u.preventDefault(), i.touchMoveStopPropagation && !i.nested && u.stopPropagation();
    let g = r.isHorizontal() ? f : h,
      v = r.isHorizontal() ? n.currentX - n.previousX : n.currentY - n.previousY;
    i.oneWayMovement && ((g = Math.abs(g) * (o ? 1 : -1)), (v = Math.abs(v) * (o ? 1 : -1))), (n.diff = g), (g *= i.touchRatio), o && ((g = -g), (v = -v));
    const y = r.touchesDirection;
    (r.swipeDirection = g > 0 ? "prev" : "next"), (r.touchesDirection = v > 0 ? "prev" : "next");
    const w = r.params.loop && !i.cssMode,
      b = ("next" === r.touchesDirection && r.allowSlideNext) || ("prev" === r.touchesDirection && r.allowSlidePrev);
    if (!s.isMoved) {
      if ((w && b && r.loopFix({ direction: r.swipeDirection }), (s.startTranslate = r.getTranslate()), r.setTransition(0), r.animating)) {
        const e = new window.CustomEvent("transitionend", { bubbles: !0, cancelable: !0 });
        r.wrapperEl.dispatchEvent(e);
      }
      (s.allowMomentumBounce = !1), !i.grabCursor || (!0 !== r.allowSlideNext && !0 !== r.allowSlidePrev) || r.setGrabCursor(!0), r.emit("sliderFirstMove", u);
    }
    if ((new Date().getTime(), s.isMoved && s.allowThresholdMove && y !== r.touchesDirection && w && b && Math.abs(g) >= 1))
      return Object.assign(n, { startX: p, startY: m, currentX: p, currentY: m, startTranslate: s.currentTranslate }), (s.loopSwapReset = !0), void (s.startTranslate = s.currentTranslate);
    r.emit("sliderMove", u), (s.isMoved = !0), (s.currentTranslate = g + s.startTranslate);
    let x = !0,
      E = i.resistanceRatio;
    if (
      (i.touchReleaseOnEdges && (E = 0),
        g > 0
          ? (w &&
          b &&
          s.allowThresholdMove &&
          s.currentTranslate > (i.centeredSlides ? r.minTranslate() - r.slidesSizesGrid[r.activeIndex + 1] : r.minTranslate()) &&
          r.loopFix({ direction: "prev", setTranslate: !0, activeSlideIndex: 0 }),
          s.currentTranslate > r.minTranslate() && ((x = !1), i.resistance && (s.currentTranslate = r.minTranslate() - 1 + (-r.minTranslate() + s.startTranslate + g) ** E)))
          : g < 0 &&
          (w &&
          b &&
          s.allowThresholdMove &&
          s.currentTranslate < (i.centeredSlides ? r.maxTranslate() + r.slidesSizesGrid[r.slidesSizesGrid.length - 1] : r.maxTranslate()) &&
          r.loopFix({ direction: "next", setTranslate: !0, activeSlideIndex: r.slides.length - ("auto" === i.slidesPerView ? r.slidesPerViewDynamic() : Math.ceil(parseFloat(i.slidesPerView, 10))) }),
          s.currentTranslate < r.maxTranslate() && ((x = !1), i.resistance && (s.currentTranslate = r.maxTranslate() + 1 - (r.maxTranslate() - s.startTranslate - g) ** E))),
      x && (u.preventedByNestedSwiper = !0),
      !r.allowSlideNext && "next" === r.swipeDirection && s.currentTranslate < s.startTranslate && (s.currentTranslate = s.startTranslate),
      !r.allowSlidePrev && "prev" === r.swipeDirection && s.currentTranslate > s.startTranslate && (s.currentTranslate = s.startTranslate),
      r.allowSlidePrev || r.allowSlideNext || (s.currentTranslate = s.startTranslate),
      i.threshold > 0)
    ) {
      if (!(Math.abs(g) > i.threshold || s.allowThresholdMove)) return void (s.currentTranslate = s.startTranslate);
      if (!s.allowThresholdMove)
        return (s.allowThresholdMove = !0), (n.startX = n.currentX), (n.startY = n.currentY), (s.currentTranslate = s.startTranslate), void (n.diff = r.isHorizontal() ? n.currentX - n.startX : n.currentY - n.startY);
    }
    i.followFinger &&
    !i.cssMode &&
    (((i.freeMode && i.freeMode.enabled && r.freeMode) || i.watchSlidesProgress) && (r.updateActiveIndex(), r.updateSlidesClasses()),
    i.freeMode && i.freeMode.enabled && r.freeMode && r.freeMode.onTouchMove(),
      r.updateProgress(s.currentTranslate),
      r.setTranslate(s.currentTranslate));
  }
  function X(e) {
    const t = this,
      r = t.touchEventsData;
    let a,
      s = e;
    if ((s.originalEvent && (s = s.originalEvent), "touchend" === s.type || "touchcancel" === s.type)) {
      if (((a = [...s.changedTouches].filter((e) => e.identifier === r.touchId)[0]), !a || a.identifier !== r.touchId)) return;
    } else {
      if (null !== r.touchId) return;
      if (s.pointerId !== r.pointerId) return;
      a = s;
    }
    if (["pointercancel", "pointerout", "pointerleave", "contextmenu"].includes(s.type) && (!["pointercancel", "contextmenu"].includes(s.type) || (!t.browser.isSafari && !t.browser.isWebView))) return;
    (r.pointerId = null), (r.touchId = null);
    const { params: i, touches: n, rtlTranslate: d, slidesGrid: c, enabled: u } = t;
    if (!u) return;
    if (!i.simulateTouch && "mouse" === s.pointerType) return;
    if ((r.allowTouchCallbacks && t.emit("touchEnd", s), (r.allowTouchCallbacks = !1), !r.isTouched)) return r.isMoved && i.grabCursor && t.setGrabCursor(!1), (r.isMoved = !1), void (r.startMoving = !1);
    i.grabCursor && r.isMoved && r.isTouched && (!0 === t.allowSlideNext || !0 === t.allowSlidePrev) && t.setGrabCursor(!1);
    const p = l(),
      m = p - r.touchStartTime;
    if (t.allowClick) {
      const e = s.path || (s.composedPath && s.composedPath());
      t.updateClickedSlide((e && e[0]) || s.target, e), t.emit("tap click", s), m < 300 && p - r.lastClickTime < 300 && t.emit("doubleTap doubleClick", s);
    }
    if (
      ((r.lastClickTime = l()),
        o(() => {
          t.destroyed || (t.allowClick = !0);
        }),
      !r.isTouched || !r.isMoved || !t.swipeDirection || (0 === n.diff && !r.loopSwapReset) || (r.currentTranslate === r.startTranslate && !r.loopSwapReset))
    )
      return (r.isTouched = !1), (r.isMoved = !1), void (r.startMoving = !1);
    let f;
    if (((r.isTouched = !1), (r.isMoved = !1), (r.startMoving = !1), (f = i.followFinger ? (d ? t.translate : -t.translate) : -r.currentTranslate), i.cssMode)) return;
    if (i.freeMode && i.freeMode.enabled) return void t.freeMode.onTouchEnd({ currentPos: f });
    const h = f >= -t.maxTranslate() && !t.params.loop;
    let g = 0,
      v = t.slidesSizesGrid[0];
    for (let e = 0; e < c.length; e += e < i.slidesPerGroupSkip ? 1 : i.slidesPerGroup) {
      const t = e < i.slidesPerGroupSkip - 1 ? 1 : i.slidesPerGroup;
      void 0 !== c[e + t] ? (h || (f >= c[e] && f < c[e + t])) && ((g = e), (v = c[e + t] - c[e])) : (h || f >= c[e]) && ((g = e), (v = c[c.length - 1] - c[c.length - 2]));
    }
    let y = null,
      w = null;
    i.rewind && (t.isBeginning ? (w = i.virtual && i.virtual.enabled && t.virtual ? t.virtual.slides.length - 1 : t.slides.length - 1) : t.isEnd && (y = 0));
    const b = (f - c[g]) / v,
      x = g < i.slidesPerGroupSkip - 1 ? 1 : i.slidesPerGroup;
    if (m > i.longSwipesMs) {
      if (!i.longSwipes) return void t.slideTo(t.activeIndex);
      "next" === t.swipeDirection && (b >= i.longSwipesRatio ? t.slideTo(i.rewind && t.isEnd ? y : g + x) : t.slideTo(g)),
      "prev" === t.swipeDirection && (b > 1 - i.longSwipesRatio ? t.slideTo(g + x) : null !== w && b < 0 && Math.abs(b) > i.longSwipesRatio ? t.slideTo(w) : t.slideTo(g));
    } else {
      if (!i.shortSwipes) return void t.slideTo(t.activeIndex);
      !t.navigation || (s.target !== t.navigation.nextEl && s.target !== t.navigation.prevEl)
        ? ("next" === t.swipeDirection && t.slideTo(null !== y ? y : g + x), "prev" === t.swipeDirection && t.slideTo(null !== w ? w : g))
        : s.target === t.navigation.nextEl
          ? t.slideTo(g + x)
          : t.slideTo(g);
    }
  }
  function F() {
    const e = this,
      { params: t, el: r } = e;
    if (r && 0 === r.offsetWidth) return;
    t.breakpoints && e.setBreakpoint();
    const { allowSlideNext: a, allowSlidePrev: s, snapGrid: i } = e,
      n = e.virtual && e.params.virtual.enabled;
    (e.allowSlideNext = !0), (e.allowSlidePrev = !0), e.updateSize(), e.updateSlides(), e.updateSlidesClasses();
    const o = n && t.loop;
    !("auto" === t.slidesPerView || t.slidesPerView > 1) || !e.isEnd || e.isBeginning || e.params.centeredSlides || o
      ? e.params.loop && !n
        ? e.slideToLoop(e.realIndex, 0, !1, !0)
        : e.slideTo(e.activeIndex, 0, !1, !0)
      : e.slideTo(e.slides.length - 1, 0, !1, !0),
    e.autoplay &&
    e.autoplay.running &&
    e.autoplay.paused &&
    (clearTimeout(e.autoplay.resizeTimeout),
      (e.autoplay.resizeTimeout = setTimeout(() => {
        e.autoplay && e.autoplay.running && e.autoplay.paused && e.autoplay.resume();
      }, 500))),
      (e.allowSlidePrev = s),
      (e.allowSlideNext = a),
    e.params.watchOverflow && i !== e.snapGrid && e.checkOverflow();
  }
  function Y(e) {
    const t = this;
    t.enabled && (t.allowClick || (t.params.preventClicks && e.preventDefault(), t.params.preventClicksPropagation && t.animating && (e.stopPropagation(), e.stopImmediatePropagation())));
  }
  function _() {
    const e = this,
      { wrapperEl: t, rtlTranslate: r, enabled: a } = e;
    if (!a) return;
    let s;
    (e.previousTranslate = e.translate), e.isHorizontal() ? (e.translate = -t.scrollLeft) : (e.translate = -t.scrollTop), 0 === e.translate && (e.translate = 0), e.updateActiveIndex(), e.updateSlidesClasses();
    const i = e.maxTranslate() - e.minTranslate();
    (s = 0 === i ? 0 : (e.translate - e.minTranslate()) / i), s !== e.progress && e.updateProgress(r ? -e.translate : e.translate), e.emit("setTranslate", e.translate, !1);
  }
  function V(e) {
    const t = this;
    A(t, e.target), t.params.cssMode || ("auto" !== t.params.slidesPerView && !t.params.autoHeight) || t.update();
  }
  function R() {
    const e = this;
    e.documentTouchHandlerProceeded || ((e.documentTouchHandlerProceeded = !0), e.params.touchReleaseOnEdges && (e.el.style.touchAction = "auto"));
  }
  const W = (e, t) => {
      const r = a(),
        { params: s, el: i, wrapperEl: n, device: o } = e,
        l = !!s.nested,
        d = "on" === t ? "addEventListener" : "removeEventListener",
        c = t;
      r[d]("touchstart", e.onDocumentTouchStart, { passive: !1, capture: l }),
        i[d]("touchstart", e.onTouchStart, { passive: !1 }),
        i[d]("pointerdown", e.onTouchStart, { passive: !1 }),
        r[d]("touchmove", e.onTouchMove, { passive: !1, capture: l }),
        r[d]("pointermove", e.onTouchMove, { passive: !1, capture: l }),
        r[d]("touchend", e.onTouchEnd, { passive: !0 }),
        r[d]("pointerup", e.onTouchEnd, { passive: !0 }),
        r[d]("pointercancel", e.onTouchEnd, { passive: !0 }),
        r[d]("touchcancel", e.onTouchEnd, { passive: !0 }),
        r[d]("pointerout", e.onTouchEnd, { passive: !0 }),
        r[d]("pointerleave", e.onTouchEnd, { passive: !0 }),
        r[d]("contextmenu", e.onTouchEnd, { passive: !0 }),
      (s.preventClicks || s.preventClicksPropagation) && i[d]("click", e.onClick, !0),
      s.cssMode && n[d]("scroll", e.onScroll),
        s.updateOnWindowResize ? e[c](o.ios || o.android ? "resize orientationchange observerUpdate" : "resize observerUpdate", F, !0) : e[c]("observerUpdate", F, !0),
        i[d]("load", e.onLoad, { capture: !0 });
    },
    U = (e, t) => e.grid && t.grid && t.grid.rows > 1;
  var K = {
    init: !0,
    direction: "horizontal",
    oneWayMovement: !1,
    swiperElementNodeName: "SWIPER-CONTAINER",
    touchEventsTarget: "wrapper",
    initialSlide: 0,
    speed: 300,
    cssMode: !1,
    updateOnWindowResize: !0,
    resizeObserver: !0,
    nested: !1,
    createElements: !1,
    eventsPrefix: "swiper",
    enabled: !0,
    focusableElements: "input, select, option, textarea, button, video, label",
    width: null,
    height: null,
    preventInteractionOnTransition: !1,
    userAgent: null,
    url: null,
    edgeSwipeDetection: !1,
    edgeSwipeThreshold: 20,
    autoHeight: !1,
    setWrapperSize: !1,
    virtualTranslate: !1,
    effect: "slide",
    breakpoints: void 0,
    breakpointsBase: "window",
    spaceBetween: 0,
    slidesPerView: 1,
    slidesPerGroup: 1,
    slidesPerGroupSkip: 0,
    slidesPerGroupAuto: !1,
    centeredSlides: !1,
    centeredSlidesBounds: !1,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    normalizeSlideIndex: !0,
    centerInsufficientSlides: !1,
    watchOverflow: !0,
    roundLengths: !1,
    touchRatio: 1,
    touchAngle: 45,
    simulateTouch: !0,
    shortSwipes: !0,
    longSwipes: !0,
    longSwipesRatio: 0.5,
    longSwipesMs: 300,
    followFinger: !0,
    allowTouchMove: !0,
    threshold: 5,
    touchMoveStopPropagation: !1,
    touchStartPreventDefault: !0,
    touchStartForcePreventDefault: !1,
    touchReleaseOnEdges: !1,
    uniqueNavElements: !0,
    resistance: !0,
    resistanceRatio: 0.85,
    watchSlidesProgress: !1,
    grabCursor: !1,
    preventClicks: !0,
    preventClicksPropagation: !0,
    slideToClickedSlide: !1,
    loop: !1,
    loopAddBlankSlides: !0,
    loopAdditionalSlides: 0,
    loopPreventsSliding: !0,
    rewind: !1,
    allowSlidePrev: !0,
    allowSlideNext: !0,
    swipeHandler: null,
    noSwiping: !0,
    noSwipingClass: "swiper-no-swiping",
    noSwipingSelector: null,
    passiveListeners: !0,
    maxBackfaceHiddenSlides: 10,
    containerModifierClass: "swiper-",
    slideClass: "swiper-slide",
    slideBlankClass: "swiper-slide-blank",
    slideActiveClass: "swiper-slide-active",
    slideVisibleClass: "swiper-slide-visible",
    slideFullyVisibleClass: "swiper-slide-fully-visible",
    slideNextClass: "swiper-slide-next",
    slidePrevClass: "swiper-slide-prev",
    wrapperClass: "swiper-wrapper",
    lazyPreloaderClass: "swiper-lazy-preloader",
    lazyPreloadPrevNext: 0,
    runCallbacksOnInit: !0,
    _emitClasses: !1,
  };
  function Z(e, t) {
    return function (r) {
      void 0 === r && (r = {});
      const a = Object.keys(r)[0],
        s = r[a];
      "object" == typeof s && null !== s
        ? (!0 === e[a] && (e[a] = { enabled: !0 }),
        "navigation" === a && e[a] && e[a].enabled && !e[a].prevEl && !e[a].nextEl && (e[a].auto = !0),
        ["pagination", "scrollbar"].indexOf(a) >= 0 && e[a] && e[a].enabled && !e[a].el && (e[a].auto = !0),
          a in e && "enabled" in s ? ("object" != typeof e[a] || "enabled" in e[a] || (e[a].enabled = !0), e[a] || (e[a] = { enabled: !1 }), u(t, r)) : u(t, r))
        : u(t, r);
    };
  }
  const Q = {
      eventsEmitter: O,
      update: D,
      translate: {
        getTranslate: function (e) {
          void 0 === e && (e = this.isHorizontal() ? "x" : "y");
          const { params: t, rtlTranslate: r, translate: a, wrapperEl: s } = this;
          if (t.virtualTranslate) return r ? -a : a;
          if (t.cssMode) return a;
          let i = d(s, e);
          return (i += this.cssOverflowAdjustment()), r && (i = -i), i || 0;
        },
        setTranslate: function (e, t) {
          const r = this,
            { rtlTranslate: a, params: s, wrapperEl: i, progress: n } = r;
          let o,
            l = 0,
            d = 0;
          r.isHorizontal() ? (l = a ? -e : e) : (d = e),
          s.roundLengths && ((l = Math.floor(l)), (d = Math.floor(d))),
            (r.previousTranslate = r.translate),
            (r.translate = r.isHorizontal() ? l : d),
            s.cssMode
              ? (i[r.isHorizontal() ? "scrollLeft" : "scrollTop"] = r.isHorizontal() ? -l : -d)
              : s.virtualTranslate || (r.isHorizontal() ? (l -= r.cssOverflowAdjustment()) : (d -= r.cssOverflowAdjustment()), (i.style.transform = `translate3d(${l}px, ${d}px, 0px)`));
          const c = r.maxTranslate() - r.minTranslate();
          (o = 0 === c ? 0 : (e - r.minTranslate()) / c), o !== n && r.updateProgress(e), r.emit("setTranslate", r.translate, t);
        },
        minTranslate: function () {
          return -this.snapGrid[0];
        },
        maxTranslate: function () {
          return -this.snapGrid[this.snapGrid.length - 1];
        },
        translateTo: function (e, t, r, a, s) {
          void 0 === e && (e = 0), void 0 === t && (t = this.params.speed), void 0 === r && (r = !0), void 0 === a && (a = !0);
          const i = this,
            { params: n, wrapperEl: o } = i;
          if (i.animating && n.preventInteractionOnTransition) return !1;
          const l = i.minTranslate(),
            d = i.maxTranslate();
          let c;
          if (((c = a && e > l ? l : a && e < d ? d : e), i.updateProgress(c), n.cssMode)) {
            const e = i.isHorizontal();
            if (0 === t) o[e ? "scrollLeft" : "scrollTop"] = -c;
            else {
              if (!i.support.smoothScroll) return m({ swiper: i, targetPosition: -c, side: e ? "left" : "top" }), !0;
              o.scrollTo({ [e ? "left" : "top"]: -c, behavior: "smooth" });
            }
            return !0;
          }
          return (
            0 === t
              ? (i.setTransition(0), i.setTranslate(c), r && (i.emit("beforeTransitionStart", t, s), i.emit("transitionEnd")))
              : (i.setTransition(t),
                i.setTranslate(c),
              r && (i.emit("beforeTransitionStart", t, s), i.emit("transitionStart")),
              i.animating ||
              ((i.animating = !0),
              i.onTranslateToWrapperTransitionEnd ||
              (i.onTranslateToWrapperTransitionEnd = function (e) {
                i &&
                !i.destroyed &&
                e.target === this &&
                (i.wrapperEl.removeEventListener("transitionend", i.onTranslateToWrapperTransitionEnd),
                  (i.onTranslateToWrapperTransitionEnd = null),
                  delete i.onTranslateToWrapperTransitionEnd,
                  (i.animating = !1),
                r && i.emit("transitionEnd"));
              }),
                i.wrapperEl.addEventListener("transitionend", i.onTranslateToWrapperTransitionEnd))),
              !0
          );
        },
      },
      transition: {
        setTransition: function (e, t) {
          const r = this;
          r.params.cssMode || ((r.wrapperEl.style.transitionDuration = `${e}ms`), (r.wrapperEl.style.transitionDelay = 0 === e ? "0ms" : "")), r.emit("setTransition", e, t);
        },
        transitionStart: function (e, t) {
          void 0 === e && (e = !0);
          const r = this,
            { params: a } = r;
          a.cssMode || (a.autoHeight && r.updateAutoHeight(), G({ swiper: r, runCallbacks: e, direction: t, step: "Start" }));
        },
        transitionEnd: function (e, t) {
          void 0 === e && (e = !0);
          const r = this,
            { params: a } = r;
          (r.animating = !1), a.cssMode || (r.setTransition(0), G({ swiper: r, runCallbacks: e, direction: t, step: "End" }));
        },
      },
      slide: B,
      loop: N,
      grabCursor: {
        setGrabCursor: function (e) {
          const t = this;
          if (!t.params.simulateTouch || (t.params.watchOverflow && t.isLocked) || t.params.cssMode) return;
          const r = "container" === t.params.touchEventsTarget ? t.el : t.wrapperEl;
          t.isElement && (t.__preventObserver__ = !0),
            (r.style.cursor = "move"),
            (r.style.cursor = e ? "grabbing" : "grab"),
          t.isElement &&
          requestAnimationFrame(() => {
            t.__preventObserver__ = !1;
          });
        },
        unsetGrabCursor: function () {
          const e = this;
          (e.params.watchOverflow && e.isLocked) ||
          e.params.cssMode ||
          (e.isElement && (e.__preventObserver__ = !0),
            (e["container" === e.params.touchEventsTarget ? "el" : "wrapperEl"].style.cursor = ""),
          e.isElement &&
          requestAnimationFrame(() => {
            e.__preventObserver__ = !1;
          }));
        },
      },
      events: {
        attachEvents: function () {
          const e = this,
            { params: t } = e;
          (e.onTouchStart = H.bind(e)),
            (e.onTouchMove = j.bind(e)),
            (e.onTouchEnd = X.bind(e)),
            (e.onDocumentTouchStart = R.bind(e)),
          t.cssMode && (e.onScroll = _.bind(e)),
            (e.onClick = Y.bind(e)),
            (e.onLoad = V.bind(e)),
            W(e, "on");
        },
        detachEvents: function () {
          W(this, "off");
        },
      },
      breakpoints: {
        setBreakpoint: function () {
          const e = this,
            { realIndex: t, initialized: r, params: a, el: s } = e,
            i = a.breakpoints;
          if (!i || (i && 0 === Object.keys(i).length)) return;
          const n = e.getBreakpoint(i, e.params.breakpointsBase, e.el);
          if (!n || e.currentBreakpoint === n) return;
          const o = (n in i ? i[n] : void 0) || e.originalParams,
            l = U(e, a),
            d = U(e, o),
            c = e.params.grabCursor,
            p = o.grabCursor,
            m = a.enabled;
          l && !d
            ? (s.classList.remove(`${a.containerModifierClass}grid`, `${a.containerModifierClass}grid-column`), e.emitContainerClasses())
            : !l &&
            d &&
            (s.classList.add(`${a.containerModifierClass}grid`),
            ((o.grid.fill && "column" === o.grid.fill) || (!o.grid.fill && "column" === a.grid.fill)) && s.classList.add(`${a.containerModifierClass}grid-column`),
              e.emitContainerClasses()),
            c && !p ? e.unsetGrabCursor() : !c && p && e.setGrabCursor(),
            ["navigation", "pagination", "scrollbar"].forEach((t) => {
              if (void 0 === o[t]) return;
              const r = a[t] && a[t].enabled,
                s = o[t] && o[t].enabled;
              r && !s && e[t].disable(), !r && s && e[t].enable();
            });
          const f = o.direction && o.direction !== a.direction,
            h = a.loop && (o.slidesPerView !== a.slidesPerView || f),
            g = a.loop;
          f && r && e.changeDirection(), u(e.params, o);
          const v = e.params.enabled,
            y = e.params.loop;
          Object.assign(e, { allowTouchMove: e.params.allowTouchMove, allowSlideNext: e.params.allowSlideNext, allowSlidePrev: e.params.allowSlidePrev }),
            m && !v ? e.disable() : !m && v && e.enable(),
            (e.currentBreakpoint = n),
            e.emit("_beforeBreakpoint", o),
          r && (h ? (e.loopDestroy(), e.loopCreate(t), e.updateSlides()) : !g && y ? (e.loopCreate(t), e.updateSlides()) : g && !y && e.loopDestroy()),
            e.emit("breakpoint", o);
        },
        getBreakpoint: function (e, t, r) {
          if ((void 0 === t && (t = "window"), !e || ("container" === t && !r))) return;
          let a = !1;
          const s = i(),
            n = "window" === t ? s.innerHeight : r.clientHeight,
            o = Object.keys(e).map((e) => {
              if ("string" == typeof e && 0 === e.indexOf("@")) {
                const t = parseFloat(e.substr(1));
                return { value: n * t, point: e };
              }
              return { value: e, point: e };
            });
          o.sort((e, t) => parseInt(e.value, 10) - parseInt(t.value, 10));
          for (let e = 0; e < o.length; e += 1) {
            const { point: i, value: n } = o[e];
            "window" === t ? s.matchMedia(`(min-width: ${n}px)`).matches && (a = i) : n <= r.clientWidth && (a = i);
          }
          return a || "max";
        },
      },
      checkOverflow: {
        checkOverflow: function () {
          const e = this,
            { isLocked: t, params: r } = e,
            { slidesOffsetBefore: a } = r;
          if (a) {
            const t = e.slides.length - 1,
              r = e.slidesGrid[t] + e.slidesSizesGrid[t] + 2 * a;
            e.isLocked = e.size > r;
          } else e.isLocked = 1 === e.snapGrid.length;
          !0 === r.allowSlideNext && (e.allowSlideNext = !e.isLocked),
          !0 === r.allowSlidePrev && (e.allowSlidePrev = !e.isLocked),
          t && t !== e.isLocked && (e.isEnd = !1),
          t !== e.isLocked && e.emit(e.isLocked ? "lock" : "unlock");
        },
      },
      classes: {
        addClasses: function () {
          const e = this,
            { classNames: t, params: r, rtl: a, el: s, device: i } = e,
            n = (function (e, t) {
              const r = [];
              return (
                e.forEach((e) => {
                  "object" == typeof e
                    ? Object.keys(e).forEach((a) => {
                      e[a] && r.push(t + a);
                    })
                    : "string" == typeof e && r.push(t + e);
                }),
                  r
              );
            })(
              [
                "initialized",
                r.direction,
                { "free-mode": e.params.freeMode && r.freeMode.enabled },
                { autoheight: r.autoHeight },
                { rtl: a },
                { grid: r.grid && r.grid.rows > 1 },
                { "grid-column": r.grid && r.grid.rows > 1 && "column" === r.grid.fill },
                { android: i.android },
                { ios: i.ios },
                { "css-mode": r.cssMode },
                { centered: r.cssMode && r.centeredSlides },
                { "watch-progress": r.watchSlidesProgress },
              ],
              r.containerModifierClass
            );
          t.push(...n), s.classList.add(...t), e.emitContainerClasses();
        },
        removeClasses: function () {
          const { el: e, classNames: t } = this;
          e.classList.remove(...t), this.emitContainerClasses();
        },
      },
    },
    J = {};
  class ee {
    constructor() {
      let e, t;
      for (var r = arguments.length, s = new Array(r), n = 0; n < r; n++) s[n] = arguments[n];
      1 === s.length && s[0].constructor && "Object" === Object.prototype.toString.call(s[0]).slice(8, -1) ? (t = s[0]) : ([e, t] = s), t || (t = {}), (t = u({}, t)), e && !t.el && (t.el = e);
      const o = a();
      if (t.el && "string" == typeof t.el && o.querySelectorAll(t.el).length > 1) {
        const e = [];
        return (
          o.querySelectorAll(t.el).forEach((r) => {
            const a = u({}, t, { el: r });
            e.push(new ee(a));
          }),
            e
        );
      }
      const l = this;
      (l.__swiper__ = !0),
        (l.support = P()),
        (l.device = I({ userAgent: t.userAgent })),
        (l.browser =
          (L ||
          (L = (function () {
            const e = i(),
              t = I();
            let r = !1;
            function a() {
              const t = e.navigator.userAgent.toLowerCase();
              return t.indexOf("safari") >= 0 && t.indexOf("chrome") < 0 && t.indexOf("android") < 0;
            }
            if (a()) {
              const t = String(e.navigator.userAgent);
              if (t.includes("Version/")) {
                const [e, a] = t
                  .split("Version/")[1]
                  .split(" ")[0]
                  .split(".")
                  .map((e) => Number(e));
                r = e < 16 || (16 === e && a < 2);
              }
            }
            const s = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(e.navigator.userAgent),
              n = a();
            return { isSafari: r || n, needPerspectiveFix: r, need3dFix: n || (s && t.ios), isWebView: s };
          })()),
            L)),
        (l.eventsListeners = {}),
        (l.eventsAnyListeners = []),
        (l.modules = [...l.__modules__]),
      t.modules && Array.isArray(t.modules) && l.modules.push(...t.modules);
      const d = {};
      l.modules.forEach((e) => {
        e({ params: t, swiper: l, extendParams: Z(t, d), on: l.on.bind(l), once: l.once.bind(l), off: l.off.bind(l), emit: l.emit.bind(l) });
      });
      const c = u({}, K, d);
      return (
        (l.params = u({}, c, J, t)),
          (l.originalParams = u({}, l.params)),
          (l.passedParams = u({}, t)),
        l.params &&
        l.params.on &&
        Object.keys(l.params.on).forEach((e) => {
          l.on(e, l.params.on[e]);
        }),
        l.params && l.params.onAny && l.onAny(l.params.onAny),
          Object.assign(l, {
            enabled: l.params.enabled,
            el: e,
            classNames: [],
            slides: [],
            slidesGrid: [],
            snapGrid: [],
            slidesSizesGrid: [],
            isHorizontal() {
              return "horizontal" === l.params.direction;
            },
            isVertical() {
              return "vertical" === l.params.direction;
            },
            activeIndex: 0,
            realIndex: 0,
            isBeginning: !0,
            isEnd: !1,
            translate: 0,
            previousTranslate: 0,
            progress: 0,
            velocity: 0,
            animating: !1,
            cssOverflowAdjustment() {
              return Math.trunc(this.translate / 2 ** 23) * 2 ** 23;
            },
            allowSlideNext: l.params.allowSlideNext,
            allowSlidePrev: l.params.allowSlidePrev,
            touchEventsData: {
              isTouched: void 0,
              isMoved: void 0,
              allowTouchCallbacks: void 0,
              touchStartTime: void 0,
              isScrolling: void 0,
              currentTranslate: void 0,
              startTranslate: void 0,
              allowThresholdMove: void 0,
              focusableElements: l.params.focusableElements,
              lastClickTime: 0,
              clickTimeout: void 0,
              velocities: [],
              allowMomentumBounce: void 0,
              startMoving: void 0,
              pointerId: null,
              touchId: null,
            },
            allowClick: !0,
            allowTouchMove: l.params.allowTouchMove,
            touches: { startX: 0, startY: 0, currentX: 0, currentY: 0, diff: 0 },
            imagesToLoad: [],
            imagesLoaded: 0,
          }),
          l.emit("_swiper"),
        l.params.init && l.init(),
          l
      );
    }
    getDirectionLabel(e) {
      return this.isHorizontal()
        ? e
        : {
          width: "height",
          "margin-top": "margin-left",
          "margin-bottom ": "margin-right",
          "margin-left": "margin-top",
          "margin-right": "margin-bottom",
          "padding-left": "padding-top",
          "padding-right": "padding-bottom",
          marginRight: "marginBottom",
        }[e];
    }
    getSlideIndex(e) {
      const { slidesEl: t, params: r } = this,
        a = b(h(t, `.${r.slideClass}, swiper-slide`)[0]);
      return b(e) - a;
    }
    getSlideIndexByData(e) {
      return this.getSlideIndex(this.slides.filter((t) => 1 * t.getAttribute("data-swiper-slide-index") === e)[0]);
    }
    recalcSlides() {
      const { slidesEl: e, params: t } = this;
      this.slides = h(e, `.${t.slideClass}, swiper-slide`);
    }
    enable() {
      const e = this;
      e.enabled || ((e.enabled = !0), e.params.grabCursor && e.setGrabCursor(), e.emit("enable"));
    }
    disable() {
      const e = this;
      e.enabled && ((e.enabled = !1), e.params.grabCursor && e.unsetGrabCursor(), e.emit("disable"));
    }
    setProgress(e, t) {
      const r = this;
      e = Math.min(Math.max(e, 0), 1);
      const a = r.minTranslate(),
        s = (r.maxTranslate() - a) * e + a;
      r.translateTo(s, void 0 === t ? 0 : t), r.updateActiveIndex(), r.updateSlidesClasses();
    }
    emitContainerClasses() {
      const e = this;
      if (!e.params._emitClasses || !e.el) return;
      const t = e.el.className.split(" ").filter((t) => 0 === t.indexOf("swiper") || 0 === t.indexOf(e.params.containerModifierClass));
      e.emit("_containerClasses", t.join(" "));
    }
    getSlideClasses(e) {
      const t = this;
      return t.destroyed
        ? ""
        : e.className
          .split(" ")
          .filter((e) => 0 === e.indexOf("swiper-slide") || 0 === e.indexOf(t.params.slideClass))
          .join(" ");
    }
    emitSlidesClasses() {
      const e = this;
      if (!e.params._emitClasses || !e.el) return;
      const t = [];
      e.slides.forEach((r) => {
        const a = e.getSlideClasses(r);
        t.push({ slideEl: r, classNames: a }), e.emit("_slideClass", r, a);
      }),
        e.emit("_slideClasses", t);
    }
    slidesPerViewDynamic(e, t) {
      void 0 === e && (e = "current"), void 0 === t && (t = !1);
      const { params: r, slides: a, slidesGrid: s, slidesSizesGrid: i, size: n, activeIndex: o } = this;
      let l = 1;
      if ("number" == typeof r.slidesPerView) return r.slidesPerView;
      if (r.centeredSlides) {
        let e,
          t = a[o] ? Math.ceil(a[o].swiperSlideSize) : 0;
        for (let r = o + 1; r < a.length; r += 1) a[r] && !e && ((t += Math.ceil(a[r].swiperSlideSize)), (l += 1), t > n && (e = !0));
        for (let r = o - 1; r >= 0; r -= 1) a[r] && !e && ((t += a[r].swiperSlideSize), (l += 1), t > n && (e = !0));
      } else if ("current" === e) for (let e = o + 1; e < a.length; e += 1) (t ? s[e] + i[e] - s[o] < n : s[e] - s[o] < n) && (l += 1);
      else for (let e = o - 1; e >= 0; e -= 1) s[o] - s[e] < n && (l += 1);
      return l;
    }
    update() {
      const e = this;
      if (!e || e.destroyed) return;
      const { snapGrid: t, params: r } = e;
      function a() {
        const t = e.rtlTranslate ? -1 * e.translate : e.translate,
          r = Math.min(Math.max(t, e.maxTranslate()), e.minTranslate());
        e.setTranslate(r), e.updateActiveIndex(), e.updateSlidesClasses();
      }
      let s;
      if (
        (r.breakpoints && e.setBreakpoint(),
          [...e.el.querySelectorAll('[loading="lazy"]')].forEach((t) => {
            t.complete && A(e, t);
          }),
          e.updateSize(),
          e.updateSlides(),
          e.updateProgress(),
          e.updateSlidesClasses(),
        r.freeMode && r.freeMode.enabled && !r.cssMode)
      )
        a(), r.autoHeight && e.updateAutoHeight();
      else {
        if (("auto" === r.slidesPerView || r.slidesPerView > 1) && e.isEnd && !r.centeredSlides) {
          const t = e.virtual && r.virtual.enabled ? e.virtual.slides : e.slides;
          s = e.slideTo(t.length - 1, 0, !1, !0);
        } else s = e.slideTo(e.activeIndex, 0, !1, !0);
        s || a();
      }
      r.watchOverflow && t !== e.snapGrid && e.checkOverflow(), e.emit("update");
    }
    changeDirection(e, t) {
      void 0 === t && (t = !0);
      const r = this,
        a = r.params.direction;
      return (
        e || (e = "horizontal" === a ? "vertical" : "horizontal"),
        e === a ||
        ("horizontal" !== e && "vertical" !== e) ||
        (r.el.classList.remove(`${r.params.containerModifierClass}${a}`),
          r.el.classList.add(`${r.params.containerModifierClass}${e}`),
          r.emitContainerClasses(),
          (r.params.direction = e),
          r.slides.forEach((t) => {
            "vertical" === e ? (t.style.width = "") : (t.style.height = "");
          }),
          r.emit("changeDirection"),
        t && r.update()),
          r
      );
    }
    changeLanguageDirection(e) {
      const t = this;
      (t.rtl && "rtl" === e) ||
      (!t.rtl && "ltr" === e) ||
      ((t.rtl = "rtl" === e),
        (t.rtlTranslate = "horizontal" === t.params.direction && t.rtl),
        t.rtl ? (t.el.classList.add(`${t.params.containerModifierClass}rtl`), (t.el.dir = "rtl")) : (t.el.classList.remove(`${t.params.containerModifierClass}rtl`), (t.el.dir = "ltr")),
        t.update());
    }
    mount(e) {
      const t = this;
      if (t.mounted) return !0;
      let r = e || t.params.el;
      if (("string" == typeof r && (r = document.querySelector(r)), !r)) return !1;
      (r.swiper = t), r.parentNode && r.parentNode.host && r.parentNode.host.nodeName === t.params.swiperElementNodeName.toUpperCase() && (t.isElement = !0);
      const a = () => `.${(t.params.wrapperClass || "").trim().split(" ").join(".")}`;
      let s = r && r.shadowRoot && r.shadowRoot.querySelector ? r.shadowRoot.querySelector(a()) : h(r, a())[0];
      return (
        !s &&
        t.params.createElements &&
        ((s = v("div", t.params.wrapperClass)),
          r.append(s),
          h(r, `.${t.params.slideClass}`).forEach((e) => {
            s.append(e);
          })),
          Object.assign(t, {
            el: r,
            wrapperEl: s,
            slidesEl: t.isElement && !r.parentNode.host.slideSlots ? r.parentNode.host : s,
            hostEl: t.isElement ? r.parentNode.host : r,
            mounted: !0,
            rtl: "rtl" === r.dir.toLowerCase() || "rtl" === w(r, "direction"),
            rtlTranslate: "horizontal" === t.params.direction && ("rtl" === r.dir.toLowerCase() || "rtl" === w(r, "direction")),
            wrongRTL: "-webkit-box" === w(s, "display"),
          }),
          !0
      );
    }
    init(e) {
      const t = this;
      if (t.initialized) return t;
      if (!1 === t.mount(e)) return t;
      t.emit("beforeInit"),
      t.params.breakpoints && t.setBreakpoint(),
        t.addClasses(),
        t.updateSize(),
        t.updateSlides(),
      t.params.watchOverflow && t.checkOverflow(),
      t.params.grabCursor && t.enabled && t.setGrabCursor(),
        t.params.loop && t.virtual && t.params.virtual.enabled
          ? t.slideTo(t.params.initialSlide + t.virtual.slidesBefore, 0, t.params.runCallbacksOnInit, !1, !0)
          : t.slideTo(t.params.initialSlide, 0, t.params.runCallbacksOnInit, !1, !0),
      t.params.loop && t.loopCreate(),
        t.attachEvents();
      const r = [...t.el.querySelectorAll('[loading="lazy"]')];
      return (
        t.isElement && r.push(...t.hostEl.querySelectorAll('[loading="lazy"]')),
          r.forEach((e) => {
            e.complete
              ? A(t, e)
              : e.addEventListener("load", (e) => {
                A(t, e.target);
              });
          }),
          $(t),
          (t.initialized = !0),
          $(t),
          t.emit("init"),
          t.emit("afterInit"),
          t
      );
    }
    destroy(e, t) {
      void 0 === e && (e = !0), void 0 === t && (t = !0);
      const r = this,
        { params: a, el: s, wrapperEl: i, slides: n } = r;
      return (
        void 0 === r.params ||
        r.destroyed ||
        (r.emit("beforeDestroy"),
          (r.initialized = !1),
          r.detachEvents(),
        a.loop && r.loopDestroy(),
        t &&
        (r.removeClasses(),
          s.removeAttribute("style"),
          i.removeAttribute("style"),
        n &&
        n.length &&
        n.forEach((e) => {
          e.classList.remove(a.slideVisibleClass, a.slideFullyVisibleClass, a.slideActiveClass, a.slideNextClass, a.slidePrevClass), e.removeAttribute("style"), e.removeAttribute("data-swiper-slide-index");
        })),
          r.emit("destroy"),
          Object.keys(r.eventsListeners).forEach((e) => {
            r.off(e);
          }),
        !1 !== e &&
        ((r.el.swiper = null),
          (function (e) {
            const t = e;
            Object.keys(t).forEach((e) => {
              try {
                t[e] = null;
              } catch (e) {}
              try {
                delete t[e];
              } catch (e) {}
            });
          })(r)),
          (r.destroyed = !0)),
          null
      );
    }
    static extendDefaults(e) {
      u(J, e);
    }
    static get extendedDefaults() {
      return J;
    }
    static get defaults() {
      return K;
    }
    static installModule(e) {
      ee.prototype.__modules__ || (ee.prototype.__modules__ = []);
      const t = ee.prototype.__modules__;
      "function" == typeof e && t.indexOf(e) < 0 && t.push(e);
    }
    static use(e) {
      return Array.isArray(e) ? (e.forEach((e) => ee.installModule(e)), ee) : (ee.installModule(e), ee);
    }
  }
  function te(e, t, r, a) {
    return (
      e.params.createElements &&
      Object.keys(a).forEach((s) => {
        if (!r[s] && !0 === r.auto) {
          let i = h(e.el, `.${a[s]}`)[0];
          i || ((i = v("div", a[s])), (i.className = a[s]), e.el.append(i)), (r[s] = i), (t[s] = i);
        }
      }),
        r
    );
  }
  function re(e) {
    return (
      void 0 === e && (e = ""),
        `.${e
          .trim()
          .replace(/([\.:!+\/])/g, "\\$1")
          .replace(/ /g, ".")}`
    );
  }
  function ae(e) {
    const t = this,
      { params: r, slidesEl: a } = t;
    r.loop && t.loopDestroy();
    const s = (e) => {
      if ("string" == typeof e) {
        const t = document.createElement("div");
        (t.innerHTML = e), a.append(t.children[0]), (t.innerHTML = "");
      } else a.append(e);
    };
    if ("object" == typeof e && "length" in e) for (let t = 0; t < e.length; t += 1) e[t] && s(e[t]);
    else s(e);
    t.recalcSlides(), r.loop && t.loopCreate(), (r.observer && !t.isElement) || t.update();
  }
  function se(e) {
    const t = this,
      { params: r, activeIndex: a, slidesEl: s } = t;
    r.loop && t.loopDestroy();
    let i = a + 1;
    const n = (e) => {
      if ("string" == typeof e) {
        const t = document.createElement("div");
        (t.innerHTML = e), s.prepend(t.children[0]), (t.innerHTML = "");
      } else s.prepend(e);
    };
    if ("object" == typeof e && "length" in e) {
      for (let t = 0; t < e.length; t += 1) e[t] && n(e[t]);
      i = a + e.length;
    } else n(e);
    t.recalcSlides(), r.loop && t.loopCreate(), (r.observer && !t.isElement) || t.update(), t.slideTo(i, 0, !1);
  }
  function ie(e, t) {
    const r = this,
      { params: a, activeIndex: s, slidesEl: i } = r;
    let n = s;
    a.loop && ((n -= r.loopedSlides), r.loopDestroy(), r.recalcSlides());
    const o = r.slides.length;
    if (e <= 0) return void r.prependSlide(t);
    if (e >= o) return void r.appendSlide(t);
    let l = n > e ? n + 1 : n;
    const d = [];
    for (let t = o - 1; t >= e; t -= 1) {
      const e = r.slides[t];
      e.remove(), d.unshift(e);
    }
    if ("object" == typeof t && "length" in t) {
      for (let e = 0; e < t.length; e += 1) t[e] && i.append(t[e]);
      l = n > e ? n + t.length : n;
    } else i.append(t);
    for (let e = 0; e < d.length; e += 1) i.append(d[e]);
    r.recalcSlides(), a.loop && r.loopCreate(), (a.observer && !r.isElement) || r.update(), a.loop ? r.slideTo(l + r.loopedSlides, 0, !1) : r.slideTo(l, 0, !1);
  }
  function ne(e) {
    const t = this,
      { params: r, activeIndex: a } = t;
    let s = a;
    r.loop && ((s -= t.loopedSlides), t.loopDestroy());
    let i,
      n = s;
    if ("object" == typeof e && "length" in e) {
      for (let r = 0; r < e.length; r += 1) (i = e[r]), t.slides[i] && t.slides[i].remove(), i < n && (n -= 1);
      n = Math.max(n, 0);
    } else (i = e), t.slides[i] && t.slides[i].remove(), i < n && (n -= 1), (n = Math.max(n, 0));
    t.recalcSlides(), r.loop && t.loopCreate(), (r.observer && !t.isElement) || t.update(), r.loop ? t.slideTo(n + t.loopedSlides, 0, !1) : t.slideTo(n, 0, !1);
  }
  function oe() {
    const e = this,
      t = [];
    for (let r = 0; r < e.slides.length; r += 1) t.push(r);
    e.removeSlide(t);
  }
  function le(e) {
    const { effect: t, swiper: r, on: a, setTranslate: s, setTransition: i, overwriteParams: n, perspective: o, recreateShadows: l, getEffectParams: d } = e;
    let c;
    a("beforeInit", () => {
      if (r.params.effect !== t) return;
      r.classNames.push(`${r.params.containerModifierClass}${t}`), o && o() && r.classNames.push(`${r.params.containerModifierClass}3d`);
      const e = n ? n() : {};
      Object.assign(r.params, e), Object.assign(r.originalParams, e);
    }),
      a("setTranslate", () => {
        r.params.effect === t && s();
      }),
      a("setTransition", (e, a) => {
        r.params.effect === t && i(a);
      }),
      a("transitionEnd", () => {
        if (r.params.effect === t && l) {
          if (!d || !d().slideShadows) return;
          r.slides.forEach((e) => {
            e.querySelectorAll(".swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left").forEach((e) => e.remove());
          }),
            l();
        }
      }),
      a("virtualUpdate", () => {
        r.params.effect === t &&
        (r.slides.length || (c = !0),
          requestAnimationFrame(() => {
            c && r.slides && r.slides.length && (s(), (c = !1));
          }));
      });
  }
  function de(e, t) {
    const r = f(t);
    return r !== t && ((r.style.backfaceVisibility = "hidden"), (r.style["-webkit-backface-visibility"] = "hidden")), r;
  }
  function ce(e) {
    let { swiper: t, duration: r, transformElements: a, allSlides: s } = e;
    const { activeIndex: i } = t;
    if (t.params.virtualTranslate && 0 !== r) {
      let e,
        r = !1;
      (e = s
        ? a
        : a.filter((e) => {
          const r = e.classList.contains("swiper-slide-transform") ? ((e) => (e.parentElement ? e.parentElement : t.slides.filter((t) => t.shadowRoot && t.shadowRoot === e.parentNode)[0]))(e) : e;
          return t.getSlideIndex(r) === i;
        })),
        e.forEach((e) => {
          E(e, () => {
            if (r) return;
            if (!t || t.destroyed) return;
            (r = !0), (t.animating = !1);
            const e = new window.CustomEvent("transitionend", { bubbles: !0, cancelable: !0 });
            t.wrapperEl.dispatchEvent(e);
          });
        });
    }
  }
  function ue(e, t, r) {
    const a = `swiper-slide-shadow${r ? `-${r}` : ""}${e ? ` swiper-slide-shadow-${e}` : ""}`,
      s = f(t);
    let i = s.querySelector(`.${a.split(" ").join(".")}`);
    return i || ((i = v("div", a.split(" "))), s.append(i)), i;
  }
  Object.keys(Q).forEach((e) => {
    Object.keys(Q[e]).forEach((t) => {
      ee.prototype[t] = Q[e][t];
    });
  }),
    ee.use([
      function (e) {
        let { swiper: t, on: r, emit: a } = e;
        const s = i();
        let n = null,
          o = null;
        const l = () => {
            t && !t.destroyed && t.initialized && (a("beforeResize"), a("resize"));
          },
          d = () => {
            t && !t.destroyed && t.initialized && a("orientationchange");
          };
        r("init", () => {
          t.params.resizeObserver && void 0 !== s.ResizeObserver
            ? t &&
            !t.destroyed &&
            t.initialized &&
            ((n = new ResizeObserver((e) => {
              o = s.requestAnimationFrame(() => {
                const { width: r, height: a } = t;
                let s = r,
                  i = a;
                e.forEach((e) => {
                  let { contentBoxSize: r, contentRect: a, target: n } = e;
                  (n && n !== t.el) || ((s = a ? a.width : (r[0] || r).inlineSize), (i = a ? a.height : (r[0] || r).blockSize));
                }),
                (s === r && i === a) || l();
              });
            })),
              n.observe(t.el))
            : (s.addEventListener("resize", l), s.addEventListener("orientationchange", d));
        }),
          r("destroy", () => {
            o && s.cancelAnimationFrame(o), n && n.unobserve && t.el && (n.unobserve(t.el), (n = null)), s.removeEventListener("resize", l), s.removeEventListener("orientationchange", d);
          });
      },
      function (e) {
        let { swiper: t, extendParams: r, on: a, emit: s } = e;
        const n = [],
          o = i(),
          l = function (e, r) {
            void 0 === r && (r = {});
            const a = new (o.MutationObserver || o.WebkitMutationObserver)((e) => {
              if (t.__preventObserver__) return;
              if (1 === e.length) return void s("observerUpdate", e[0]);
              const r = function () {
                s("observerUpdate", e[0]);
              };
              o.requestAnimationFrame ? o.requestAnimationFrame(r) : o.setTimeout(r, 0);
            });
            a.observe(e, { attributes: void 0 === r.attributes || r.attributes, childList: void 0 === r.childList || r.childList, characterData: void 0 === r.characterData || r.characterData }), n.push(a);
          };
        r({ observer: !1, observeParents: !1, observeSlideChildren: !1 }),
          a("init", () => {
            if (t.params.observer) {
              if (t.params.observeParents) {
                const e = x(t.hostEl);
                for (let t = 0; t < e.length; t += 1) l(e[t]);
              }
              l(t.hostEl, { childList: t.params.observeSlideChildren }), l(t.wrapperEl, { attributes: !1 });
            }
          }),
          a("destroy", () => {
            n.forEach((e) => {
              e.disconnect();
            }),
              n.splice(0, n.length);
          });
      },
    ]);
  const pe = [
    function (e) {
      let t,
        { swiper: r, extendParams: s, on: i, emit: n } = e;
      s({ virtual: { enabled: !1, slides: [], cache: !0, renderSlide: null, renderExternal: null, renderExternalUpdate: !0, addSlidesBefore: 0, addSlidesAfter: 0 } });
      const o = a();
      r.virtual = { cache: {}, from: void 0, to: void 0, slides: [], offset: 0, slidesGrid: [] };
      const l = o.createElement("div");
      function d(e, t) {
        const a = r.params.virtual;
        if (a.cache && r.virtual.cache[t]) return r.virtual.cache[t];
        let s;
        return (
          a.renderSlide ? ((s = a.renderSlide.call(r, e, t)), "string" == typeof s && ((l.innerHTML = s), (s = l.children[0]))) : (s = r.isElement ? v("swiper-slide") : v("div", r.params.slideClass)),
            s.setAttribute("data-swiper-slide-index", t),
          a.renderSlide || (s.innerHTML = e),
          a.cache && (r.virtual.cache[t] = s),
            s
        );
      }
      function c(e, t) {
        const { slidesPerView: a, slidesPerGroup: s, centeredSlides: i, loop: o, initialSlide: l } = r.params;
        if (t && !o && l > 0) return;
        const { addSlidesBefore: c, addSlidesAfter: u } = r.params.virtual,
          { from: p, to: m, slides: f, slidesGrid: g, offset: v } = r.virtual;
        r.params.cssMode || r.updateActiveIndex();
        const y = r.activeIndex || 0;
        let w, b, x;
        (w = r.rtlTranslate ? "right" : r.isHorizontal() ? "left" : "top"), i ? ((b = Math.floor(a / 2) + s + u), (x = Math.floor(a / 2) + s + c)) : ((b = a + (s - 1) + u), (x = (o ? a : s) + c));
        let E = y - x,
          S = y + b;
        o || ((E = Math.max(E, 0)), (S = Math.min(S, f.length - 1)));
        let T = (r.slidesGrid[E] || 0) - (r.slidesGrid[0] || 0);
        function M() {
          r.updateSlides(), r.updateProgress(), r.updateSlidesClasses(), n("virtualUpdate");
        }
        if (
          (o && y >= x ? ((E -= x), i || (T += r.slidesGrid[0])) : o && y < x && ((E = -x), i && (T += r.slidesGrid[0])),
            Object.assign(r.virtual, { from: E, to: S, offset: T, slidesGrid: r.slidesGrid, slidesBefore: x, slidesAfter: b }),
          p === E && m === S && !e)
        )
          return (
            r.slidesGrid !== g &&
            T !== v &&
            r.slides.forEach((e) => {
              e.style[w] = T - Math.abs(r.cssOverflowAdjustment()) + "px";
            }),
              r.updateProgress(),
              void n("virtualUpdate")
          );
        if (r.params.virtual.renderExternal)
          return (
            r.params.virtual.renderExternal.call(r, {
              offset: T,
              from: E,
              to: S,
              slides: (function () {
                const e = [];
                for (let t = E; t <= S; t += 1) e.push(f[t]);
                return e;
              })(),
            }),
              void (r.params.virtual.renderExternalUpdate ? M() : n("virtualUpdate"))
          );
        const C = [],
          L = [],
          P = (e) => {
            let t = e;
            return e < 0 ? (t = f.length + e) : t >= f.length && (t -= f.length), t;
          };
        if (e)
          r.slides
            .filter((e) => e.matches(`.${r.params.slideClass}, swiper-slide`))
            .forEach((e) => {
              e.remove();
            });
        else
          for (let e = p; e <= m; e += 1)
            if (e < E || e > S) {
              const t = P(e);
              r.slides
                .filter((e) => e.matches(`.${r.params.slideClass}[data-swiper-slide-index="${t}"], swiper-slide[data-swiper-slide-index="${t}"]`))
                .forEach((e) => {
                  e.remove();
                });
            }
        const I = o ? -f.length : 0,
          O = o ? 2 * f.length : f.length;
        for (let t = I; t < O; t += 1)
          if (t >= E && t <= S) {
            const r = P(t);
            void 0 === m || e ? L.push(r) : (t > m && L.push(r), t < p && C.push(r));
          }
        if (
          (L.forEach((e) => {
            r.slidesEl.append(d(f[e], e));
          }),
            o)
        )
          for (let e = C.length - 1; e >= 0; e -= 1) {
            const t = C[e];
            r.slidesEl.prepend(d(f[t], t));
          }
        else
          C.sort((e, t) => t - e),
            C.forEach((e) => {
              r.slidesEl.prepend(d(f[e], e));
            });
        h(r.slidesEl, ".swiper-slide, swiper-slide").forEach((e) => {
          e.style[w] = T - Math.abs(r.cssOverflowAdjustment()) + "px";
        }),
          M();
      }
      i("beforeInit", () => {
        if (!r.params.virtual.enabled) return;
        let e;
        if (void 0 === r.passedParams.virtual.slides) {
          const t = [...r.slidesEl.children].filter((e) => e.matches(`.${r.params.slideClass}, swiper-slide`));
          t &&
          t.length &&
          ((r.virtual.slides = [...t]),
            (e = !0),
            t.forEach((e, t) => {
              e.setAttribute("data-swiper-slide-index", t), (r.virtual.cache[t] = e), e.remove();
            }));
        }
        e || (r.virtual.slides = r.params.virtual.slides), r.classNames.push(`${r.params.containerModifierClass}virtual`), (r.params.watchSlidesProgress = !0), (r.originalParams.watchSlidesProgress = !0), c(!1, !0);
      }),
        i("setTranslate", () => {
          r.params.virtual.enabled &&
          (r.params.cssMode && !r._immediateVirtual
            ? (clearTimeout(t),
              (t = setTimeout(() => {
                c();
              }, 100)))
            : c());
        }),
        i("init update resize", () => {
          r.params.virtual.enabled && r.params.cssMode && p(r.wrapperEl, "--swiper-virtual-size", `${r.virtualSize}px`);
        }),
        Object.assign(r.virtual, {
          appendSlide: function (e) {
            if ("object" == typeof e && "length" in e) for (let t = 0; t < e.length; t += 1) e[t] && r.virtual.slides.push(e[t]);
            else r.virtual.slides.push(e);
            c(!0);
          },
          prependSlide: function (e) {
            const t = r.activeIndex;
            let a = t + 1,
              s = 1;
            if (Array.isArray(e)) {
              for (let t = 0; t < e.length; t += 1) e[t] && r.virtual.slides.unshift(e[t]);
              (a = t + e.length), (s = e.length);
            } else r.virtual.slides.unshift(e);
            if (r.params.virtual.cache) {
              const e = r.virtual.cache,
                t = {};
              Object.keys(e).forEach((r) => {
                const a = e[r],
                  i = a.getAttribute("data-swiper-slide-index");
                i && a.setAttribute("data-swiper-slide-index", parseInt(i, 10) + s), (t[parseInt(r, 10) + s] = a);
              }),
                (r.virtual.cache = t);
            }
            c(!0), r.slideTo(a, 0);
          },
          removeSlide: function (e) {
            if (null == e) return;
            let t = r.activeIndex;
            if (Array.isArray(e))
              for (let a = e.length - 1; a >= 0; a -= 1)
                r.params.virtual.cache &&
                (delete r.virtual.cache[e[a]],
                  Object.keys(r.virtual.cache).forEach((t) => {
                    t > e && ((r.virtual.cache[t - 1] = r.virtual.cache[t]), r.virtual.cache[t - 1].setAttribute("data-swiper-slide-index", t - 1), delete r.virtual.cache[t]);
                  })),
                  r.virtual.slides.splice(e[a], 1),
                e[a] < t && (t -= 1),
                  (t = Math.max(t, 0));
            else
              r.params.virtual.cache &&
              (delete r.virtual.cache[e],
                Object.keys(r.virtual.cache).forEach((t) => {
                  t > e && ((r.virtual.cache[t - 1] = r.virtual.cache[t]), r.virtual.cache[t - 1].setAttribute("data-swiper-slide-index", t - 1), delete r.virtual.cache[t]);
                })),
                r.virtual.slides.splice(e, 1),
              e < t && (t -= 1),
                (t = Math.max(t, 0));
            c(!0), r.slideTo(t, 0);
          },
          removeAllSlides: function () {
            (r.virtual.slides = []), r.params.virtual.cache && (r.virtual.cache = {}), c(!0), r.slideTo(0, 0);
          },
          update: c,
        });
    },
    function (e) {
      let { swiper: t, extendParams: r, on: s, emit: n } = e;
      const o = a(),
        l = i();
      function d(e) {
        if (!t.enabled) return;
        const { rtlTranslate: r } = t;
        let a = e;
        a.originalEvent && (a = a.originalEvent);
        const s = a.keyCode || a.charCode,
          i = t.params.keyboard.pageUpDown,
          d = i && 33 === s,
          c = i && 34 === s,
          u = 37 === s,
          p = 39 === s,
          m = 38 === s,
          f = 40 === s;
        if (!t.allowSlideNext && ((t.isHorizontal() && p) || (t.isVertical() && f) || c)) return !1;
        if (!t.allowSlidePrev && ((t.isHorizontal() && u) || (t.isVertical() && m) || d)) return !1;
        if (!(a.shiftKey || a.altKey || a.ctrlKey || a.metaKey || (o.activeElement && o.activeElement.nodeName && ("input" === o.activeElement.nodeName.toLowerCase() || "textarea" === o.activeElement.nodeName.toLowerCase())))) {
          if (t.params.keyboard.onlyInViewport && (d || c || u || p || m || f)) {
            let e = !1;
            if (x(t.el, `.${t.params.slideClass}, swiper-slide`).length > 0 && 0 === x(t.el, `.${t.params.slideActiveClass}`).length) return;
            const a = t.el,
              s = a.clientWidth,
              i = a.clientHeight,
              n = l.innerWidth,
              o = l.innerHeight,
              d = y(a);
            r && (d.left -= a.scrollLeft);
            const c = [
              [d.left, d.top],
              [d.left + s, d.top],
              [d.left, d.top + i],
              [d.left + s, d.top + i],
            ];
            for (let t = 0; t < c.length; t += 1) {
              const r = c[t];
              if (r[0] >= 0 && r[0] <= n && r[1] >= 0 && r[1] <= o) {
                if (0 === r[0] && 0 === r[1]) continue;
                e = !0;
              }
            }
            if (!e) return;
          }
          t.isHorizontal()
            ? ((d || c || u || p) && (a.preventDefault ? a.preventDefault() : (a.returnValue = !1)), (((c || p) && !r) || ((d || u) && r)) && t.slideNext(), (((d || u) && !r) || ((c || p) && r)) && t.slidePrev())
            : ((d || c || m || f) && (a.preventDefault ? a.preventDefault() : (a.returnValue = !1)), (c || f) && t.slideNext(), (d || m) && t.slidePrev()),
            n("keyPress", s);
        }
      }
      function c() {
        t.keyboard.enabled || (o.addEventListener("keydown", d), (t.keyboard.enabled = !0));
      }
      function u() {
        t.keyboard.enabled && (o.removeEventListener("keydown", d), (t.keyboard.enabled = !1));
      }
      (t.keyboard = { enabled: !1 }),
        r({ keyboard: { enabled: !1, onlyInViewport: !0, pageUpDown: !0 } }),
        s("init", () => {
          t.params.keyboard.enabled && c();
        }),
        s("destroy", () => {
          t.keyboard.enabled && u();
        }),
        Object.assign(t.keyboard, { enable: c, disable: u });
    },
    function (e) {
      let { swiper: t, extendParams: r, on: a, emit: s } = e;
      const n = i();
      let d;
      r({ mousewheel: { enabled: !1, releaseOnEdges: !1, invert: !1, forceToAxis: !1, sensitivity: 1, eventsTarget: "container", thresholdDelta: null, thresholdTime: null, noMousewheelClass: "swiper-no-mousewheel" } }),
        (t.mousewheel = { enabled: !1 });
      let c,
        u = l();
      const p = [];
      function m() {
        t.enabled && (t.mouseEntered = !0);
      }
      function f() {
        t.enabled && (t.mouseEntered = !1);
      }
      function h(e) {
        return !(
          (t.params.mousewheel.thresholdDelta && e.delta < t.params.mousewheel.thresholdDelta) ||
          (t.params.mousewheel.thresholdTime && l() - u < t.params.mousewheel.thresholdTime) ||
          (!(e.delta >= 6 && l() - u < 60) &&
            (e.direction < 0 ? (t.isEnd && !t.params.loop) || t.animating || (t.slideNext(), s("scroll", e.raw)) : (t.isBeginning && !t.params.loop) || t.animating || (t.slidePrev(), s("scroll", e.raw)),
              (u = new n.Date().getTime()),
              1))
        );
      }
      function g(e) {
        let r = e,
          a = !0;
        if (!t.enabled) return;
        if (e.target.closest(`.${t.params.mousewheel.noMousewheelClass}`)) return;
        const i = t.params.mousewheel;
        t.params.cssMode && r.preventDefault();
        let n = t.el;
        "container" !== t.params.mousewheel.eventsTarget && (n = document.querySelector(t.params.mousewheel.eventsTarget));
        const u = n && n.contains(r.target);
        if (!t.mouseEntered && !u && !i.releaseOnEdges) return !0;
        r.originalEvent && (r = r.originalEvent);
        let m = 0;
        const f = t.rtlTranslate ? -1 : 1,
          g = (function (e) {
            let t = 0,
              r = 0,
              a = 0,
              s = 0;
            return (
              "detail" in e && (r = e.detail),
              "wheelDelta" in e && (r = -e.wheelDelta / 120),
              "wheelDeltaY" in e && (r = -e.wheelDeltaY / 120),
              "wheelDeltaX" in e && (t = -e.wheelDeltaX / 120),
              "axis" in e && e.axis === e.HORIZONTAL_AXIS && ((t = r), (r = 0)),
                (a = 10 * t),
                (s = 10 * r),
              "deltaY" in e && (s = e.deltaY),
              "deltaX" in e && (a = e.deltaX),
              e.shiftKey && !a && ((a = s), (s = 0)),
              (a || s) && e.deltaMode && (1 === e.deltaMode ? ((a *= 40), (s *= 40)) : ((a *= 800), (s *= 800))),
              a && !t && (t = a < 1 ? -1 : 1),
              s && !r && (r = s < 1 ? -1 : 1),
                { spinX: t, spinY: r, pixelX: a, pixelY: s }
            );
          })(r);
        if (i.forceToAxis)
          if (t.isHorizontal()) {
            if (!(Math.abs(g.pixelX) > Math.abs(g.pixelY))) return !0;
            m = -g.pixelX * f;
          } else {
            if (!(Math.abs(g.pixelY) > Math.abs(g.pixelX))) return !0;
            m = -g.pixelY;
          }
        else m = Math.abs(g.pixelX) > Math.abs(g.pixelY) ? -g.pixelX * f : -g.pixelY;
        if (0 === m) return !0;
        i.invert && (m = -m);
        let v = t.getTranslate() + m * i.sensitivity;
        if (
          (v >= t.minTranslate() && (v = t.minTranslate()),
          v <= t.maxTranslate() && (v = t.maxTranslate()),
            (a = !!t.params.loop || !(v === t.minTranslate() || v === t.maxTranslate())),
          a && t.params.nested && r.stopPropagation(),
          t.params.freeMode && t.params.freeMode.enabled)
        ) {
          const e = { time: l(), delta: Math.abs(m), direction: Math.sign(m) },
            a = c && e.time < c.time + 500 && e.delta <= c.delta && e.direction === c.direction;
          if (!a) {
            c = void 0;
            let n = t.getTranslate() + m * i.sensitivity;
            const l = t.isBeginning,
              u = t.isEnd;
            if (
              (n >= t.minTranslate() && (n = t.minTranslate()),
              n <= t.maxTranslate() && (n = t.maxTranslate()),
                t.setTransition(0),
                t.setTranslate(n),
                t.updateProgress(),
                t.updateActiveIndex(),
                t.updateSlidesClasses(),
              ((!l && t.isBeginning) || (!u && t.isEnd)) && t.updateSlidesClasses(),
              t.params.loop && t.loopFix({ direction: e.direction < 0 ? "next" : "prev", byMousewheel: !0 }),
                t.params.freeMode.sticky)
            ) {
              clearTimeout(d), (d = void 0), p.length >= 15 && p.shift();
              const r = p.length ? p[p.length - 1] : void 0,
                a = p[0];
              if ((p.push(e), r && (e.delta > r.delta || e.direction !== r.direction))) p.splice(0);
              else if (p.length >= 15 && e.time - a.time < 500 && a.delta - e.delta >= 1 && e.delta <= 6) {
                const r = m > 0 ? 0.8 : 0.2;
                (c = e),
                  p.splice(0),
                  (d = o(() => {
                    t.slideToClosest(t.params.speed, !0, void 0, r);
                  }, 0));
              }
              d ||
              (d = o(() => {
                (c = e), p.splice(0), t.slideToClosest(t.params.speed, !0, void 0, 0.5);
              }, 500));
            }
            if ((a || s("scroll", r), t.params.autoplay && t.params.autoplayDisableOnInteraction && t.autoplay.stop(), i.releaseOnEdges && (n === t.minTranslate() || n === t.maxTranslate()))) return !0;
          }
        } else {
          const r = { time: l(), delta: Math.abs(m), direction: Math.sign(m), raw: e };
          p.length >= 2 && p.shift();
          const a = p.length ? p[p.length - 1] : void 0;
          if (
            (p.push(r),
              a ? (r.direction !== a.direction || r.delta > a.delta || r.time > a.time + 150) && h(r) : h(r),
              (function (e) {
                const r = t.params.mousewheel;
                if (e.direction < 0) {
                  if (t.isEnd && !t.params.loop && r.releaseOnEdges) return !0;
                } else if (t.isBeginning && !t.params.loop && r.releaseOnEdges) return !0;
                return !1;
              })(r))
          )
            return !0;
        }
        return r.preventDefault ? r.preventDefault() : (r.returnValue = !1), !1;
      }
      function v(e) {
        let r = t.el;
        "container" !== t.params.mousewheel.eventsTarget && (r = document.querySelector(t.params.mousewheel.eventsTarget)), r[e]("mouseenter", m), r[e]("mouseleave", f), r[e]("wheel", g);
      }
      function y() {
        return t.params.cssMode ? (t.wrapperEl.removeEventListener("wheel", g), !0) : !t.mousewheel.enabled && (v("addEventListener"), (t.mousewheel.enabled = !0), !0);
      }
      function w() {
        return t.params.cssMode ? (t.wrapperEl.addEventListener(event, g), !0) : !!t.mousewheel.enabled && (v("removeEventListener"), (t.mousewheel.enabled = !1), !0);
      }
      a("init", () => {
        !t.params.mousewheel.enabled && t.params.cssMode && w(), t.params.mousewheel.enabled && y();
      }),
        a("destroy", () => {
          t.params.cssMode && y(), t.mousewheel.enabled && w();
        }),
        Object.assign(t.mousewheel, { enable: y, disable: w });
    },
    function (e) {
      let { swiper: t, extendParams: r, on: a, emit: s } = e;
      function i(e) {
        let r;
        return e && "string" == typeof e && t.isElement && ((r = t.el.querySelector(e)), r)
          ? r
          : (e &&
          ("string" == typeof e && (r = [...document.querySelectorAll(e)]),
            t.params.uniqueNavElements && "string" == typeof e && r && r.length > 1 && 1 === t.el.querySelectorAll(e).length ? (r = t.el.querySelector(e)) : r && 1 === r.length && (r = r[0])),
            e && !r ? e : r);
      }
      function n(e, r) {
        const a = t.params.navigation;
        (e = T(e)).forEach((e) => {
          e && (e.classList[r ? "add" : "remove"](...a.disabledClass.split(" ")), "BUTTON" === e.tagName && (e.disabled = r), t.params.watchOverflow && t.enabled && e.classList[t.isLocked ? "add" : "remove"](a.lockClass));
        });
      }
      function o() {
        const { nextEl: e, prevEl: r } = t.navigation;
        if (t.params.loop) return n(r, !1), void n(e, !1);
        n(r, t.isBeginning && !t.params.rewind), n(e, t.isEnd && !t.params.rewind);
      }
      function l(e) {
        e.preventDefault(), (!t.isBeginning || t.params.loop || t.params.rewind) && (t.slidePrev(), s("navigationPrev"));
      }
      function d(e) {
        e.preventDefault(), (!t.isEnd || t.params.loop || t.params.rewind) && (t.slideNext(), s("navigationNext"));
      }
      function c() {
        const e = t.params.navigation;
        if (((t.params.navigation = te(t, t.originalParams.navigation, t.params.navigation, { nextEl: "swiper-button-next", prevEl: "swiper-button-prev" })), !e.nextEl && !e.prevEl)) return;
        let r = i(e.nextEl),
          a = i(e.prevEl);
        Object.assign(t.navigation, { nextEl: r, prevEl: a }), (r = T(r)), (a = T(a));
        const s = (r, a) => {
          r && r.addEventListener("click", "next" === a ? d : l), !t.enabled && r && r.classList.add(...e.lockClass.split(" "));
        };
        r.forEach((e) => s(e, "next")), a.forEach((e) => s(e, "prev"));
      }
      function u() {
        let { nextEl: e, prevEl: r } = t.navigation;
        (e = T(e)), (r = T(r));
        const a = (e, r) => {
          e.removeEventListener("click", "next" === r ? d : l), e.classList.remove(...t.params.navigation.disabledClass.split(" "));
        };
        e.forEach((e) => a(e, "next")), r.forEach((e) => a(e, "prev"));
      }
      r({
        navigation: {
          nextEl: null,
          prevEl: null,
          hideOnClick: !1,
          disabledClass: "swiper-button-disabled",
          hiddenClass: "swiper-button-hidden",
          lockClass: "swiper-button-lock",
          navigationDisabledClass: "swiper-navigation-disabled",
        },
      }),
        (t.navigation = { nextEl: null, prevEl: null }),
        a("init", () => {
          !1 === t.params.navigation.enabled ? p() : (c(), o());
        }),
        a("toEdge fromEdge lock unlock", () => {
          o();
        }),
        a("destroy", () => {
          u();
        }),
        a("enable disable", () => {
          let { nextEl: e, prevEl: r } = t.navigation;
          (e = T(e)), (r = T(r)), t.enabled ? o() : [...e, ...r].filter((e) => !!e).forEach((e) => e.classList.add(t.params.navigation.lockClass));
        }),
        a("click", (e, r) => {
          let { nextEl: a, prevEl: i } = t.navigation;
          (a = T(a)), (i = T(i));
          const n = r.target;
          if (t.params.navigation.hideOnClick && !i.includes(n) && !a.includes(n)) {
            if (t.pagination && t.params.pagination && t.params.pagination.clickable && (t.pagination.el === n || t.pagination.el.contains(n))) return;
            let e;
            a.length ? (e = a[0].classList.contains(t.params.navigation.hiddenClass)) : i.length && (e = i[0].classList.contains(t.params.navigation.hiddenClass)),
              s(!0 === e ? "navigationShow" : "navigationHide"),
              [...a, ...i].filter((e) => !!e).forEach((e) => e.classList.toggle(t.params.navigation.hiddenClass));
          }
        });
      const p = () => {
        t.el.classList.add(...t.params.navigation.navigationDisabledClass.split(" ")), u();
      };
      Object.assign(t.navigation, {
        enable: () => {
          t.el.classList.remove(...t.params.navigation.navigationDisabledClass.split(" ")), c(), o();
        },
        disable: p,
        update: o,
        init: c,
        destroy: u,
      });
    },
    function (e) {
      let { swiper: t, extendParams: r, on: a, emit: s } = e;
      const i = "swiper-pagination";
      let n;
      r({
        pagination: {
          el: null,
          bulletElement: "span",
          clickable: !1,
          hideOnClick: !1,
          renderBullet: null,
          renderProgressbar: null,
          renderFraction: null,
          renderCustom: null,
          progressbarOpposite: !1,
          type: "bullets",
          dynamicBullets: !1,
          dynamicMainBullets: 1,
          formatFractionCurrent: (e) => e,
          formatFractionTotal: (e) => e,
          bulletClass: `${i}-bullet`,
          bulletActiveClass: `${i}-bullet-active`,
          modifierClass: `${i}-`,
          currentClass: `${i}-current`,
          totalClass: `${i}-total`,
          hiddenClass: `${i}-hidden`,
          progressbarFillClass: `${i}-progressbar-fill`,
          progressbarOppositeClass: `${i}-progressbar-opposite`,
          clickableClass: `${i}-clickable`,
          lockClass: `${i}-lock`,
          horizontalClass: `${i}-horizontal`,
          verticalClass: `${i}-vertical`,
          paginationDisabledClass: `${i}-disabled`,
        },
      }),
        (t.pagination = { el: null, bullets: [] });
      let o = 0;
      function l() {
        return !t.params.pagination.el || !t.pagination.el || (Array.isArray(t.pagination.el) && 0 === t.pagination.el.length);
      }
      function d(e, r) {
        const { bulletActiveClass: a } = t.params.pagination;
        e && (e = e[("prev" === r ? "previous" : "next") + "ElementSibling"]) && (e.classList.add(`${a}-${r}`), (e = e[("prev" === r ? "previous" : "next") + "ElementSibling"]) && e.classList.add(`${a}-${r}-${r}`));
      }
      function c(e) {
        const r = e.target.closest(re(t.params.pagination.bulletClass));
        if (!r) return;
        e.preventDefault();
        const a = b(r) * t.params.slidesPerGroup;
        if (t.params.loop) {
          if (t.realIndex === a) return;
          t.slideToLoop(a);
        } else t.slideTo(a);
      }
      function u() {
        const e = t.rtl,
          r = t.params.pagination;
        if (l()) return;
        let a,
          i,
          c = t.pagination.el;
        c = T(c);
        const u = t.virtual && t.params.virtual.enabled ? t.virtual.slides.length : t.slides.length,
          p = t.params.loop ? Math.ceil(u / t.params.slidesPerGroup) : t.snapGrid.length;
        if (
          (t.params.loop
            ? ((i = t.previousRealIndex || 0), (a = t.params.slidesPerGroup > 1 ? Math.floor(t.realIndex / t.params.slidesPerGroup) : t.realIndex))
            : void 0 !== t.snapIndex
              ? ((a = t.snapIndex), (i = t.previousSnapIndex))
              : ((i = t.previousIndex || 0), (a = t.activeIndex || 0)),
          "bullets" === r.type && t.pagination.bullets && t.pagination.bullets.length > 0)
        ) {
          const s = t.pagination.bullets;
          let l, u, p;
          if (
            (r.dynamicBullets &&
            ((n = S(s[0], t.isHorizontal() ? "width" : "height", !0)),
              c.forEach((e) => {
                e.style[t.isHorizontal() ? "width" : "height"] = n * (r.dynamicMainBullets + 4) + "px";
              }),
            r.dynamicMainBullets > 1 && void 0 !== i && ((o += a - (i || 0)), o > r.dynamicMainBullets - 1 ? (o = r.dynamicMainBullets - 1) : o < 0 && (o = 0)),
              (l = Math.max(a - o, 0)),
              (u = l + (Math.min(s.length, r.dynamicMainBullets) - 1)),
              (p = (u + l) / 2)),
              s.forEach((e) => {
                const t = [...["", "-next", "-next-next", "-prev", "-prev-prev", "-main"].map((e) => `${r.bulletActiveClass}${e}`)].map((e) => ("string" == typeof e && e.includes(" ") ? e.split(" ") : e)).flat();
                e.classList.remove(...t);
              }),
            c.length > 1)
          )
            s.forEach((e) => {
              const s = b(e);
              s === a ? e.classList.add(...r.bulletActiveClass.split(" ")) : t.isElement && e.setAttribute("part", "bullet"),
              r.dynamicBullets && (s >= l && s <= u && e.classList.add(...`${r.bulletActiveClass}-main`.split(" ")), s === l && d(e, "prev"), s === u && d(e, "next"));
            });
          else {
            const e = s[a];
            if (
              (e && e.classList.add(...r.bulletActiveClass.split(" ")),
              t.isElement &&
              s.forEach((e, t) => {
                e.setAttribute("part", t === a ? "bullet-active" : "bullet");
              }),
                r.dynamicBullets)
            ) {
              const e = s[l],
                t = s[u];
              for (let e = l; e <= u; e += 1) s[e] && s[e].classList.add(...`${r.bulletActiveClass}-main`.split(" "));
              d(e, "prev"), d(t, "next");
            }
          }
          if (r.dynamicBullets) {
            const a = Math.min(s.length, r.dynamicMainBullets + 4),
              i = (n * a - n) / 2 - p * n,
              o = e ? "right" : "left";
            s.forEach((e) => {
              e.style[t.isHorizontal() ? o : "top"] = `${i}px`;
            });
          }
        }
        c.forEach((e, i) => {
          if (
            ("fraction" === r.type &&
            (e.querySelectorAll(re(r.currentClass)).forEach((e) => {
              e.textContent = r.formatFractionCurrent(a + 1);
            }),
              e.querySelectorAll(re(r.totalClass)).forEach((e) => {
                e.textContent = r.formatFractionTotal(p);
              })),
            "progressbar" === r.type)
          ) {
            let s;
            s = r.progressbarOpposite ? (t.isHorizontal() ? "vertical" : "horizontal") : t.isHorizontal() ? "horizontal" : "vertical";
            const i = (a + 1) / p;
            let n = 1,
              o = 1;
            "horizontal" === s ? (n = i) : (o = i),
              e.querySelectorAll(re(r.progressbarFillClass)).forEach((e) => {
                (e.style.transform = `translate3d(0,0,0) scaleX(${n}) scaleY(${o})`), (e.style.transitionDuration = `${t.params.speed}ms`);
              });
          }
          "custom" === r.type && r.renderCustom ? ((e.innerHTML = r.renderCustom(t, a + 1, p)), 0 === i && s("paginationRender", e)) : (0 === i && s("paginationRender", e), s("paginationUpdate", e)),
          t.params.watchOverflow && t.enabled && e.classList[t.isLocked ? "add" : "remove"](r.lockClass);
        });
      }
      function p() {
        const e = t.params.pagination;
        if (l()) return;
        const r = t.virtual && t.params.virtual.enabled ? t.virtual.slides.length : t.grid && t.params.grid.rows > 1 ? t.slides.length / Math.ceil(t.params.grid.rows) : t.slides.length;
        let a = t.pagination.el;
        a = T(a);
        let i = "";
        if ("bullets" === e.type) {
          let a = t.params.loop ? Math.ceil(r / t.params.slidesPerGroup) : t.snapGrid.length;
          t.params.freeMode && t.params.freeMode.enabled && a > r && (a = r);
          for (let r = 0; r < a; r += 1) e.renderBullet ? (i += e.renderBullet.call(t, r, e.bulletClass)) : (i += `<${e.bulletElement} ${t.isElement ? 'part="bullet"' : ""} class="${e.bulletClass}"></${e.bulletElement}>`);
        }
        "fraction" === e.type && (i = e.renderFraction ? e.renderFraction.call(t, e.currentClass, e.totalClass) : `<span class="${e.currentClass}"></span> / <span class="${e.totalClass}"></span>`),
        "progressbar" === e.type && (i = e.renderProgressbar ? e.renderProgressbar.call(t, e.progressbarFillClass) : `<span class="${e.progressbarFillClass}"></span>`),
          (t.pagination.bullets = []),
          a.forEach((r) => {
            "custom" !== e.type && (r.innerHTML = i || ""), "bullets" === e.type && t.pagination.bullets.push(...r.querySelectorAll(re(e.bulletClass)));
          }),
        "custom" !== e.type && s("paginationRender", a[0]);
      }
      function m() {
        t.params.pagination = te(t, t.originalParams.pagination, t.params.pagination, { el: "swiper-pagination" });
        const e = t.params.pagination;
        if (!e.el) return;
        let r;
        "string" == typeof e.el && t.isElement && (r = t.el.querySelector(e.el)),
        r || "string" != typeof e.el || (r = [...document.querySelectorAll(e.el)]),
        r || (r = e.el),
        r &&
        0 !== r.length &&
        (t.params.uniqueNavElements && "string" == typeof e.el && Array.isArray(r) && r.length > 1 && ((r = [...t.el.querySelectorAll(e.el)]), r.length > 1 && (r = r.filter((e) => x(e, ".swiper")[0] === t.el)[0])),
        Array.isArray(r) && 1 === r.length && (r = r[0]),
          Object.assign(t.pagination, { el: r }),
          (r = T(r)),
          r.forEach((r) => {
            "bullets" === e.type && e.clickable && r.classList.add(...(e.clickableClass || "").split(" ")),
              r.classList.add(e.modifierClass + e.type),
              r.classList.add(t.isHorizontal() ? e.horizontalClass : e.verticalClass),
            "bullets" === e.type && e.dynamicBullets && (r.classList.add(`${e.modifierClass}${e.type}-dynamic`), (o = 0), e.dynamicMainBullets < 1 && (e.dynamicMainBullets = 1)),
            "progressbar" === e.type && e.progressbarOpposite && r.classList.add(e.progressbarOppositeClass),
            e.clickable && r.addEventListener("click", c),
            t.enabled || r.classList.add(e.lockClass);
          }));
      }
      function f() {
        const e = t.params.pagination;
        if (l()) return;
        let r = t.pagination.el;
        r &&
        ((r = T(r)),
          r.forEach((r) => {
            r.classList.remove(e.hiddenClass),
              r.classList.remove(e.modifierClass + e.type),
              r.classList.remove(t.isHorizontal() ? e.horizontalClass : e.verticalClass),
            e.clickable && (r.classList.remove(...(e.clickableClass || "").split(" ")), r.removeEventListener("click", c));
          })),
        t.pagination.bullets && t.pagination.bullets.forEach((t) => t.classList.remove(...e.bulletActiveClass.split(" ")));
      }
      a("changeDirection", () => {
        if (!t.pagination || !t.pagination.el) return;
        const e = t.params.pagination;
        let { el: r } = t.pagination;
        (r = T(r)),
          r.forEach((r) => {
            r.classList.remove(e.horizontalClass, e.verticalClass), r.classList.add(t.isHorizontal() ? e.horizontalClass : e.verticalClass);
          });
      }),
        a("init", () => {
          !1 === t.params.pagination.enabled ? h() : (m(), p(), u());
        }),
        a("activeIndexChange", () => {
          void 0 === t.snapIndex && u();
        }),
        a("snapIndexChange", () => {
          u();
        }),
        a("snapGridLengthChange", () => {
          p(), u();
        }),
        a("destroy", () => {
          f();
        }),
        a("enable disable", () => {
          let { el: e } = t.pagination;
          e && ((e = T(e)), e.forEach((e) => e.classList[t.enabled ? "remove" : "add"](t.params.pagination.lockClass)));
        }),
        a("lock unlock", () => {
          u();
        }),
        a("click", (e, r) => {
          const a = r.target,
            i = T(t.pagination.el);
          if (t.params.pagination.el && t.params.pagination.hideOnClick && i && i.length > 0 && !a.classList.contains(t.params.pagination.bulletClass)) {
            if (t.navigation && ((t.navigation.nextEl && a === t.navigation.nextEl) || (t.navigation.prevEl && a === t.navigation.prevEl))) return;
            const e = i[0].classList.contains(t.params.pagination.hiddenClass);
            s(!0 === e ? "paginationShow" : "paginationHide"), i.forEach((e) => e.classList.toggle(t.params.pagination.hiddenClass));
          }
        });
      const h = () => {
        t.el.classList.add(t.params.pagination.paginationDisabledClass);
        let { el: e } = t.pagination;
        e && ((e = T(e)), e.forEach((e) => e.classList.add(t.params.pagination.paginationDisabledClass))), f();
      };
      Object.assign(t.pagination, {
        enable: () => {
          t.el.classList.remove(t.params.pagination.paginationDisabledClass);
          let { el: e } = t.pagination;
          e && ((e = T(e)), e.forEach((e) => e.classList.remove(t.params.pagination.paginationDisabledClass))), m(), p(), u();
        },
        disable: h,
        render: p,
        update: u,
        init: m,
        destroy: f,
      });
    },
    function (e) {
      let { swiper: t, extendParams: r, on: s, emit: i } = e;
      const l = a();
      let d,
        c,
        u,
        p,
        m = !1,
        f = null,
        h = null;
      function g() {
        if (!t.params.scrollbar.el || !t.scrollbar.el) return;
        const { scrollbar: e, rtlTranslate: r } = t,
          { dragEl: a, el: s } = e,
          i = t.params.scrollbar,
          n = t.params.loop ? t.progressLoop : t.progress;
        let o = c,
          l = (u - c) * n;
        r ? ((l = -l), l > 0 ? ((o = c - l), (l = 0)) : -l + c > u && (o = u + l)) : l < 0 ? ((o = c + l), (l = 0)) : l + c > u && (o = u - l),
          t.isHorizontal() ? ((a.style.transform = `translate3d(${l}px, 0, 0)`), (a.style.width = `${o}px`)) : ((a.style.transform = `translate3d(0px, ${l}px, 0)`), (a.style.height = `${o}px`)),
        i.hide &&
        (clearTimeout(f),
          (s.style.opacity = 1),
          (f = setTimeout(() => {
            (s.style.opacity = 0), (s.style.transitionDuration = "400ms");
          }, 1e3)));
      }
      function w() {
        if (!t.params.scrollbar.el || !t.scrollbar.el) return;
        const { scrollbar: e } = t,
          { dragEl: r, el: a } = e;
        (r.style.width = ""),
          (r.style.height = ""),
          (u = t.isHorizontal() ? a.offsetWidth : a.offsetHeight),
          (p = t.size / (t.virtualSize + t.params.slidesOffsetBefore - (t.params.centeredSlides ? t.snapGrid[0] : 0))),
          (c = "auto" === t.params.scrollbar.dragSize ? u * p : parseInt(t.params.scrollbar.dragSize, 10)),
          t.isHorizontal() ? (r.style.width = `${c}px`) : (r.style.height = `${c}px`),
          (a.style.display = p >= 1 ? "none" : ""),
        t.params.scrollbar.hide && (a.style.opacity = 0),
        t.params.watchOverflow && t.enabled && e.el.classList[t.isLocked ? "add" : "remove"](t.params.scrollbar.lockClass);
      }
      function b(e) {
        return t.isHorizontal() ? e.clientX : e.clientY;
      }
      function x(e) {
        const { scrollbar: r, rtlTranslate: a } = t,
          { el: s } = r;
        let i;
        (i = (b(e) - y(s)[t.isHorizontal() ? "left" : "top"] - (null !== d ? d : c / 2)) / (u - c)), (i = Math.max(Math.min(i, 1), 0)), a && (i = 1 - i);
        const n = t.minTranslate() + (t.maxTranslate() - t.minTranslate()) * i;
        t.updateProgress(n), t.setTranslate(n), t.updateActiveIndex(), t.updateSlidesClasses();
      }
      function E(e) {
        const r = t.params.scrollbar,
          { scrollbar: a, wrapperEl: s } = t,
          { el: n, dragEl: o } = a;
        (m = !0),
          (d = e.target === o ? b(e) - e.target.getBoundingClientRect()[t.isHorizontal() ? "left" : "top"] : null),
          e.preventDefault(),
          e.stopPropagation(),
          (s.style.transitionDuration = "100ms"),
          (o.style.transitionDuration = "100ms"),
          x(e),
          clearTimeout(h),
          (n.style.transitionDuration = "0ms"),
        r.hide && (n.style.opacity = 1),
        t.params.cssMode && (t.wrapperEl.style["scroll-snap-type"] = "none"),
          i("scrollbarDragStart", e);
      }
      function S(e) {
        const { scrollbar: r, wrapperEl: a } = t,
          { el: s, dragEl: n } = r;
        m &&
        (e.preventDefault && e.cancelable ? e.preventDefault() : (e.returnValue = !1),
          x(e),
          (a.style.transitionDuration = "0ms"),
          (s.style.transitionDuration = "0ms"),
          (n.style.transitionDuration = "0ms"),
          i("scrollbarDragMove", e));
      }
      function M(e) {
        const r = t.params.scrollbar,
          { scrollbar: a, wrapperEl: s } = t,
          { el: n } = a;
        m &&
        ((m = !1),
        t.params.cssMode && ((t.wrapperEl.style["scroll-snap-type"] = ""), (s.style.transitionDuration = "")),
        r.hide &&
        (clearTimeout(h),
          (h = o(() => {
            (n.style.opacity = 0), (n.style.transitionDuration = "400ms");
          }, 1e3))),
          i("scrollbarDragEnd", e),
        r.snapOnRelease && t.slideToClosest());
      }
      function C(e) {
        const { scrollbar: r, params: a } = t,
          s = r.el;
        if (!s) return;
        const i = s,
          n = !!a.passiveListeners && { passive: !1, capture: !1 },
          o = !!a.passiveListeners && { passive: !0, capture: !1 };
        if (!i) return;
        const d = "on" === e ? "addEventListener" : "removeEventListener";
        i[d]("pointerdown", E, n), l[d]("pointermove", S, n), l[d]("pointerup", M, o);
      }
      function L() {
        const { scrollbar: e, el: r } = t;
        t.params.scrollbar = te(t, t.originalParams.scrollbar, t.params.scrollbar, { el: "swiper-scrollbar" });
        const a = t.params.scrollbar;
        if (!a.el) return;
        let s, i;
        if (("string" == typeof a.el && t.isElement && (s = t.el.querySelector(a.el)), s || "string" != typeof a.el)) s || (s = a.el);
        else if (((s = l.querySelectorAll(a.el)), !s.length)) return;
        t.params.uniqueNavElements && "string" == typeof a.el && s.length > 1 && 1 === r.querySelectorAll(a.el).length && (s = r.querySelector(a.el)),
        s.length > 0 && (s = s[0]),
          s.classList.add(t.isHorizontal() ? a.horizontalClass : a.verticalClass),
        s && ((i = s.querySelector(re(t.params.scrollbar.dragClass))), i || ((i = v("div", t.params.scrollbar.dragClass)), s.append(i))),
          Object.assign(e, { el: s, dragEl: i }),
        a.draggable && t.params.scrollbar.el && t.scrollbar.el && C("on"),
        s && s.classList[t.enabled ? "remove" : "add"](...n(t.params.scrollbar.lockClass));
      }
      function P() {
        const e = t.params.scrollbar,
          r = t.scrollbar.el;
        r && r.classList.remove(...n(t.isHorizontal() ? e.horizontalClass : e.verticalClass)), t.params.scrollbar.el && t.scrollbar.el && C("off");
      }
      r({
        scrollbar: {
          el: null,
          dragSize: "auto",
          hide: !1,
          draggable: !1,
          snapOnRelease: !0,
          lockClass: "swiper-scrollbar-lock",
          dragClass: "swiper-scrollbar-drag",
          scrollbarDisabledClass: "swiper-scrollbar-disabled",
          horizontalClass: "swiper-scrollbar-horizontal",
          verticalClass: "swiper-scrollbar-vertical",
        },
      }),
        (t.scrollbar = { el: null, dragEl: null }),
        s("changeDirection", () => {
          if (!t.scrollbar || !t.scrollbar.el) return;
          const e = t.params.scrollbar;
          let { el: r } = t.scrollbar;
          (r = T(r)),
            r.forEach((r) => {
              r.classList.remove(e.horizontalClass, e.verticalClass), r.classList.add(t.isHorizontal() ? e.horizontalClass : e.verticalClass);
            });
        }),
        s("init", () => {
          !1 === t.params.scrollbar.enabled ? I() : (L(), w(), g());
        }),
        s("update resize observerUpdate lock unlock changeDirection", () => {
          w();
        }),
        s("setTranslate", () => {
          g();
        }),
        s("setTransition", (e, r) => {
          !(function (e) {
            t.params.scrollbar.el && t.scrollbar.el && (t.scrollbar.dragEl.style.transitionDuration = `${e}ms`);
          })(r);
        }),
        s("enable disable", () => {
          const { el: e } = t.scrollbar;
          e && e.classList[t.enabled ? "remove" : "add"](...n(t.params.scrollbar.lockClass));
        }),
        s("destroy", () => {
          P();
        });
      const I = () => {
        t.el.classList.add(...n(t.params.scrollbar.scrollbarDisabledClass)), t.scrollbar.el && t.scrollbar.el.classList.add(...n(t.params.scrollbar.scrollbarDisabledClass)), P();
      };
      Object.assign(t.scrollbar, {
        enable: () => {
          t.el.classList.remove(...n(t.params.scrollbar.scrollbarDisabledClass)), t.scrollbar.el && t.scrollbar.el.classList.remove(...n(t.params.scrollbar.scrollbarDisabledClass)), L(), w(), g();
        },
        disable: I,
        updateSize: w,
        setTranslate: g,
        init: L,
        destroy: P,
      });
    },
    function (e) {
      let { swiper: t, extendParams: r, on: a } = e;
      r({ parallax: { enabled: !1 } });
      const s = "[data-swiper-parallax], [data-swiper-parallax-x], [data-swiper-parallax-y], [data-swiper-parallax-opacity], [data-swiper-parallax-scale]",
        i = (e, r) => {
          const { rtl: a } = t,
            s = a ? -1 : 1,
            i = e.getAttribute("data-swiper-parallax") || "0";
          let n = e.getAttribute("data-swiper-parallax-x"),
            o = e.getAttribute("data-swiper-parallax-y");
          const l = e.getAttribute("data-swiper-parallax-scale"),
            d = e.getAttribute("data-swiper-parallax-opacity"),
            c = e.getAttribute("data-swiper-parallax-rotate");
          if (
            (n || o ? ((n = n || "0"), (o = o || "0")) : t.isHorizontal() ? ((n = i), (o = "0")) : ((o = i), (n = "0")),
              (n = n.indexOf("%") >= 0 ? parseInt(n, 10) * r * s + "%" : n * r * s + "px"),
              (o = o.indexOf("%") >= 0 ? parseInt(o, 10) * r + "%" : o * r + "px"),
            null != d)
          ) {
            const t = d - (d - 1) * (1 - Math.abs(r));
            e.style.opacity = t;
          }
          let u = `translate3d(${n}, ${o}, 0px)`;
          null != l && (u += ` scale(${l - (l - 1) * (1 - Math.abs(r))})`), c && null != c && (u += ` rotate(${c * r * -1}deg)`), (e.style.transform = u);
        },
        n = () => {
          const { el: e, slides: r, progress: a, snapGrid: n, isElement: o } = t,
            l = h(e, s);
          t.isElement && l.push(...h(t.hostEl, s)),
            l.forEach((e) => {
              i(e, a);
            }),
            r.forEach((e, r) => {
              let o = e.progress;
              t.params.slidesPerGroup > 1 && "auto" !== t.params.slidesPerView && (o += Math.ceil(r / 2) - a * (n.length - 1)),
                (o = Math.min(Math.max(o, -1), 1)),
                e.querySelectorAll(`${s}, [data-swiper-parallax-rotate]`).forEach((e) => {
                  i(e, o);
                });
            });
        };
      a("beforeInit", () => {
        t.params.parallax.enabled && ((t.params.watchSlidesProgress = !0), (t.originalParams.watchSlidesProgress = !0));
      }),
        a("init", () => {
          t.params.parallax.enabled && n();
        }),
        a("setTranslate", () => {
          t.params.parallax.enabled && n();
        }),
        a("setTransition", (e, r) => {
          t.params.parallax.enabled &&
          (function (e) {
            void 0 === e && (e = t.params.speed);
            const { el: r, hostEl: a } = t,
              i = [...r.querySelectorAll(s)];
            t.isElement && i.push(...a.querySelectorAll(s)),
              i.forEach((t) => {
                let r = parseInt(t.getAttribute("data-swiper-parallax-duration"), 10) || e;
                0 === e && (r = 0), (t.style.transitionDuration = `${r}ms`);
              });
          })(r);
        });
    },
    function (e) {
      let { swiper: t, extendParams: r, on: a, emit: s } = e;
      const n = i();
      r({ zoom: { enabled: !1, limitToOriginalSize: !1, maxRatio: 3, minRatio: 1, toggle: !0, containerClass: "swiper-zoom-container", zoomedSlideClass: "swiper-slide-zoomed" } }), (t.zoom = { enabled: !1 });
      let o,
        l,
        c = 1,
        u = !1;
      const p = [],
        m = { originX: 0, originY: 0, slideEl: void 0, slideWidth: void 0, slideHeight: void 0, imageEl: void 0, imageWrapEl: void 0, maxRatio: 3 },
        f = {
          isTouched: void 0,
          isMoved: void 0,
          currentX: void 0,
          currentY: void 0,
          minX: void 0,
          minY: void 0,
          maxX: void 0,
          maxY: void 0,
          width: void 0,
          height: void 0,
          startX: void 0,
          startY: void 0,
          touchesStart: {},
          touchesCurrent: {},
        },
        g = { x: void 0, y: void 0, prevPositionX: void 0, prevPositionY: void 0, prevTime: void 0 };
      let v,
        w = 1;
      function b() {
        if (p.length < 2) return 1;
        const e = p[0].pageX,
          t = p[0].pageY,
          r = p[1].pageX,
          a = p[1].pageY;
        return Math.sqrt((r - e) ** 2 + (a - t) ** 2);
      }
      function E() {
        const e = t.params.zoom,
          r = m.imageWrapEl.getAttribute("data-swiper-zoom") || e.maxRatio;
        if (e.limitToOriginalSize && m.imageEl && m.imageEl.naturalWidth) {
          const e = m.imageEl.naturalWidth / m.imageEl.offsetWidth;
          return Math.min(e, r);
        }
        return r;
      }
      function S(e) {
        const r = t.isElement ? "swiper-slide" : `.${t.params.slideClass}`;
        return !!e.target.matches(r) || t.slides.filter((t) => t.contains(e.target)).length > 0;
      }
      function T(e) {
        if (("mouse" === e.pointerType && p.splice(0, p.length), !S(e))) return;
        const r = t.params.zoom;
        if (((o = !1), (l = !1), p.push(e), !(p.length < 2))) {
          if (((o = !0), (m.scaleStart = b()), !m.slideEl)) {
            (m.slideEl = e.target.closest(`.${t.params.slideClass}, swiper-slide`)), m.slideEl || (m.slideEl = t.slides[t.activeIndex]);
            let a = m.slideEl.querySelector(`.${r.containerClass}`);
            if ((a && (a = a.querySelectorAll("picture, img, svg, canvas, .swiper-zoom-target")[0]), (m.imageEl = a), (m.imageWrapEl = a ? x(m.imageEl, `.${r.containerClass}`)[0] : void 0), !m.imageWrapEl))
              return void (m.imageEl = void 0);
            m.maxRatio = E();
          }
          if (m.imageEl) {
            const [e, t] = (function () {
              if (p.length < 2) return { x: null, y: null };
              const e = m.imageEl.getBoundingClientRect();
              return [(p[0].pageX + (p[1].pageX - p[0].pageX) / 2 - e.x - n.scrollX) / c, (p[0].pageY + (p[1].pageY - p[0].pageY) / 2 - e.y - n.scrollY) / c];
            })();
            (m.originX = e), (m.originY = t), (m.imageEl.style.transitionDuration = "0ms");
          }
          u = !0;
        }
      }
      function M(e) {
        if (!S(e)) return;
        const r = t.params.zoom,
          a = t.zoom,
          s = p.findIndex((t) => t.pointerId === e.pointerId);
        s >= 0 && (p[s] = e),
        p.length < 2 ||
        ((l = !0),
          (m.scaleMove = b()),
        m.imageEl &&
        ((a.scale = (m.scaleMove / m.scaleStart) * c),
        a.scale > m.maxRatio && (a.scale = m.maxRatio - 1 + (a.scale - m.maxRatio + 1) ** 0.5),
        a.scale < r.minRatio && (a.scale = r.minRatio + 1 - (r.minRatio - a.scale + 1) ** 0.5),
          (m.imageEl.style.transform = `translate3d(0,0,0) scale(${a.scale})`)));
      }
      function C(e) {
        if (!S(e)) return;
        if ("mouse" === e.pointerType && "pointerout" === e.type) return;
        const r = t.params.zoom,
          a = t.zoom,
          s = p.findIndex((t) => t.pointerId === e.pointerId);
        s >= 0 && p.splice(s, 1),
        o &&
        l &&
        ((o = !1),
          (l = !1),
        m.imageEl &&
        ((a.scale = Math.max(Math.min(a.scale, m.maxRatio), r.minRatio)),
          (m.imageEl.style.transitionDuration = `${t.params.speed}ms`),
          (m.imageEl.style.transform = `translate3d(0,0,0) scale(${a.scale})`),
          (c = a.scale),
          (u = !1),
          a.scale > 1 && m.slideEl ? m.slideEl.classList.add(`${r.zoomedSlideClass}`) : a.scale <= 1 && m.slideEl && m.slideEl.classList.remove(`${r.zoomedSlideClass}`),
        1 === a.scale && ((m.originX = 0), (m.originY = 0), (m.slideEl = void 0))));
      }
      function L() {
        t.touchEventsData.preventTouchMoveFromPointerMove = !1;
      }
      function P(e) {
        if (
          !S(e) ||
          !(function (e) {
            const r = `.${t.params.zoom.containerClass}`;
            return !!e.target.matches(r) || [...t.hostEl.querySelectorAll(r)].filter((t) => t.contains(e.target)).length > 0;
          })(e)
        )
          return;
        const r = t.zoom;
        if (!m.imageEl) return;
        if (!f.isTouched || !m.slideEl) return;
        f.isMoved ||
        ((f.width = m.imageEl.offsetWidth || m.imageEl.clientWidth),
          (f.height = m.imageEl.offsetHeight || m.imageEl.clientHeight),
          (f.startX = d(m.imageWrapEl, "x") || 0),
          (f.startY = d(m.imageWrapEl, "y") || 0),
          (m.slideWidth = m.slideEl.offsetWidth),
          (m.slideHeight = m.slideEl.offsetHeight),
          (m.imageWrapEl.style.transitionDuration = "0ms"));
        const a = f.width * r.scale,
          s = f.height * r.scale;
        if (a < m.slideWidth && s < m.slideHeight) return void L();
        if (
          ((f.minX = Math.min(m.slideWidth / 2 - a / 2, 0)),
            (f.maxX = -f.minX),
            (f.minY = Math.min(m.slideHeight / 2 - s / 2, 0)),
            (f.maxY = -f.minY),
            (f.touchesCurrent.x = p.length > 0 ? p[0].pageX : e.pageX),
            (f.touchesCurrent.y = p.length > 0 ? p[0].pageY : e.pageY),
          Math.max(Math.abs(f.touchesCurrent.x - f.touchesStart.x), Math.abs(f.touchesCurrent.y - f.touchesStart.y)) > 5 && (t.allowClick = !1),
          !f.isMoved && !u)
        ) {
          if (t.isHorizontal() && ((Math.floor(f.minX) === Math.floor(f.startX) && f.touchesCurrent.x < f.touchesStart.x) || (Math.floor(f.maxX) === Math.floor(f.startX) && f.touchesCurrent.x > f.touchesStart.x)))
            return (f.isTouched = !1), void L();
          if (!t.isHorizontal() && ((Math.floor(f.minY) === Math.floor(f.startY) && f.touchesCurrent.y < f.touchesStart.y) || (Math.floor(f.maxY) === Math.floor(f.startY) && f.touchesCurrent.y > f.touchesStart.y)))
            return (f.isTouched = !1), void L();
        }
        e.cancelable && e.preventDefault(),
          e.stopPropagation(),
          clearTimeout(v),
          (t.touchEventsData.preventTouchMoveFromPointerMove = !0),
          (v = setTimeout(() => {
            L();
          })),
          (f.isMoved = !0);
        const i = (r.scale - c) / (m.maxRatio - t.params.zoom.minRatio),
          { originX: n, originY: o } = m;
        (f.currentX = f.touchesCurrent.x - f.touchesStart.x + f.startX + i * (f.width - 2 * n)),
          (f.currentY = f.touchesCurrent.y - f.touchesStart.y + f.startY + i * (f.height - 2 * o)),
        f.currentX < f.minX && (f.currentX = f.minX + 1 - (f.minX - f.currentX + 1) ** 0.8),
        f.currentX > f.maxX && (f.currentX = f.maxX - 1 + (f.currentX - f.maxX + 1) ** 0.8),
        f.currentY < f.minY && (f.currentY = f.minY + 1 - (f.minY - f.currentY + 1) ** 0.8),
        f.currentY > f.maxY && (f.currentY = f.maxY - 1 + (f.currentY - f.maxY + 1) ** 0.8),
        g.prevPositionX || (g.prevPositionX = f.touchesCurrent.x),
        g.prevPositionY || (g.prevPositionY = f.touchesCurrent.y),
        g.prevTime || (g.prevTime = Date.now()),
          (g.x = (f.touchesCurrent.x - g.prevPositionX) / (Date.now() - g.prevTime) / 2),
          (g.y = (f.touchesCurrent.y - g.prevPositionY) / (Date.now() - g.prevTime) / 2),
        Math.abs(f.touchesCurrent.x - g.prevPositionX) < 2 && (g.x = 0),
        Math.abs(f.touchesCurrent.y - g.prevPositionY) < 2 && (g.y = 0),
          (g.prevPositionX = f.touchesCurrent.x),
          (g.prevPositionY = f.touchesCurrent.y),
          (g.prevTime = Date.now()),
          (m.imageWrapEl.style.transform = `translate3d(${f.currentX}px, ${f.currentY}px,0)`);
      }
      function I() {
        const e = t.zoom;
        m.slideEl &&
        t.activeIndex !== t.slides.indexOf(m.slideEl) &&
        (m.imageEl && (m.imageEl.style.transform = "translate3d(0,0,0) scale(1)"),
        m.imageWrapEl && (m.imageWrapEl.style.transform = "translate3d(0,0,0)"),
          m.slideEl.classList.remove(`${t.params.zoom.zoomedSlideClass}`),
          (e.scale = 1),
          (c = 1),
          (m.slideEl = void 0),
          (m.imageEl = void 0),
          (m.imageWrapEl = void 0),
          (m.originX = 0),
          (m.originY = 0));
      }
      function O(e) {
        const r = t.zoom,
          a = t.params.zoom;
        if (!m.slideEl) {
          e && e.target && (m.slideEl = e.target.closest(`.${t.params.slideClass}, swiper-slide`)),
          m.slideEl || (t.params.virtual && t.params.virtual.enabled && t.virtual ? (m.slideEl = h(t.slidesEl, `.${t.params.slideActiveClass}`)[0]) : (m.slideEl = t.slides[t.activeIndex]));
          let r = m.slideEl.querySelector(`.${a.containerClass}`);
          r && (r = r.querySelectorAll("picture, img, svg, canvas, .swiper-zoom-target")[0]), (m.imageEl = r), (m.imageWrapEl = r ? x(m.imageEl, `.${a.containerClass}`)[0] : void 0);
        }
        if (!m.imageEl || !m.imageWrapEl) return;
        let s, i, o, l, d, u, p, g, v, w, b, S, T, M, C, L, P, I;
        t.params.cssMode && ((t.wrapperEl.style.overflow = "hidden"), (t.wrapperEl.style.touchAction = "none")),
          m.slideEl.classList.add(`${a.zoomedSlideClass}`),
          void 0 === f.touchesStart.x && e ? ((s = e.pageX), (i = e.pageY)) : ((s = f.touchesStart.x), (i = f.touchesStart.y));
        const O = "number" == typeof e ? e : null;
        1 === c && O && ((s = void 0), (i = void 0));
        const k = E();
        (r.scale = O || k),
          (c = O || k),
          !e || (1 === c && O)
            ? ((p = 0), (g = 0))
            : ((P = m.slideEl.offsetWidth),
              (I = m.slideEl.offsetHeight),
              (o = y(m.slideEl).left + n.scrollX),
              (l = y(m.slideEl).top + n.scrollY),
              (d = o + P / 2 - s),
              (u = l + I / 2 - i),
              (v = m.imageEl.offsetWidth || m.imageEl.clientWidth),
              (w = m.imageEl.offsetHeight || m.imageEl.clientHeight),
              (b = v * r.scale),
              (S = w * r.scale),
              (T = Math.min(P / 2 - b / 2, 0)),
              (M = Math.min(I / 2 - S / 2, 0)),
              (C = -T),
              (L = -M),
              (p = d * r.scale),
              (g = u * r.scale),
            p < T && (p = T),
            p > C && (p = C),
            g < M && (g = M),
            g > L && (g = L)),
        O && 1 === r.scale && ((m.originX = 0), (m.originY = 0)),
          (m.imageWrapEl.style.transitionDuration = "300ms"),
          (m.imageWrapEl.style.transform = `translate3d(${p}px, ${g}px,0)`),
          (m.imageEl.style.transitionDuration = "300ms"),
          (m.imageEl.style.transform = `translate3d(0,0,0) scale(${r.scale})`);
      }
      function k() {
        const e = t.zoom,
          r = t.params.zoom;
        if (!m.slideEl) {
          t.params.virtual && t.params.virtual.enabled && t.virtual ? (m.slideEl = h(t.slidesEl, `.${t.params.slideActiveClass}`)[0]) : (m.slideEl = t.slides[t.activeIndex]);
          let e = m.slideEl.querySelector(`.${r.containerClass}`);
          e && (e = e.querySelectorAll("picture, img, svg, canvas, .swiper-zoom-target")[0]), (m.imageEl = e), (m.imageWrapEl = e ? x(m.imageEl, `.${r.containerClass}`)[0] : void 0);
        }
        m.imageEl &&
        m.imageWrapEl &&
        (t.params.cssMode && ((t.wrapperEl.style.overflow = ""), (t.wrapperEl.style.touchAction = "")),
          (e.scale = 1),
          (c = 1),
          (m.imageWrapEl.style.transitionDuration = "300ms"),
          (m.imageWrapEl.style.transform = "translate3d(0,0,0)"),
          (m.imageEl.style.transitionDuration = "300ms"),
          (m.imageEl.style.transform = "translate3d(0,0,0) scale(1)"),
          m.slideEl.classList.remove(`${r.zoomedSlideClass}`),
          (m.slideEl = void 0),
          (m.originX = 0),
          (m.originY = 0));
      }
      function A(e) {
        const r = t.zoom;
        r.scale && 1 !== r.scale ? k() : O(e);
      }
      function z() {
        return { passiveListener: !!t.params.passiveListeners && { passive: !0, capture: !1 }, activeListenerWithCapture: !t.params.passiveListeners || { passive: !1, capture: !0 } };
      }
      function $() {
        const e = t.zoom;
        if (e.enabled) return;
        e.enabled = !0;
        const { passiveListener: r, activeListenerWithCapture: a } = z();
        t.wrapperEl.addEventListener("pointerdown", T, r),
          t.wrapperEl.addEventListener("pointermove", M, a),
          ["pointerup", "pointercancel", "pointerout"].forEach((e) => {
            t.wrapperEl.addEventListener(e, C, r);
          }),
          t.wrapperEl.addEventListener("pointermove", P, a);
      }
      function D() {
        const e = t.zoom;
        if (!e.enabled) return;
        e.enabled = !1;
        const { passiveListener: r, activeListenerWithCapture: a } = z();
        t.wrapperEl.removeEventListener("pointerdown", T, r),
          t.wrapperEl.removeEventListener("pointermove", M, a),
          ["pointerup", "pointercancel", "pointerout"].forEach((e) => {
            t.wrapperEl.removeEventListener(e, C, r);
          }),
          t.wrapperEl.removeEventListener("pointermove", P, a);
      }
      Object.defineProperty(t.zoom, "scale", {
        get() {
          return w;
        },
        set(e) {
          if (w !== e) {
            const t = m.imageEl,
              r = m.slideEl;
            s("zoomChange", e, t, r);
          }
          w = e;
        },
      }),
        a("init", () => {
          t.params.zoom.enabled && $();
        }),
        a("destroy", () => {
          D();
        }),
        a("touchStart", (e, r) => {
          t.zoom.enabled &&
          (function (e) {
            const r = t.device;
            if (!m.imageEl) return;
            if (f.isTouched) return;
            r.android && e.cancelable && e.preventDefault(), (f.isTouched = !0);
            const a = p.length > 0 ? p[0] : e;
            (f.touchesStart.x = a.pageX), (f.touchesStart.y = a.pageY);
          })(r);
        }),
        a("touchEnd", (e, r) => {
          t.zoom.enabled &&
          (function () {
            const e = t.zoom;
            if (!m.imageEl) return;
            if (!f.isTouched || !f.isMoved) return (f.isTouched = !1), void (f.isMoved = !1);
            (f.isTouched = !1), (f.isMoved = !1);
            let r = 300,
              a = 300;
            const s = g.x * r,
              i = f.currentX + s,
              n = g.y * a,
              o = f.currentY + n;
            0 !== g.x && (r = Math.abs((i - f.currentX) / g.x)), 0 !== g.y && (a = Math.abs((o - f.currentY) / g.y));
            const l = Math.max(r, a);
            (f.currentX = i), (f.currentY = o);
            const d = f.width * e.scale,
              c = f.height * e.scale;
            (f.minX = Math.min(m.slideWidth / 2 - d / 2, 0)),
              (f.maxX = -f.minX),
              (f.minY = Math.min(m.slideHeight / 2 - c / 2, 0)),
              (f.maxY = -f.minY),
              (f.currentX = Math.max(Math.min(f.currentX, f.maxX), f.minX)),
              (f.currentY = Math.max(Math.min(f.currentY, f.maxY), f.minY)),
              (m.imageWrapEl.style.transitionDuration = `${l}ms`),
              (m.imageWrapEl.style.transform = `translate3d(${f.currentX}px, ${f.currentY}px,0)`);
          })();
        }),
        a("doubleTap", (e, r) => {
          !t.animating && t.params.zoom.enabled && t.zoom.enabled && t.params.zoom.toggle && A(r);
        }),
        a("transitionEnd", () => {
          t.zoom.enabled && t.params.zoom.enabled && I();
        }),
        a("slideChange", () => {
          t.zoom.enabled && t.params.zoom.enabled && t.params.cssMode && I();
        }),
        Object.assign(t.zoom, { enable: $, disable: D, in: O, out: k, toggle: A });
    },
    function (e) {
      let { swiper: t, extendParams: r, on: a } = e;
      function s(e, t) {
        const r = (function () {
          let e, t, r;
          return (a, s) => {
            for (t = -1, e = a.length; e - t > 1; ) (r = (e + t) >> 1), a[r] <= s ? (t = r) : (e = r);
            return e;
          };
        })();
        let a, s;
        return (
          (this.x = e),
            (this.y = t),
            (this.lastIndex = e.length - 1),
            (this.interpolate = function (e) {
              return e ? ((s = r(this.x, e)), (a = s - 1), ((e - this.x[a]) * (this.y[s] - this.y[a])) / (this.x[s] - this.x[a]) + this.y[a]) : 0;
            }),
            this
        );
      }
      function i() {
        t.controller.control && t.controller.spline && ((t.controller.spline = void 0), delete t.controller.spline);
      }
      r({ controller: { control: void 0, inverse: !1, by: "slide" } }),
        (t.controller = { control: void 0 }),
        a("beforeInit", () => {
          if ("undefined" != typeof window && ("string" == typeof t.params.controller.control || t.params.controller.control instanceof HTMLElement)) {
            const e = document.querySelector(t.params.controller.control);
            if (e && e.swiper) t.controller.control = e.swiper;
            else if (e) {
              const r = (a) => {
                (t.controller.control = a.detail[0]), t.update(), e.removeEventListener("init", r);
              };
              e.addEventListener("init", r);
            }
          } else t.controller.control = t.params.controller.control;
        }),
        a("update", () => {
          i();
        }),
        a("resize", () => {
          i();
        }),
        a("observerUpdate", () => {
          i();
        }),
        a("setTranslate", (e, r, a) => {
          t.controller.control && !t.controller.control.destroyed && t.controller.setTranslate(r, a);
        }),
        a("setTransition", (e, r, a) => {
          t.controller.control && !t.controller.control.destroyed && t.controller.setTransition(r, a);
        }),
        Object.assign(t.controller, {
          setTranslate: function (e, r) {
            const a = t.controller.control;
            let i, n;
            const o = t.constructor;
            function l(e) {
              if (e.destroyed) return;
              const r = t.rtlTranslate ? -t.translate : t.translate;
              "slide" === t.params.controller.by &&
              ((function (e) {
                t.controller.spline = t.params.loop ? new s(t.slidesGrid, e.slidesGrid) : new s(t.snapGrid, e.snapGrid);
              })(e),
                (n = -t.controller.spline.interpolate(-r))),
              (n && "container" !== t.params.controller.by) ||
              ((i = (e.maxTranslate() - e.minTranslate()) / (t.maxTranslate() - t.minTranslate())), (!Number.isNaN(i) && Number.isFinite(i)) || (i = 1), (n = (r - t.minTranslate()) * i + e.minTranslate())),
              t.params.controller.inverse && (n = e.maxTranslate() - n),
                e.updateProgress(n),
                e.setTranslate(n, t),
                e.updateActiveIndex(),
                e.updateSlidesClasses();
            }
            if (Array.isArray(a)) for (let e = 0; e < a.length; e += 1) a[e] !== r && a[e] instanceof o && l(a[e]);
            else a instanceof o && r !== a && l(a);
          },
          setTransition: function (e, r) {
            const a = t.constructor,
              s = t.controller.control;
            let i;
            function n(r) {
              r.destroyed ||
              (r.setTransition(e, t),
              0 !== e &&
              (r.transitionStart(),
              r.params.autoHeight &&
              o(() => {
                r.updateAutoHeight();
              }),
                E(r.wrapperEl, () => {
                  s && r.transitionEnd();
                })));
            }
            if (Array.isArray(s)) for (i = 0; i < s.length; i += 1) s[i] !== r && s[i] instanceof a && n(s[i]);
            else s instanceof a && r !== s && n(s);
          },
        });
    },
    function (e) {
      let { swiper: t, extendParams: r, on: s } = e;
      r({
        a11y: {
          enabled: !0,
          notificationClass: "swiper-notification",
          prevSlideMessage: "Previous slide",
          nextSlideMessage: "Next slide",
          firstSlideMessage: "This is the first slide",
          lastSlideMessage: "This is the last slide",
          paginationBulletMessage: "Go to slide {{index}}",
          slideLabelMessage: "{{index}} / {{slidesLength}}",
          containerMessage: null,
          containerRoleDescriptionMessage: null,
          itemRoleDescriptionMessage: null,
          slideRole: "group",
          id: null,
        },
      }),
        (t.a11y = { clicked: !1 });
      let i,
        n,
        o = null,
        l = new Date().getTime();
      function d(e) {
        const t = o;
        0 !== t.length && ((t.innerHTML = ""), (t.innerHTML = e));
      }
      function c(e) {
        (e = T(e)).forEach((e) => {
          e.setAttribute("tabIndex", "0");
        });
      }
      function u(e) {
        (e = T(e)).forEach((e) => {
          e.setAttribute("tabIndex", "-1");
        });
      }
      function p(e, t) {
        (e = T(e)).forEach((e) => {
          e.setAttribute("role", t);
        });
      }
      function m(e, t) {
        (e = T(e)).forEach((e) => {
          e.setAttribute("aria-roledescription", t);
        });
      }
      function f(e, t) {
        (e = T(e)).forEach((e) => {
          e.setAttribute("aria-label", t);
        });
      }
      function h(e) {
        (e = T(e)).forEach((e) => {
          e.setAttribute("aria-disabled", !0);
        });
      }
      function g(e) {
        (e = T(e)).forEach((e) => {
          e.setAttribute("aria-disabled", !1);
        });
      }
      function y(e) {
        if (13 !== e.keyCode && 32 !== e.keyCode) return;
        const r = t.params.a11y,
          a = e.target;
        if (!t.pagination || !t.pagination.el || (a !== t.pagination.el && !t.pagination.el.contains(e.target)) || e.target.matches(re(t.params.pagination.bulletClass))) {
          if (t.navigation && t.navigation.prevEl && t.navigation.nextEl) {
            const e = T(t.navigation.prevEl);
            T(t.navigation.nextEl).includes(a) && ((t.isEnd && !t.params.loop) || t.slideNext(), t.isEnd ? d(r.lastSlideMessage) : d(r.nextSlideMessage)),
            e.includes(a) && ((t.isBeginning && !t.params.loop) || t.slidePrev(), t.isBeginning ? d(r.firstSlideMessage) : d(r.prevSlideMessage));
          }
          t.pagination && a.matches(re(t.params.pagination.bulletClass)) && a.click();
        }
      }
      function w() {
        return t.pagination && t.pagination.bullets && t.pagination.bullets.length;
      }
      function x() {
        return w() && t.params.pagination.clickable;
      }
      const E = (e, t, r) => {
          c(e),
          "BUTTON" !== e.tagName && (p(e, "button"), e.addEventListener("keydown", y)),
            f(e, r),
            (function (e, t) {
              (e = T(e)).forEach((e) => {
                e.setAttribute("aria-controls", t);
              });
            })(e, t);
        },
        S = (e) => {
          n && n !== e.target && !n.contains(e.target) && (i = !0), (t.a11y.clicked = !0);
        },
        M = () => {
          (i = !1),
            requestAnimationFrame(() => {
              requestAnimationFrame(() => {
                t.destroyed || (t.a11y.clicked = !1);
              });
            });
        },
        C = (e) => {
          l = new Date().getTime();
        },
        L = (e) => {
          if (t.a11y.clicked) return;
          if (new Date().getTime() - l < 100) return;
          const r = e.target.closest(`.${t.params.slideClass}, swiper-slide`);
          if (!r || !t.slides.includes(r)) return;
          n = r;
          const a = t.slides.indexOf(r) === t.activeIndex,
            s = t.params.watchSlidesProgress && t.visibleSlides && t.visibleSlides.includes(r);
          a ||
          s ||
          (e.sourceCapabilities && e.sourceCapabilities.firesTouchEvents) ||
          (t.isHorizontal() ? (t.el.scrollLeft = 0) : (t.el.scrollTop = 0),
            requestAnimationFrame(() => {
              i || (t.slideTo(t.slides.indexOf(r), 0), (i = !1));
            }));
        },
        P = () => {
          const e = t.params.a11y;
          e.itemRoleDescriptionMessage && m(t.slides, e.itemRoleDescriptionMessage), e.slideRole && p(t.slides, e.slideRole);
          const r = t.slides.length;
          e.slideLabelMessage &&
          t.slides.forEach((a, s) => {
            const i = t.params.loop ? parseInt(a.getAttribute("data-swiper-slide-index"), 10) : s;
            f(a, e.slideLabelMessage.replace(/\{\{index\}\}/, i + 1).replace(/\{\{slidesLength\}\}/, r));
          });
        };
      s("beforeInit", () => {
        (o = v("span", t.params.a11y.notificationClass)), o.setAttribute("aria-live", "assertive"), o.setAttribute("aria-atomic", "true");
      }),
        s("afterInit", () => {
          t.params.a11y.enabled &&
          (() => {
            const e = t.params.a11y;
            t.el.append(o);
            const r = t.el;
            e.containerRoleDescriptionMessage && m(r, e.containerRoleDescriptionMessage), e.containerMessage && f(r, e.containerMessage);
            const s = t.wrapperEl,
              i = e.id || s.getAttribute("id") || `swiper-wrapper-${((n = 16), void 0 === n && (n = 16), "x".repeat(n).replace(/x/g, () => Math.round(16 * Math.random()).toString(16)))}`;
            var n;
            const l = t.params.autoplay && t.params.autoplay.enabled ? "off" : "polite";
            var d;
            (d = i),
              T(s).forEach((e) => {
                e.setAttribute("id", d);
              }),
              (function (e, t) {
                (e = T(e)).forEach((e) => {
                  e.setAttribute("aria-live", t);
                });
              })(s, l),
              P();
            let { nextEl: c, prevEl: u } = t.navigation ? t.navigation : {};
            (c = T(c)),
              (u = T(u)),
            c && c.forEach((t) => E(t, i, e.nextSlideMessage)),
            u && u.forEach((t) => E(t, i, e.prevSlideMessage)),
            x() &&
            T(t.pagination.el).forEach((e) => {
              e.addEventListener("keydown", y);
            }),
              a().addEventListener("visibilitychange", C),
              t.el.addEventListener("focus", L, !0),
              t.el.addEventListener("focus", L, !0),
              t.el.addEventListener("pointerdown", S, !0),
              t.el.addEventListener("pointerup", M, !0);
          })();
        }),
        s("slidesLengthChange snapGridLengthChange slidesGridLengthChange", () => {
          t.params.a11y.enabled && P();
        }),
        s("fromEdge toEdge afterInit lock unlock", () => {
          t.params.a11y.enabled &&
          (function () {
            if (t.params.loop || t.params.rewind || !t.navigation) return;
            const { nextEl: e, prevEl: r } = t.navigation;
            r && (t.isBeginning ? (h(r), u(r)) : (g(r), c(r))), e && (t.isEnd ? (h(e), u(e)) : (g(e), c(e)));
          })();
        }),
        s("paginationUpdate", () => {
          t.params.a11y.enabled &&
          (function () {
            const e = t.params.a11y;
            w() &&
            t.pagination.bullets.forEach((r) => {
              t.params.pagination.clickable && (c(r), t.params.pagination.renderBullet || (p(r, "button"), f(r, e.paginationBulletMessage.replace(/\{\{index\}\}/, b(r) + 1)))),
                r.matches(re(t.params.pagination.bulletActiveClass)) ? r.setAttribute("aria-current", "true") : r.removeAttribute("aria-current");
            });
          })();
        }),
        s("destroy", () => {
          t.params.a11y.enabled &&
          (function () {
            o && o.remove();
            let { nextEl: e, prevEl: r } = t.navigation ? t.navigation : {};
            (e = T(e)),
              (r = T(r)),
            e && e.forEach((e) => e.removeEventListener("keydown", y)),
            r && r.forEach((e) => e.removeEventListener("keydown", y)),
            x() &&
            T(t.pagination.el).forEach((e) => {
              e.removeEventListener("keydown", y);
            }),
              a().removeEventListener("visibilitychange", C),
              t.el.removeEventListener("focus", L, !0),
              t.el.removeEventListener("pointerdown", S, !0),
              t.el.removeEventListener("pointerup", M, !0);
          })();
        });
    },
    function (e) {
      let { swiper: t, extendParams: r, on: a } = e;
      r({ history: { enabled: !1, root: "", replaceState: !1, key: "slides", keepQuery: !1 } });
      let s = !1,
        n = {};
      const o = (e) =>
          e
            .toString()
            .replace(/\s+/g, "-")
            .replace(/[^\w-]+/g, "")
            .replace(/--+/g, "-")
            .replace(/^-+/, "")
            .replace(/-+$/, ""),
        l = (e) => {
          const t = i();
          let r;
          r = e ? new URL(e) : t.location;
          const a = r.pathname
              .slice(1)
              .split("/")
              .filter((e) => "" !== e),
            s = a.length;
          return { key: a[s - 2], value: a[s - 1] };
        },
        d = (e, r) => {
          const a = i();
          if (!s || !t.params.history.enabled) return;
          let n;
          n = t.params.url ? new URL(t.params.url) : a.location;
          const l = t.virtual && t.params.virtual.enabled ? t.slidesEl.querySelector(`[data-swiper-slide-index="${r}"]`) : t.slides[r];
          let d = o(l.getAttribute("data-history"));
          if (t.params.history.root.length > 0) {
            let r = t.params.history.root;
            "/" === r[r.length - 1] && (r = r.slice(0, r.length - 1)), (d = `${r}/${e ? `${e}/` : ""}${d}`);
          } else n.pathname.includes(e) || (d = `${e ? `${e}/` : ""}${d}`);
          t.params.history.keepQuery && (d += n.search);
          const c = a.history.state;
          (c && c.value === d) || (t.params.history.replaceState ? a.history.replaceState({ value: d }, null, d) : a.history.pushState({ value: d }, null, d));
        },
        c = (e, r, a) => {
          if (r)
            for (let s = 0, i = t.slides.length; s < i; s += 1) {
              const i = t.slides[s];
              if (o(i.getAttribute("data-history")) === r) {
                const r = t.getSlideIndex(i);
                t.slideTo(r, e, a);
              }
            }
          else t.slideTo(0, e, a);
        },
        u = () => {
          (n = l(t.params.url)), c(t.params.speed, n.value, !1);
        };
      a("init", () => {
        t.params.history.enabled &&
        (() => {
          const e = i();
          if (t.params.history) {
            if (!e.history || !e.history.pushState) return (t.params.history.enabled = !1), void (t.params.hashNavigation.enabled = !0);
            (s = !0),
              (n = l(t.params.url)),
              n.key || n.value ? (c(0, n.value, t.params.runCallbacksOnInit), t.params.history.replaceState || e.addEventListener("popstate", u)) : t.params.history.replaceState || e.addEventListener("popstate", u);
          }
        })();
      }),
        a("destroy", () => {
          t.params.history.enabled &&
          (() => {
            const e = i();
            t.params.history.replaceState || e.removeEventListener("popstate", u);
          })();
        }),
        a("transitionEnd _freeModeNoMomentumRelease", () => {
          s && d(t.params.history.key, t.activeIndex);
        }),
        a("slideChange", () => {
          s && t.params.cssMode && d(t.params.history.key, t.activeIndex);
        });
    },
    function (e) {
      let { swiper: t, extendParams: r, emit: s, on: n } = e,
        o = !1;
      const l = a(),
        d = i();
      r({
        hashNavigation: {
          enabled: !1,
          replaceState: !1,
          watchState: !1,
          getSlideIndex(e, r) {
            if (t.virtual && t.params.virtual.enabled) {
              const e = t.slides.filter((e) => e.getAttribute("data-hash") === r)[0];
              return e ? parseInt(e.getAttribute("data-swiper-slide-index"), 10) : 0;
            }
            return t.getSlideIndex(h(t.slidesEl, `.${t.params.slideClass}[data-hash="${r}"], swiper-slide[data-hash="${r}"]`)[0]);
          },
        },
      });
      const c = () => {
          s("hashChange");
          const e = l.location.hash.replace("#", ""),
            r = t.virtual && t.params.virtual.enabled ? t.slidesEl.querySelector(`[data-swiper-slide-index="${t.activeIndex}"]`) : t.slides[t.activeIndex];
          if (e !== (r ? r.getAttribute("data-hash") : "")) {
            const r = t.params.hashNavigation.getSlideIndex(t, e);
            if (void 0 === r || Number.isNaN(r)) return;
            t.slideTo(r);
          }
        },
        u = () => {
          if (!o || !t.params.hashNavigation.enabled) return;
          const e = t.virtual && t.params.virtual.enabled ? t.slidesEl.querySelector(`[data-swiper-slide-index="${t.activeIndex}"]`) : t.slides[t.activeIndex],
            r = e ? e.getAttribute("data-hash") || e.getAttribute("data-history") : "";
          t.params.hashNavigation.replaceState && d.history && d.history.replaceState ? (d.history.replaceState(null, null, `#${r}` || ""), s("hashSet")) : ((l.location.hash = r || ""), s("hashSet"));
        };
      n("init", () => {
        t.params.hashNavigation.enabled &&
        (() => {
          if (!t.params.hashNavigation.enabled || (t.params.history && t.params.history.enabled)) return;
          o = !0;
          const e = l.location.hash.replace("#", "");
          if (e) {
            const r = 0,
              a = t.params.hashNavigation.getSlideIndex(t, e);
            t.slideTo(a || 0, r, t.params.runCallbacksOnInit, !0);
          }
          t.params.hashNavigation.watchState && d.addEventListener("hashchange", c);
        })();
      }),
        n("destroy", () => {
          t.params.hashNavigation.enabled && t.params.hashNavigation.watchState && d.removeEventListener("hashchange", c);
        }),
        n("transitionEnd _freeModeNoMomentumRelease", () => {
          o && u();
        }),
        n("slideChange", () => {
          o && t.params.cssMode && u();
        });
    },
    function (e) {
      let t,
        r,
        { swiper: s, extendParams: i, on: n, emit: o, params: l } = e;
      (s.autoplay = { running: !1, paused: !1, timeLeft: 0 }), i({ autoplay: { enabled: !1, delay: 3e3, waitForTransition: !0, disableOnInteraction: !1, stopOnLastSlide: !1, reverseDirection: !1, pauseOnMouseEnter: !1 } });
      let d,
        c,
        u,
        p,
        m,
        f,
        h,
        g,
        v = l && l.autoplay ? l.autoplay.delay : 3e3,
        y = l && l.autoplay ? l.autoplay.delay : 3e3,
        w = new Date().getTime();
      function b(e) {
        s && !s.destroyed && s.wrapperEl && e.target === s.wrapperEl && (s.wrapperEl.removeEventListener("transitionend", b), g || C());
      }
      const x = () => {
          if (s.destroyed || !s.autoplay.running) return;
          s.autoplay.paused ? (c = !0) : c && ((y = d), (c = !1));
          const e = s.autoplay.paused ? d : w + y - new Date().getTime();
          (s.autoplay.timeLeft = e),
            o("autoplayTimeLeft", e, e / v),
            (r = requestAnimationFrame(() => {
              x();
            }));
        },
        E = (e) => {
          if (s.destroyed || !s.autoplay.running) return;
          cancelAnimationFrame(r), x();
          let a = void 0 === e ? s.params.autoplay.delay : e;
          (v = s.params.autoplay.delay), (y = s.params.autoplay.delay);
          const i = (() => {
            let e;
            if (((e = s.virtual && s.params.virtual.enabled ? s.slides.filter((e) => e.classList.contains("swiper-slide-active"))[0] : s.slides[s.activeIndex]), e)) return parseInt(e.getAttribute("data-swiper-autoplay"), 10);
          })();
          !Number.isNaN(i) && i > 0 && void 0 === e && ((a = i), (v = i), (y = i)), (d = a);
          const n = s.params.speed,
            l = () => {
              s &&
              !s.destroyed &&
              (s.params.autoplay.reverseDirection
                ? !s.isBeginning || s.params.loop || s.params.rewind
                  ? (s.slidePrev(n, !0, !0), o("autoplay"))
                  : s.params.autoplay.stopOnLastSlide || (s.slideTo(s.slides.length - 1, n, !0, !0), o("autoplay"))
                : !s.isEnd || s.params.loop || s.params.rewind
                  ? (s.slideNext(n, !0, !0), o("autoplay"))
                  : s.params.autoplay.stopOnLastSlide || (s.slideTo(0, n, !0, !0), o("autoplay")),
              s.params.cssMode &&
              ((w = new Date().getTime()),
                requestAnimationFrame(() => {
                  E();
                })));
            };
          return (
            a > 0
              ? (clearTimeout(t),
                (t = setTimeout(() => {
                  l();
                }, a)))
              : requestAnimationFrame(() => {
                l();
              }),
              a
          );
        },
        S = () => {
          (w = new Date().getTime()), (s.autoplay.running = !0), E(), o("autoplayStart");
        },
        T = () => {
          (s.autoplay.running = !1), clearTimeout(t), cancelAnimationFrame(r), o("autoplayStop");
        },
        M = (e, r) => {
          if (s.destroyed || !s.autoplay.running) return;
          clearTimeout(t), e || (h = !0);
          const a = () => {
            o("autoplayPause"), s.params.autoplay.waitForTransition ? s.wrapperEl.addEventListener("transitionend", b) : C();
          };
          if (((s.autoplay.paused = !0), r)) return f && (d = s.params.autoplay.delay), (f = !1), void a();
          const i = d || s.params.autoplay.delay;
          (d = i - (new Date().getTime() - w)), (s.isEnd && d < 0 && !s.params.loop) || (d < 0 && (d = 0), a());
        },
        C = () => {
          (s.isEnd && d < 0 && !s.params.loop) || s.destroyed || !s.autoplay.running || ((w = new Date().getTime()), h ? ((h = !1), E(d)) : E(), (s.autoplay.paused = !1), o("autoplayResume"));
        },
        L = () => {
          if (s.destroyed || !s.autoplay.running) return;
          const e = a();
          "hidden" === e.visibilityState && ((h = !0), M(!0)), "visible" === e.visibilityState && C();
        },
        P = (e) => {
          "mouse" === e.pointerType && ((h = !0), (g = !0), s.animating || s.autoplay.paused || M(!0));
        },
        I = (e) => {
          "mouse" === e.pointerType && ((g = !1), s.autoplay.paused && C());
        };
      n("init", () => {
        s.params.autoplay.enabled && (s.params.autoplay.pauseOnMouseEnter && (s.el.addEventListener("pointerenter", P), s.el.addEventListener("pointerleave", I)), a().addEventListener("visibilitychange", L), S());
      }),
        n("destroy", () => {
          s.el.removeEventListener("pointerenter", P), s.el.removeEventListener("pointerleave", I), a().removeEventListener("visibilitychange", L), s.autoplay.running && T();
        }),
        n("_freeModeStaticRelease", () => {
          (p || h) && C();
        }),
        n("_freeModeNoMomentumRelease", () => {
          s.params.autoplay.disableOnInteraction ? T() : M(!0, !0);
        }),
        n("beforeTransitionStart", (e, t, r) => {
          !s.destroyed && s.autoplay.running && (r || !s.params.autoplay.disableOnInteraction ? M(!0, !0) : T());
        }),
        n("sliderFirstMove", () => {
          !s.destroyed &&
          s.autoplay.running &&
          (s.params.autoplay.disableOnInteraction
            ? T()
            : ((u = !0),
              (p = !1),
              (h = !1),
              (m = setTimeout(() => {
                (h = !0), (p = !0), M(!0);
              }, 200))));
        }),
        n("touchEnd", () => {
          if (!s.destroyed && s.autoplay.running && u) {
            if ((clearTimeout(m), clearTimeout(t), s.params.autoplay.disableOnInteraction)) return (p = !1), void (u = !1);
            p && s.params.cssMode && C(), (p = !1), (u = !1);
          }
        }),
        n("slideChange", () => {
          !s.destroyed && s.autoplay.running && (f = !0);
        }),
        Object.assign(s.autoplay, { start: S, stop: T, pause: M, resume: C });
    },
    function (e) {
      let { swiper: t, extendParams: r, on: s } = e;
      r({ thumbs: { swiper: null, multipleActiveThumbs: !0, autoScrollOffset: 0, slideThumbActiveClass: "swiper-slide-thumb-active", thumbsContainerClass: "swiper-thumbs" } });
      let i = !1,
        n = !1;
      function o() {
        const e = t.thumbs.swiper;
        if (!e || e.destroyed) return;
        const r = e.clickedIndex,
          a = e.clickedSlide;
        if (a && a.classList.contains(t.params.thumbs.slideThumbActiveClass)) return;
        if (null == r) return;
        let s;
        (s = e.params.loop ? parseInt(e.clickedSlide.getAttribute("data-swiper-slide-index"), 10) : r), t.params.loop ? t.slideToLoop(s) : t.slideTo(s);
      }
      function l() {
        const { thumbs: e } = t.params;
        if (i) return !1;
        i = !0;
        const r = t.constructor;
        if (e.swiper instanceof r)
          (t.thumbs.swiper = e.swiper),
            Object.assign(t.thumbs.swiper.originalParams, { watchSlidesProgress: !0, slideToClickedSlide: !1 }),
            Object.assign(t.thumbs.swiper.params, { watchSlidesProgress: !0, slideToClickedSlide: !1 }),
            t.thumbs.swiper.update();
        else if (c(e.swiper)) {
          const a = Object.assign({}, e.swiper);
          Object.assign(a, { watchSlidesProgress: !0, slideToClickedSlide: !1 }), (t.thumbs.swiper = new r(a)), (n = !0);
        }
        return t.thumbs.swiper.el.classList.add(t.params.thumbs.thumbsContainerClass), t.thumbs.swiper.on("tap", o), !0;
      }
      function d(e) {
        const r = t.thumbs.swiper;
        if (!r || r.destroyed) return;
        const a = "auto" === r.params.slidesPerView ? r.slidesPerViewDynamic() : r.params.slidesPerView;
        let s = 1;
        const i = t.params.thumbs.slideThumbActiveClass;
        if (
          (t.params.slidesPerView > 1 && !t.params.centeredSlides && (s = t.params.slidesPerView),
          t.params.thumbs.multipleActiveThumbs || (s = 1),
            (s = Math.floor(s)),
            r.slides.forEach((e) => e.classList.remove(i)),
          r.params.loop || (r.params.virtual && r.params.virtual.enabled))
        )
          for (let e = 0; e < s; e += 1)
            h(r.slidesEl, `[data-swiper-slide-index="${t.realIndex + e}"]`).forEach((e) => {
              e.classList.add(i);
            });
        else for (let e = 0; e < s; e += 1) r.slides[t.realIndex + e] && r.slides[t.realIndex + e].classList.add(i);
        const n = t.params.thumbs.autoScrollOffset,
          o = n && !r.params.loop;
        if (t.realIndex !== r.realIndex || o) {
          const s = r.activeIndex;
          let i, l;
          if (r.params.loop) {
            const e = r.slides.filter((e) => e.getAttribute("data-swiper-slide-index") === `${t.realIndex}`)[0];
            (i = r.slides.indexOf(e)), (l = t.activeIndex > t.previousIndex ? "next" : "prev");
          } else (i = t.realIndex), (l = i > t.previousIndex ? "next" : "prev");
          o && (i += "next" === l ? n : -1 * n),
          r.visibleSlidesIndexes &&
          r.visibleSlidesIndexes.indexOf(i) < 0 &&
          (r.params.centeredSlides ? (i = i > s ? i - Math.floor(a / 2) + 1 : i + Math.floor(a / 2) - 1) : i > s && r.params.slidesPerGroup, r.slideTo(i, e ? 0 : void 0));
        }
      }
      (t.thumbs = { swiper: null }),
        s("beforeInit", () => {
          const { thumbs: e } = t.params;
          if (e && e.swiper)
            if ("string" == typeof e.swiper || e.swiper instanceof HTMLElement) {
              const r = a(),
                s = () => {
                  const a = "string" == typeof e.swiper ? r.querySelector(e.swiper) : e.swiper;
                  if (a && a.swiper) (e.swiper = a.swiper), l(), d(!0);
                  else if (a) {
                    const r = (s) => {
                      (e.swiper = s.detail[0]), a.removeEventListener("init", r), l(), d(!0), e.swiper.update(), t.update();
                    };
                    a.addEventListener("init", r);
                  }
                  return a;
                },
                i = () => {
                  t.destroyed || s() || requestAnimationFrame(i);
                };
              requestAnimationFrame(i);
            } else l(), d(!0);
        }),
        s("slideChange update resize observerUpdate", () => {
          d();
        }),
        s("setTransition", (e, r) => {
          const a = t.thumbs.swiper;
          a && !a.destroyed && a.setTransition(r);
        }),
        s("beforeDestroy", () => {
          const e = t.thumbs.swiper;
          e && !e.destroyed && n && e.destroy();
        }),
        Object.assign(t.thumbs, { init: l, update: d });
    },
    function (e) {
      let { swiper: t, extendParams: r, emit: a, once: s } = e;
      r({ freeMode: { enabled: !1, momentum: !0, momentumRatio: 1, momentumBounce: !0, momentumBounceRatio: 1, momentumVelocityRatio: 1, sticky: !1, minimumVelocity: 0.02 } }),
        Object.assign(t, {
          freeMode: {
            onTouchStart: function () {
              if (t.params.cssMode) return;
              const e = t.getTranslate();
              t.setTranslate(e), t.setTransition(0), (t.touchEventsData.velocities.length = 0), t.freeMode.onTouchEnd({ currentPos: t.rtl ? t.translate : -t.translate });
            },
            onTouchMove: function () {
              if (t.params.cssMode) return;
              const { touchEventsData: e, touches: r } = t;
              0 === e.velocities.length && e.velocities.push({ position: r[t.isHorizontal() ? "startX" : "startY"], time: e.touchStartTime }),
                e.velocities.push({ position: r[t.isHorizontal() ? "currentX" : "currentY"], time: l() });
            },
            onTouchEnd: function (e) {
              let { currentPos: r } = e;
              if (t.params.cssMode) return;
              const { params: i, wrapperEl: n, rtlTranslate: o, snapGrid: d, touchEventsData: c } = t,
                u = l() - c.touchStartTime;
              if (r < -t.minTranslate()) t.slideTo(t.activeIndex);
              else if (r > -t.maxTranslate()) t.slides.length < d.length ? t.slideTo(d.length - 1) : t.slideTo(t.slides.length - 1);
              else {
                if (i.freeMode.momentum) {
                  if (c.velocities.length > 1) {
                    const e = c.velocities.pop(),
                      r = c.velocities.pop(),
                      a = e.position - r.position,
                      s = e.time - r.time;
                    (t.velocity = a / s), (t.velocity /= 2), Math.abs(t.velocity) < i.freeMode.minimumVelocity && (t.velocity = 0), (s > 150 || l() - e.time > 300) && (t.velocity = 0);
                  } else t.velocity = 0;
                  (t.velocity *= i.freeMode.momentumVelocityRatio), (c.velocities.length = 0);
                  let e = 1e3 * i.freeMode.momentumRatio;
                  const r = t.velocity * e;
                  let u = t.translate + r;
                  o && (u = -u);
                  let p,
                    m = !1;
                  const f = 20 * Math.abs(t.velocity) * i.freeMode.momentumBounceRatio;
                  let h;
                  if (u < t.maxTranslate())
                    i.freeMode.momentumBounce ? (u + t.maxTranslate() < -f && (u = t.maxTranslate() - f), (p = t.maxTranslate()), (m = !0), (c.allowMomentumBounce = !0)) : (u = t.maxTranslate()),
                    i.loop && i.centeredSlides && (h = !0);
                  else if (u > t.minTranslate())
                    i.freeMode.momentumBounce ? (u - t.minTranslate() > f && (u = t.minTranslate() + f), (p = t.minTranslate()), (m = !0), (c.allowMomentumBounce = !0)) : (u = t.minTranslate()),
                    i.loop && i.centeredSlides && (h = !0);
                  else if (i.freeMode.sticky) {
                    let e;
                    for (let t = 0; t < d.length; t += 1)
                      if (d[t] > -u) {
                        e = t;
                        break;
                      }
                    (u = Math.abs(d[e] - u) < Math.abs(d[e - 1] - u) || "next" === t.swipeDirection ? d[e] : d[e - 1]), (u = -u);
                  }
                  if (
                    (h &&
                    s("transitionEnd", () => {
                      t.loopFix();
                    }),
                    0 !== t.velocity)
                  ) {
                    if (((e = o ? Math.abs((-u - t.translate) / t.velocity) : Math.abs((u - t.translate) / t.velocity)), i.freeMode.sticky)) {
                      const r = Math.abs((o ? -u : u) - t.translate),
                        a = t.slidesSizesGrid[t.activeIndex];
                      e = r < a ? i.speed : r < 2 * a ? 1.5 * i.speed : 2.5 * i.speed;
                    }
                  } else if (i.freeMode.sticky) return void t.slideToClosest();
                  i.freeMode.momentumBounce && m
                    ? (t.updateProgress(p),
                      t.setTransition(e),
                      t.setTranslate(u),
                      t.transitionStart(!0, t.swipeDirection),
                      (t.animating = !0),
                      E(n, () => {
                        t &&
                        !t.destroyed &&
                        c.allowMomentumBounce &&
                        (a("momentumBounce"),
                          t.setTransition(i.speed),
                          setTimeout(() => {
                            t.setTranslate(p),
                              E(n, () => {
                                t && !t.destroyed && t.transitionEnd();
                              });
                          }, 0));
                      }))
                    : t.velocity
                      ? (a("_freeModeNoMomentumRelease"),
                        t.updateProgress(u),
                        t.setTransition(e),
                        t.setTranslate(u),
                        t.transitionStart(!0, t.swipeDirection),
                      t.animating ||
                      ((t.animating = !0),
                        E(n, () => {
                          t && !t.destroyed && t.transitionEnd();
                        })))
                      : t.updateProgress(u),
                    t.updateActiveIndex(),
                    t.updateSlidesClasses();
                } else {
                  if (i.freeMode.sticky) return void t.slideToClosest();
                  i.freeMode && a("_freeModeNoMomentumRelease");
                }
                (!i.freeMode.momentum || u >= i.longSwipesMs) && (a("_freeModeStaticRelease"), t.updateProgress(), t.updateActiveIndex(), t.updateSlidesClasses());
              }
            },
          },
        });
    },
    function (e) {
      let t,
        r,
        a,
        s,
        { swiper: i, extendParams: n, on: o } = e;
      n({ grid: { rows: 1, fill: "column" } });
      const l = () => {
        let e = i.params.spaceBetween;
        return "string" == typeof e && e.indexOf("%") >= 0 ? (e = (parseFloat(e.replace("%", "")) / 100) * i.size) : "string" == typeof e && (e = parseFloat(e)), e;
      };
      o("init", () => {
        s = i.params.grid && i.params.grid.rows > 1;
      }),
        o("update", () => {
          const { params: e, el: t } = i,
            r = e.grid && e.grid.rows > 1;
          s && !r
            ? (t.classList.remove(`${e.containerModifierClass}grid`, `${e.containerModifierClass}grid-column`), (a = 1), i.emitContainerClasses())
            : !s && r && (t.classList.add(`${e.containerModifierClass}grid`), "column" === e.grid.fill && t.classList.add(`${e.containerModifierClass}grid-column`), i.emitContainerClasses()),
            (s = r);
        }),
        (i.grid = {
          initSlides: (e) => {
            const { slidesPerView: s } = i.params,
              { rows: n, fill: o } = i.params.grid,
              l = i.virtual && i.params.virtual.enabled ? i.virtual.slides.length : e.length;
            (a = Math.floor(l / n)), (t = Math.floor(l / n) === l / n ? l : Math.ceil(l / n) * n), "auto" !== s && "row" === o && (t = Math.max(t, s * n)), (r = t / n);
          },
          unsetSlides: () => {
            i.slides &&
            i.slides.forEach((e) => {
              e.swiperSlideGridSet && ((e.style.height = ""), (e.style[i.getDirectionLabel("margin-top")] = ""));
            });
          },
          updateSlide: (e, s, n) => {
            const { slidesPerGroup: o } = i.params,
              d = l(),
              { rows: c, fill: u } = i.params.grid,
              p = i.virtual && i.params.virtual.enabled ? i.virtual.slides.length : n.length;
            let m, f, h;
            if ("row" === u && o > 1) {
              const r = Math.floor(e / (o * c)),
                a = e - c * o * r,
                i = 0 === r ? o : Math.min(Math.ceil((p - r * c * o) / c), o);
              (h = Math.floor(a / i)), (f = a - h * i + r * o), (m = f + (h * t) / c), (s.style.order = m);
            } else "column" === u ? ((f = Math.floor(e / c)), (h = e - f * c), (f > a || (f === a && h === c - 1)) && ((h += 1), h >= c && ((h = 0), (f += 1)))) : ((h = Math.floor(e / r)), (f = e - h * r));
            (s.row = h), (s.column = f), (s.style.height = `calc((100% - ${(c - 1) * d}px) / ${c})`), (s.style[i.getDirectionLabel("margin-top")] = 0 !== h ? d && `${d}px` : ""), (s.swiperSlideGridSet = !0);
          },
          updateWrapperSize: (e, r) => {
            const { centeredSlides: a, roundLengths: s } = i.params,
              n = l(),
              { rows: o } = i.params.grid;
            if (((i.virtualSize = (e + n) * t), (i.virtualSize = Math.ceil(i.virtualSize / o) - n), i.params.cssMode || (i.wrapperEl.style[i.getDirectionLabel("width")] = `${i.virtualSize + n}px`), a)) {
              const e = [];
              for (let t = 0; t < r.length; t += 1) {
                let a = r[t];
                s && (a = Math.floor(a)), r[t] < i.virtualSize + r[0] && e.push(a);
              }
              r.splice(0, r.length), r.push(...e);
            }
          },
        });
    },
    function (e) {
      let { swiper: t } = e;
      Object.assign(t, { appendSlide: ae.bind(t), prependSlide: se.bind(t), addSlide: ie.bind(t), removeSlide: ne.bind(t), removeAllSlides: oe.bind(t) });
    },
    function (e) {
      let { swiper: t, extendParams: r, on: a } = e;
      r({ fadeEffect: { crossFade: !1 } }),
        le({
          effect: "fade",
          swiper: t,
          on: a,
          setTranslate: () => {
            const { slides: e } = t;
            t.params.fadeEffect;
            for (let r = 0; r < e.length; r += 1) {
              const e = t.slides[r];
              let a = -e.swiperSlideOffset;
              t.params.virtualTranslate || (a -= t.translate);
              let s = 0;
              t.isHorizontal() || ((s = a), (a = 0));
              const i = t.params.fadeEffect.crossFade ? Math.max(1 - Math.abs(e.progress), 0) : 1 + Math.min(Math.max(e.progress, -1), 0),
                n = de(0, e);
              (n.style.opacity = i), (n.style.transform = `translate3d(${a}px, ${s}px, 0px)`);
            }
          },
          setTransition: (e) => {
            const r = t.slides.map((e) => f(e));
            r.forEach((t) => {
              t.style.transitionDuration = `${e}ms`;
            }),
              ce({ swiper: t, duration: e, transformElements: r, allSlides: !0 });
          },
          overwriteParams: () => ({ slidesPerView: 1, slidesPerGroup: 1, watchSlidesProgress: !0, spaceBetween: 0, virtualTranslate: !t.params.cssMode }),
        });
    },
    function (e) {
      let { swiper: t, extendParams: r, on: a } = e;
      r({ cubeEffect: { slideShadows: !0, shadow: !0, shadowOffset: 20, shadowScale: 0.94 } });
      const s = (e, t, r) => {
        let a = r ? e.querySelector(".swiper-slide-shadow-left") : e.querySelector(".swiper-slide-shadow-top"),
          s = r ? e.querySelector(".swiper-slide-shadow-right") : e.querySelector(".swiper-slide-shadow-bottom");
        a || ((a = v("div", ("swiper-slide-shadow-cube swiper-slide-shadow-" + (r ? "left" : "top")).split(" "))), e.append(a)),
        s || ((s = v("div", ("swiper-slide-shadow-cube swiper-slide-shadow-" + (r ? "right" : "bottom")).split(" "))), e.append(s)),
        a && (a.style.opacity = Math.max(-t, 0)),
        s && (s.style.opacity = Math.max(t, 0));
      };
      le({
        effect: "cube",
        swiper: t,
        on: a,
        setTranslate: () => {
          const { el: e, wrapperEl: r, slides: a, width: i, height: n, rtlTranslate: o, size: l, browser: d } = t,
            c = t.params.cubeEffect,
            u = t.isHorizontal(),
            p = t.virtual && t.params.virtual.enabled;
          let m,
            f = 0;
          c.shadow &&
          (u
            ? ((m = t.wrapperEl.querySelector(".swiper-cube-shadow")), m || ((m = v("div", "swiper-cube-shadow")), t.wrapperEl.append(m)), (m.style.height = `${i}px`))
            : ((m = e.querySelector(".swiper-cube-shadow")), m || ((m = v("div", "swiper-cube-shadow")), e.append(m))));
          for (let e = 0; e < a.length; e += 1) {
            const r = a[e];
            let i = e;
            p && (i = parseInt(r.getAttribute("data-swiper-slide-index"), 10));
            let n = 90 * i,
              d = Math.floor(n / 360);
            o && ((n = -n), (d = Math.floor(-n / 360)));
            const m = Math.max(Math.min(r.progress, 1), -1);
            let h = 0,
              g = 0,
              v = 0;
            i % 4 == 0 ? ((h = 4 * -d * l), (v = 0)) : (i - 1) % 4 == 0 ? ((h = 0), (v = 4 * -d * l)) : (i - 2) % 4 == 0 ? ((h = l + 4 * d * l), (v = l)) : (i - 3) % 4 == 0 && ((h = -l), (v = 3 * l + 4 * l * d)),
            o && (h = -h),
            u || ((g = h), (h = 0));
            const y = `rotateX(${u ? 0 : -n}deg) rotateY(${u ? n : 0}deg) translate3d(${h}px, ${g}px, ${v}px)`;
            m <= 1 && m > -1 && ((f = 90 * i + 90 * m), o && (f = 90 * -i - 90 * m), t.browser && t.browser.need3dFix && (Math.abs(f) / 90) % 2 == 1 && (f += 0.001)), (r.style.transform = y), c.slideShadows && s(r, m, u);
          }
          if (((r.style.transformOrigin = `50% 50% -${l / 2}px`), (r.style["-webkit-transform-origin"] = `50% 50% -${l / 2}px`), c.shadow))
            if (u) m.style.transform = `translate3d(0px, ${i / 2 + c.shadowOffset}px, ${-i / 2}px) rotateX(89.99deg) rotateZ(0deg) scale(${c.shadowScale})`;
            else {
              const e = Math.abs(f) - 90 * Math.floor(Math.abs(f) / 90),
                t = 1.5 - (Math.sin((2 * e * Math.PI) / 360) / 2 + Math.cos((2 * e * Math.PI) / 360) / 2),
                r = c.shadowScale,
                a = c.shadowScale / t,
                s = c.shadowOffset;
              m.style.transform = `scale3d(${r}, 1, ${a}) translate3d(0px, ${n / 2 + s}px, ${-n / 2 / a}px) rotateX(-89.99deg)`;
            }
          const h = (d.isSafari || d.isWebView) && d.needPerspectiveFix ? -l / 2 : 0;
          (r.style.transform = `translate3d(0px,0,${h}px) rotateX(${t.isHorizontal() ? 0 : f}deg) rotateY(${t.isHorizontal() ? -f : 0}deg)`), r.style.setProperty("--swiper-cube-translate-z", `${h}px`);
        },
        setTransition: (e) => {
          const { el: r, slides: a } = t;
          if (
            (a.forEach((t) => {
              (t.style.transitionDuration = `${e}ms`),
                t.querySelectorAll(".swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left").forEach((t) => {
                  t.style.transitionDuration = `${e}ms`;
                });
            }),
            t.params.cubeEffect.shadow && !t.isHorizontal())
          ) {
            const t = r.querySelector(".swiper-cube-shadow");
            t && (t.style.transitionDuration = `${e}ms`);
          }
        },
        recreateShadows: () => {
          const e = t.isHorizontal();
          t.slides.forEach((t) => {
            const r = Math.max(Math.min(t.progress, 1), -1);
            s(t, r, e);
          });
        },
        getEffectParams: () => t.params.cubeEffect,
        perspective: () => !0,
        overwriteParams: () => ({ slidesPerView: 1, slidesPerGroup: 1, watchSlidesProgress: !0, resistanceRatio: 0, spaceBetween: 0, centeredSlides: !1, virtualTranslate: !0 }),
      });
    },
    function (e) {
      let { swiper: t, extendParams: r, on: a } = e;
      r({ flipEffect: { slideShadows: !0, limitRotation: !0 } });
      const s = (e, r) => {
        let a = t.isHorizontal() ? e.querySelector(".swiper-slide-shadow-left") : e.querySelector(".swiper-slide-shadow-top"),
          s = t.isHorizontal() ? e.querySelector(".swiper-slide-shadow-right") : e.querySelector(".swiper-slide-shadow-bottom");
        a || (a = ue("flip", e, t.isHorizontal() ? "left" : "top")), s || (s = ue("flip", e, t.isHorizontal() ? "right" : "bottom")), a && (a.style.opacity = Math.max(-r, 0)), s && (s.style.opacity = Math.max(r, 0));
      };
      le({
        effect: "flip",
        swiper: t,
        on: a,
        setTranslate: () => {
          const { slides: e, rtlTranslate: r } = t,
            a = t.params.flipEffect;
          for (let i = 0; i < e.length; i += 1) {
            const n = e[i];
            let o = n.progress;
            t.params.flipEffect.limitRotation && (o = Math.max(Math.min(n.progress, 1), -1));
            const l = n.swiperSlideOffset;
            let d = -180 * o,
              c = 0,
              u = t.params.cssMode ? -l - t.translate : -l,
              p = 0;
            t.isHorizontal() ? r && (d = -d) : ((p = u), (u = 0), (c = -d), (d = 0)),
            t.browser && t.browser.need3dFix && ((Math.abs(d) / 90) % 2 == 1 && (d += 0.001), (Math.abs(c) / 90) % 2 == 1 && (c += 0.001)),
              (n.style.zIndex = -Math.abs(Math.round(o)) + e.length),
            a.slideShadows && s(n, o);
            const m = `translate3d(${u}px, ${p}px, 0px) rotateX(${c}deg) rotateY(${d}deg)`;
            de(0, n).style.transform = m;
          }
        },
        setTransition: (e) => {
          const r = t.slides.map((e) => f(e));
          r.forEach((t) => {
            (t.style.transitionDuration = `${e}ms`),
              t.querySelectorAll(".swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left").forEach((t) => {
                t.style.transitionDuration = `${e}ms`;
              });
          }),
            ce({ swiper: t, duration: e, transformElements: r });
        },
        recreateShadows: () => {
          t.params.flipEffect,
            t.slides.forEach((e) => {
              let r = e.progress;
              t.params.flipEffect.limitRotation && (r = Math.max(Math.min(e.progress, 1), -1)), s(e, r);
            });
        },
        getEffectParams: () => t.params.flipEffect,
        perspective: () => !0,
        overwriteParams: () => ({ slidesPerView: 1, slidesPerGroup: 1, watchSlidesProgress: !0, spaceBetween: 0, virtualTranslate: !t.params.cssMode }),
      });
    },
    function (e) {
      let { swiper: t, extendParams: r, on: a } = e;
      r({ coverflowEffect: { rotate: 50, stretch: 0, depth: 100, scale: 1, modifier: 1, slideShadows: !0 } }),
        le({
          effect: "coverflow",
          swiper: t,
          on: a,
          setTranslate: () => {
            const { width: e, height: r, slides: a, slidesSizesGrid: s } = t,
              i = t.params.coverflowEffect,
              n = t.isHorizontal(),
              o = t.translate,
              l = n ? e / 2 - o : r / 2 - o,
              d = n ? i.rotate : -i.rotate,
              c = i.depth;
            for (let e = 0, r = a.length; e < r; e += 1) {
              const r = a[e],
                o = s[e],
                u = (l - r.swiperSlideOffset - o / 2) / o,
                p = "function" == typeof i.modifier ? i.modifier(u) : u * i.modifier;
              let m = n ? d * p : 0,
                f = n ? 0 : d * p,
                h = -c * Math.abs(p),
                g = i.stretch;
              "string" == typeof g && -1 !== g.indexOf("%") && (g = (parseFloat(i.stretch) / 100) * o);
              let v = n ? 0 : g * p,
                y = n ? g * p : 0,
                w = 1 - (1 - i.scale) * Math.abs(p);
              Math.abs(y) < 0.001 && (y = 0),
              Math.abs(v) < 0.001 && (v = 0),
              Math.abs(h) < 0.001 && (h = 0),
              Math.abs(m) < 0.001 && (m = 0),
              Math.abs(f) < 0.001 && (f = 0),
              Math.abs(w) < 0.001 && (w = 0),
              t.browser && t.browser.need3dFix && ((Math.abs(m) / 90) % 2 == 1 && (m += 0.001), (Math.abs(f) / 90) % 2 == 1 && (f += 0.001));
              const b = `translate3d(${y}px,${v}px,${h}px)  rotateX(${f}deg) rotateY(${m}deg) scale(${w})`;
              if (((de(0, r).style.transform = b), (r.style.zIndex = 1 - Math.abs(Math.round(p))), i.slideShadows)) {
                let e = n ? r.querySelector(".swiper-slide-shadow-left") : r.querySelector(".swiper-slide-shadow-top"),
                  t = n ? r.querySelector(".swiper-slide-shadow-right") : r.querySelector(".swiper-slide-shadow-bottom");
                e || (e = ue("coverflow", r, n ? "left" : "top")), t || (t = ue("coverflow", r, n ? "right" : "bottom")), e && (e.style.opacity = p > 0 ? p : 0), t && (t.style.opacity = -p > 0 ? -p : 0);
              }
            }
          },
          setTransition: (e) => {
            t.slides
              .map((e) => f(e))
              .forEach((t) => {
                (t.style.transitionDuration = `${e}ms`),
                  t.querySelectorAll(".swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left").forEach((t) => {
                    t.style.transitionDuration = `${e}ms`;
                  });
              });
          },
          perspective: () => !0,
          overwriteParams: () => ({ watchSlidesProgress: !0 }),
        });
    },
    function (e) {
      let { swiper: t, extendParams: r, on: a } = e;
      r({
        creativeEffect: {
          limitProgress: 1,
          shadowPerProgress: !1,
          progressMultiplier: 1,
          perspective: !0,
          prev: { translate: [0, 0, 0], rotate: [0, 0, 0], opacity: 1, scale: 1 },
          next: { translate: [0, 0, 0], rotate: [0, 0, 0], opacity: 1, scale: 1 },
        },
      });
      const s = (e) => ("string" == typeof e ? e : `${e}px`);
      le({
        effect: "creative",
        swiper: t,
        on: a,
        setTranslate: () => {
          const { slides: e, wrapperEl: r, slidesSizesGrid: a } = t,
            i = t.params.creativeEffect,
            { progressMultiplier: n } = i,
            o = t.params.centeredSlides;
          if (o) {
            const e = a[0] / 2 - t.params.slidesOffsetBefore || 0;
            r.style.transform = `translateX(calc(50% - ${e}px))`;
          }
          for (let r = 0; r < e.length; r += 1) {
            const a = e[r],
              l = a.progress,
              d = Math.min(Math.max(a.progress, -i.limitProgress), i.limitProgress);
            let c = d;
            o || (c = Math.min(Math.max(a.originalProgress, -i.limitProgress), i.limitProgress));
            const u = a.swiperSlideOffset,
              p = [t.params.cssMode ? -u - t.translate : -u, 0, 0],
              m = [0, 0, 0];
            let f = !1;
            t.isHorizontal() || ((p[1] = p[0]), (p[0] = 0));
            let h = { translate: [0, 0, 0], rotate: [0, 0, 0], scale: 1, opacity: 1 };
            d < 0 ? ((h = i.next), (f = !0)) : d > 0 && ((h = i.prev), (f = !0)),
              p.forEach((e, t) => {
                p[t] = `calc(${e}px + (${s(h.translate[t])} * ${Math.abs(d * n)}))`;
              }),
              m.forEach((e, r) => {
                let a = h.rotate[r] * Math.abs(d * n);
                t.browser && t.browser.need3dFix && (Math.abs(a) / 90) % 2 == 1 && (a += 0.001), (m[r] = a);
              }),
              (a.style.zIndex = -Math.abs(Math.round(l)) + e.length);
            const g = p.join(", "),
              v = `rotateX(${m[0]}deg) rotateY(${m[1]}deg) rotateZ(${m[2]}deg)`,
              y = c < 0 ? `scale(${1 + (1 - h.scale) * c * n})` : `scale(${1 - (1 - h.scale) * c * n})`,
              w = c < 0 ? 1 + (1 - h.opacity) * c * n : 1 - (1 - h.opacity) * c * n,
              b = `translate3d(${g}) ${v} ${y}`;
            if ((f && h.shadow) || !f) {
              let e = a.querySelector(".swiper-slide-shadow");
              if ((!e && h.shadow && (e = ue("creative", a)), e)) {
                const t = i.shadowPerProgress ? d * (1 / i.limitProgress) : d;
                e.style.opacity = Math.min(Math.max(Math.abs(t), 0), 1);
              }
            }
            const x = de(0, a);
            (x.style.transform = b), (x.style.opacity = w), h.origin && (x.style.transformOrigin = h.origin);
          }
        },
        setTransition: (e) => {
          const r = t.slides.map((e) => f(e));
          r.forEach((t) => {
            (t.style.transitionDuration = `${e}ms`),
              t.querySelectorAll(".swiper-slide-shadow").forEach((t) => {
                t.style.transitionDuration = `${e}ms`;
              });
          }),
            ce({ swiper: t, duration: e, transformElements: r, allSlides: !0 });
        },
        perspective: () => t.params.creativeEffect.perspective,
        overwriteParams: () => ({ watchSlidesProgress: !0, virtualTranslate: !t.params.cssMode }),
      });
    },
    function (e) {
      let { swiper: t, extendParams: r, on: a } = e;
      r({ cardsEffect: { slideShadows: !0, rotate: !0, perSlideRotate: 2, perSlideOffset: 8 } }),
        le({
          effect: "cards",
          swiper: t,
          on: a,
          setTranslate: () => {
            const { slides: e, activeIndex: r, rtlTranslate: a } = t,
              s = t.params.cardsEffect,
              { startTranslate: i, isTouched: n } = t.touchEventsData,
              o = a ? -t.translate : t.translate;
            for (let l = 0; l < e.length; l += 1) {
              const d = e[l],
                c = d.progress,
                u = Math.min(Math.max(c, -4), 4);
              let p = d.swiperSlideOffset;
              t.params.centeredSlides && !t.params.cssMode && (t.wrapperEl.style.transform = `translateX(${t.minTranslate()}px)`), t.params.centeredSlides && t.params.cssMode && (p -= e[0].swiperSlideOffset);
              let m = t.params.cssMode ? -p - t.translate : -p,
                f = 0;
              const h = -100 * Math.abs(u);
              let g = 1,
                v = -s.perSlideRotate * u,
                y = s.perSlideOffset - 0.75 * Math.abs(u);
              const w = t.virtual && t.params.virtual.enabled ? t.virtual.from + l : l,
                b = (w === r || w === r - 1) && u > 0 && u < 1 && (n || t.params.cssMode) && o < i,
                x = (w === r || w === r + 1) && u < 0 && u > -1 && (n || t.params.cssMode) && o > i;
              if (b || x) {
                const e = (1 - Math.abs((Math.abs(u) - 0.5) / 0.5)) ** 0.5;
                (v += -28 * u * e), (g += -0.5 * e), (y += 96 * e), (f = -25 * e * Math.abs(u) + "%");
              }
              if (((m = u < 0 ? `calc(${m}px ${a ? "-" : "+"} (${y * Math.abs(u)}%))` : u > 0 ? `calc(${m}px ${a ? "-" : "+"} (-${y * Math.abs(u)}%))` : `${m}px`), !t.isHorizontal())) {
                const e = f;
                (f = m), (m = e);
              }
              const E = u < 0 ? "" + (1 + (1 - g) * u) : "" + (1 - (1 - g) * u),
                S = `\n        translate3d(${m}, ${f}, ${h}px)\n        rotateZ(${s.rotate ? (a ? -v : v) : 0}deg)\n        scale(${E})\n      `;
              if (s.slideShadows) {
                let e = d.querySelector(".swiper-slide-shadow");
                e || (e = ue("cards", d)), e && (e.style.opacity = Math.min(Math.max((Math.abs(u) - 0.5) / 0.5, 0), 1));
              }
              (d.style.zIndex = -Math.abs(Math.round(c)) + e.length), (de(0, d).style.transform = S);
            }
          },
          setTransition: (e) => {
            const r = t.slides.map((e) => f(e));
            r.forEach((t) => {
              (t.style.transitionDuration = `${e}ms`),
                t.querySelectorAll(".swiper-slide-shadow").forEach((t) => {
                  t.style.transitionDuration = `${e}ms`;
                });
            }),
              ce({ swiper: t, duration: e, transformElements: r });
          },
          perspective: () => !0,
          overwriteParams: () => ({ watchSlidesProgress: !0, virtualTranslate: !t.params.cssMode }),
        });
    },
  ];
  function me() {
    document.querySelectorAll(".testimonial-slider").forEach(function (e) {
      var t = e.querySelector(".swiper");
      new ee(t, {
        loop: !1,
        slidesPerView: 1,
        autoHeight: !0,
        pagination: { el: e.querySelector(".swiper-pagination"), clickable: !0 },
        navigation: { nextEl: e.querySelector(".swiper-button-next"), prevEl: e.querySelector(".swiper-button-prev") },
      });
    });
  }
  function fe() {
    var e,
      t = window.matchMedia("(max-width:767px)"),
      r = function () {
        t.matches ? void 0 !== e && e.destroy(!0, !0) : a();
      },
      a = function () {
        e = new ee(".project-slider .swiper", {
          autoHeight: !0,
          slidesPerView: "auto",
          spaceBetween: 60,
          updateOnWindowResize: !0,
          navigation: { nextEl: ".project-slider .swiper-button-next", prevEl: ".project-slider .swiper-button-prev" },
          breakpoints: { 1024: { spaceBetween: 140 } },
        });
      };
    t.addEventListener("change", r), r();
  }
  function he() {
    var e,
      t,
      r,
      a,
      s,
      i = window,
      n = 0,
      o = document.getElementById("header");
    function l() {
      var e = i.scrollY,
        t = document.getElementById("header").offsetHeight;
      document.querySelector("body").classList.contains("nav-active") ||
      (e >= t ? o.classList.add("sticky") : (o.classList.remove("sticky"), o.classList.remove("show")), o.classList.contains("sticky") && (e < n ? o.classList.add("show") : o.classList.remove("show"))),
        e >= 1.3 * t ? o.classList.add("duration-300") : o.classList.remove("duration-300"),
        (n = e);
    }
    document.addEventListener("scroll", l),
      l(),
      document.addEventListener("click", function (e) {
        var t = document.querySelector("body"),
          r = t.classList.contains("nav-active");
        e.target.classList.contains("nav-opener") && (e.preventDefault(), r ? t.classList.remove("nav-active") : t.classList.add("nav-active"));
      }),
      (r = document.querySelector("html")),
      (a = "resize-active"),
      (s = function () {
        (e = !1), r.classList.remove(a);
      }),
      window.addEventListener("resize", function () {
        e || ((e = !0), r.classList.add(a)), document.querySelector("body").classList.remove("nav-active"), clearTimeout(t), (t = setTimeout(s, 500));
      });
  }
  ee.use(pe);
  var ge = { update: null, begin: null, loopBegin: null, changeBegin: null, change: null, changeComplete: null, loopComplete: null, complete: null, loop: 1, direction: "normal", autoplay: !0, timelineOffset: 0 },
    ve = { duration: 1e3, delay: 0, endDelay: 0, easing: "easeOutElastic(1, .5)", round: 0 },
    ye = ["translateX", "translateY", "translateZ", "rotate", "rotateX", "rotateY", "rotateZ", "scale", "scaleX", "scaleY", "scaleZ", "skew", "skewX", "skewY", "perspective", "matrix", "matrix3d"],
    we = { CSS: {}, springs: {} };
  function be(e, t, r) {
    return Math.min(Math.max(e, t), r);
  }
  function xe(e, t) {
    return e.indexOf(t) > -1;
  }
  function Ee(e, t) {
    return e.apply(null, t);
  }
  var Se = {
    arr: function (e) {
      return Array.isArray(e);
    },
    obj: function (e) {
      return xe(Object.prototype.toString.call(e), "Object");
    },
    pth: function (e) {
      return Se.obj(e) && e.hasOwnProperty("totalLength");
    },
    svg: function (e) {
      return e instanceof SVGElement;
    },
    inp: function (e) {
      return e instanceof HTMLInputElement;
    },
    dom: function (e) {
      return e.nodeType || Se.svg(e);
    },
    str: function (e) {
      return "string" == typeof e;
    },
    fnc: function (e) {
      return "function" == typeof e;
    },
    und: function (e) {
      return void 0 === e;
    },
    nil: function (e) {
      return Se.und(e) || null === e;
    },
    hex: function (e) {
      return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(e);
    },
    rgb: function (e) {
      return /^rgb/.test(e);
    },
    hsl: function (e) {
      return /^hsl/.test(e);
    },
    col: function (e) {
      return Se.hex(e) || Se.rgb(e) || Se.hsl(e);
    },
    key: function (e) {
      return !ge.hasOwnProperty(e) && !ve.hasOwnProperty(e) && "targets" !== e && "keyframes" !== e;
    },
  };
  function Te(e) {
    var t = /\(([^)]+)\)/.exec(e);
    return t
      ? t[1].split(",").map(function (e) {
        return parseFloat(e);
      })
      : [];
  }
  function Me(e, t) {
    var r = Te(e),
      a = be(Se.und(r[0]) ? 1 : r[0], 0.1, 100),
      s = be(Se.und(r[1]) ? 100 : r[1], 0.1, 100),
      i = be(Se.und(r[2]) ? 10 : r[2], 0.1, 100),
      n = be(Se.und(r[3]) ? 0 : r[3], 0.1, 100),
      o = Math.sqrt(s / a),
      l = i / (2 * Math.sqrt(s * a)),
      d = l < 1 ? o * Math.sqrt(1 - l * l) : 0,
      c = l < 1 ? (l * o - n) / d : -n + o;
    function u(e) {
      var r = t ? (t * e) / 1e3 : e;
      return (r = l < 1 ? Math.exp(-r * l * o) * (1 * Math.cos(d * r) + c * Math.sin(d * r)) : (1 + c * r) * Math.exp(-r * o)), 0 === e || 1 === e ? e : 1 - r;
    }
    return t
      ? u
      : function () {
        var t = we.springs[e];
        if (t) return t;
        for (var r = 1 / 6, a = 0, s = 0; ; )
          if (1 === u((a += r))) {
            if (++s >= 16) break;
          } else s = 0;
        var i = a * r * 1e3;
        return (we.springs[e] = i), i;
      };
  }
  function Ce(e) {
    return (
      void 0 === e && (e = 10),
        function (t) {
          return Math.ceil(be(t, 1e-6, 1) * e) * (1 / e);
        }
    );
  }
  var Le,
    Pe,
    Ie = (function () {
      var e = 0.1;
      function t(e, t) {
        return 1 - 3 * t + 3 * e;
      }
      function r(e, t) {
        return 3 * t - 6 * e;
      }
      function a(e) {
        return 3 * e;
      }
      function s(e, s, i) {
        return ((t(s, i) * e + r(s, i)) * e + a(s)) * e;
      }
      function i(e, s, i) {
        return 3 * t(s, i) * e * e + 2 * r(s, i) * e + a(s);
      }
      return function (t, r, a, n) {
        if (0 <= t && t <= 1 && 0 <= a && a <= 1) {
          var o = new Float32Array(11);
          if (t !== r || a !== n) for (var l = 0; l < 11; ++l) o[l] = s(l * e, t, a);
          return function (l) {
            return (t === r && a === n) || 0 === l || 1 === l
              ? l
              : s(
                (function (r) {
                  for (var n = 0, l = 1; 10 !== l && o[l] <= r; ++l) n += e;
                  --l;
                  var d = n + ((r - o[l]) / (o[l + 1] - o[l])) * e,
                    c = i(d, t, a);
                  return c >= 0.001
                    ? (function (e, t, r, a) {
                      for (var n = 0; n < 4; ++n) {
                        var o = i(t, r, a);
                        if (0 === o) return t;
                        t -= (s(t, r, a) - e) / o;
                      }
                      return t;
                    })(r, d, t, a)
                    : 0 === c
                      ? d
                      : (function (e, t, r, a, i) {
                        var n,
                          o,
                          l = 0;
                        do {
                          (n = s((o = t + (r - t) / 2), a, i) - e) > 0 ? (r = o) : (t = o);
                        } while (Math.abs(n) > 1e-7 && ++l < 10);
                        return o;
                      })(r, n, n + e, t, a);
                })(l),
                r,
                n
              );
          };
        }
      };
    })(),
    Oe =
      ((Le = {
        linear: function () {
          return function (e) {
            return e;
          };
        },
      }),
        (Pe = {
          Sine: function () {
            return function (e) {
              return 1 - Math.cos((e * Math.PI) / 2);
            };
          },
          Expo: function () {
            return function (e) {
              return e ? Math.pow(2, 10 * e - 10) : 0;
            };
          },
          Circ: function () {
            return function (e) {
              return 1 - Math.sqrt(1 - e * e);
            };
          },
          Back: function () {
            return function (e) {
              return e * e * (3 * e - 2);
            };
          },
          Bounce: function () {
            return function (e) {
              for (var t, r = 4; e < ((t = Math.pow(2, --r)) - 1) / 11; );
              return 1 / Math.pow(4, 3 - r) - 7.5625 * Math.pow((3 * t - 2) / 22 - e, 2);
            };
          },
          Elastic: function (e, t) {
            void 0 === e && (e = 1), void 0 === t && (t = 0.5);
            var r = be(e, 1, 10),
              a = be(t, 0.1, 2);
            return function (e) {
              return 0 === e || 1 === e ? e : -r * Math.pow(2, 10 * (e - 1)) * Math.sin(((e - 1 - (a / (2 * Math.PI)) * Math.asin(1 / r)) * (2 * Math.PI)) / a);
            };
          },
        }),
        ["Quad", "Cubic", "Quart", "Quint"].forEach(function (e, t) {
          Pe[e] = function () {
            return function (e) {
              return Math.pow(e, t + 2);
            };
          };
        }),
        Object.keys(Pe).forEach(function (e) {
          var t = Pe[e];
          (Le["easeIn" + e] = t),
            (Le["easeOut" + e] = function (e, r) {
              return function (a) {
                return 1 - t(e, r)(1 - a);
              };
            }),
            (Le["easeInOut" + e] = function (e, r) {
              return function (a) {
                return a < 0.5 ? t(e, r)(2 * a) / 2 : 1 - t(e, r)(-2 * a + 2) / 2;
              };
            }),
            (Le["easeOutIn" + e] = function (e, r) {
              return function (a) {
                return a < 0.5 ? (1 - t(e, r)(1 - 2 * a)) / 2 : (t(e, r)(2 * a - 1) + 1) / 2;
              };
            });
        }),
        Le);
  function ke(e, t) {
    if (Se.fnc(e)) return e;
    var r = e.split("(")[0],
      a = Oe[r],
      s = Te(e);
    switch (r) {
      case "spring":
        return Me(e, t);
      case "cubicBezier":
        return Ee(Ie, s);
      case "steps":
        return Ee(Ce, s);
      default:
        return Ee(a, s);
    }
  }
  function Ae(e) {
    try {
      return document.querySelectorAll(e);
    } catch (e) {
      return;
    }
  }
  function ze(e, t) {
    for (var r = e.length, a = arguments.length >= 2 ? arguments[1] : void 0, s = [], i = 0; i < r; i++)
      if (i in e) {
        var n = e[i];
        t.call(a, n, i, e) && s.push(n);
      }
    return s;
  }
  function $e(e) {
    return e.reduce(function (e, t) {
      return e.concat(Se.arr(t) ? $e(t) : t);
    }, []);
  }
  function De(e) {
    return Se.arr(e) ? e : (Se.str(e) && (e = Ae(e) || e), e instanceof NodeList || e instanceof HTMLCollection ? [].slice.call(e) : [e]);
  }
  function Ge(e, t) {
    return e.some(function (e) {
      return e === t;
    });
  }
  function Be(e) {
    var t = {};
    for (var r in e) t[r] = e[r];
    return t;
  }
  function Ne(e, t) {
    var r = Be(e);
    for (var a in e) r[a] = t.hasOwnProperty(a) ? t[a] : e[a];
    return r;
  }
  function qe(e, t) {
    var r = Be(e);
    for (var a in t) r[a] = Se.und(e[a]) ? t[a] : e[a];
    return r;
  }
  function He(e) {
    var t = /[+-]?\d*\.?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?(%|px|pt|em|rem|in|cm|mm|ex|ch|pc|vw|vh|vmin|vmax|deg|rad|turn)?$/.exec(e);
    if (t) return t[1];
  }
  function je(e, t) {
    return Se.fnc(e) ? e(t.target, t.id, t.total) : e;
  }
  function Xe(e, t) {
    return e.getAttribute(t);
  }
  function Fe(e, t, r) {
    if (Ge([r, "deg", "rad", "turn"], He(t))) return t;
    var a = we.CSS[t + r];
    if (!Se.und(a)) return a;
    var s = document.createElement(e.tagName),
      i = e.parentNode && e.parentNode !== document ? e.parentNode : document.body;
    i.appendChild(s), (s.style.position = "absolute"), (s.style.width = 100 + r);
    var n = 100 / s.offsetWidth;
    i.removeChild(s);
    var o = n * parseFloat(t);
    return (we.CSS[t + r] = o), o;
  }
  function Ye(e, t, r) {
    if (t in e.style) {
      var a = t.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase(),
        s = e.style[t] || getComputedStyle(e).getPropertyValue(a) || "0";
      return r ? Fe(e, s, r) : s;
    }
  }
  function _e(e, t) {
    return Se.dom(e) && !Se.inp(e) && (!Se.nil(Xe(e, t)) || (Se.svg(e) && e[t])) ? "attribute" : Se.dom(e) && Ge(ye, t) ? "transform" : Se.dom(e) && "transform" !== t && Ye(e, t) ? "css" : null != e[t] ? "object" : void 0;
  }
  function Ve(e) {
    if (Se.dom(e)) {
      for (var t, r = e.style.transform || "", a = /(\w+)\(([^)]*)\)/g, s = new Map(); (t = a.exec(r)); ) s.set(t[1], t[2]);
      return s;
    }
  }
  function Re(e, t, r, a) {
    switch (_e(e, t)) {
      case "transform":
        return (function (e, t, r, a) {
          var s = xe(t, "scale")
              ? 1
              : 0 +
              (function (e) {
                return xe(e, "translate") || "perspective" === e ? "px" : xe(e, "rotate") || xe(e, "skew") ? "deg" : void 0;
              })(t),
            i = Ve(e).get(t) || s;
          return r && (r.transforms.list.set(t, i), (r.transforms.last = t)), a ? Fe(e, i, a) : i;
        })(e, t, a, r);
      case "css":
        return Ye(e, t, r);
      case "attribute":
        return Xe(e, t);
      default:
        return e[t] || 0;
    }
  }
  function We(e, t) {
    var r = /^(\*=|\+=|-=)/.exec(e);
    if (!r) return e;
    var a = He(e) || 0,
      s = parseFloat(t),
      i = parseFloat(e.replace(r[0], ""));
    switch (r[0][0]) {
      case "+":
        return s + i + a;
      case "-":
        return s - i + a;
      case "*":
        return s * i + a;
    }
  }
  function Ue(e, t) {
    if (Se.col(e))
      return (function (e) {
        return Se.rgb(e)
          ? (r = /rgb\((\d+,\s*[\d]+,\s*[\d]+)\)/g.exec((t = e)))
            ? "rgba(" + r[1] + ",1)"
            : t
          : Se.hex(e)
            ? (function (e) {
              var t = e.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, function (e, t, r, a) {
                  return t + t + r + r + a + a;
                }),
                r = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(t);
              return "rgba(" + parseInt(r[1], 16) + "," + parseInt(r[2], 16) + "," + parseInt(r[3], 16) + ",1)";
            })(e)
            : Se.hsl(e)
              ? (function (e) {
                var t,
                  r,
                  a,
                  s = /hsl\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\)/g.exec(e) || /hsla\((\d+),\s*([\d.]+)%,\s*([\d.]+)%,\s*([\d.]+)\)/g.exec(e),
                  i = parseInt(s[1], 10) / 360,
                  n = parseInt(s[2], 10) / 100,
                  o = parseInt(s[3], 10) / 100,
                  l = s[4] || 1;
                function d(e, t, r) {
                  return r < 0 && (r += 1), r > 1 && (r -= 1), r < 1 / 6 ? e + 6 * (t - e) * r : r < 0.5 ? t : r < 2 / 3 ? e + (t - e) * (2 / 3 - r) * 6 : e;
                }
                if (0 == n) t = r = a = o;
                else {
                  var c = o < 0.5 ? o * (1 + n) : o + n - o * n,
                    u = 2 * o - c;
                  (t = d(u, c, i + 1 / 3)), (r = d(u, c, i)), (a = d(u, c, i - 1 / 3));
                }
                return "rgba(" + 255 * t + "," + 255 * r + "," + 255 * a + "," + l + ")";
              })(e)
              : void 0;
        var t, r;
      })(e);
    if (/\s/g.test(e)) return e;
    var r = He(e),
      a = r ? e.substr(0, e.length - r.length) : e;
    return t ? a + t : a;
  }
  function Ke(e, t) {
    return Math.sqrt(Math.pow(t.x - e.x, 2) + Math.pow(t.y - e.y, 2));
  }
  function Ze(e) {
    for (var t, r = e.points, a = 0, s = 0; s < r.numberOfItems; s++) {
      var i = r.getItem(s);
      s > 0 && (a += Ke(t, i)), (t = i);
    }
    return a;
  }
  function Qe(e) {
    if (e.getTotalLength) return e.getTotalLength();
    switch (e.tagName.toLowerCase()) {
      case "circle":
        return (function (e) {
          return 2 * Math.PI * Xe(e, "r");
        })(e);
      case "rect":
        return (function (e) {
          return 2 * Xe(e, "width") + 2 * Xe(e, "height");
        })(e);
      case "line":
        return (function (e) {
          return Ke({ x: Xe(e, "x1"), y: Xe(e, "y1") }, { x: Xe(e, "x2"), y: Xe(e, "y2") });
        })(e);
      case "polyline":
        return Ze(e);
      case "polygon":
        return (function (e) {
          var t = e.points;
          return Ze(e) + Ke(t.getItem(t.numberOfItems - 1), t.getItem(0));
        })(e);
    }
  }
  function Je(e, t) {
    var r = t || {},
      a =
        r.el ||
        (function (e) {
          for (var t = e.parentNode; Se.svg(t) && Se.svg(t.parentNode); ) t = t.parentNode;
          return t;
        })(e),
      s = a.getBoundingClientRect(),
      i = Xe(a, "viewBox"),
      n = s.width,
      o = s.height,
      l = r.viewBox || (i ? i.split(" ") : [0, 0, n, o]);
    return { el: a, viewBox: l, x: l[0] / 1, y: l[1] / 1, w: n, h: o, vW: l[2], vH: l[3] };
  }
  function et(e, t, r) {
    function a(r) {
      void 0 === r && (r = 0);
      var a = t + r >= 1 ? t + r : 0;
      return e.el.getPointAtLength(a);
    }
    var s = Je(e.el, e.svg),
      i = a(),
      n = a(-1),
      o = a(1),
      l = r ? 1 : s.w / s.vW,
      d = r ? 1 : s.h / s.vH;
    switch (e.property) {
      case "x":
        return (i.x - s.x) * l;
      case "y":
        return (i.y - s.y) * d;
      case "angle":
        return (180 * Math.atan2(o.y - n.y, o.x - n.x)) / Math.PI;
    }
  }
  function tt(e, t) {
    var r = /[+-]?\d*\.?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?/g,
      a = Ue(Se.pth(e) ? e.totalLength : e, t) + "";
    return { original: a, numbers: a.match(r) ? a.match(r).map(Number) : [0], strings: Se.str(e) || t ? a.split(r) : [] };
  }
  function rt(e) {
    return ze(e ? $e(Se.arr(e) ? e.map(De) : De(e)) : [], function (e, t, r) {
      return r.indexOf(e) === t;
    });
  }
  function at(e) {
    var t = rt(e);
    return t.map(function (e, r) {
      return { target: e, id: r, total: t.length, transforms: { list: Ve(e) } };
    });
  }
  function st(e, t) {
    var r = Be(t);
    if ((/^spring/.test(r.easing) && (r.duration = Me(r.easing)), Se.arr(e))) {
      var a = e.length;
      2 !== a || Se.obj(e[0]) ? Se.fnc(t.duration) || (r.duration = t.duration / a) : (e = { value: e });
    }
    var s = Se.arr(e) ? e : [e];
    return s
      .map(function (e, r) {
        var a = Se.obj(e) && !Se.pth(e) ? e : { value: e };
        return Se.und(a.delay) && (a.delay = r ? 0 : t.delay), Se.und(a.endDelay) && (a.endDelay = r === s.length - 1 ? t.endDelay : 0), a;
      })
      .map(function (e) {
        return qe(e, r);
      });
  }
  var it = {
    css: function (e, t, r) {
      return (e.style[t] = r);
    },
    attribute: function (e, t, r) {
      return e.setAttribute(t, r);
    },
    object: function (e, t, r) {
      return (e[t] = r);
    },
    transform: function (e, t, r, a, s) {
      if ((a.list.set(t, r), t === a.last || s)) {
        var i = "";
        a.list.forEach(function (e, t) {
          i += t + "(" + e + ") ";
        }),
          (e.style.transform = i);
      }
    },
  };
  function nt(e, t) {
    at(e).forEach(function (e) {
      for (var r in t) {
        var a = je(t[r], e),
          s = e.target,
          i = He(a),
          n = Re(s, r, i, e),
          o = We(Ue(a, i || He(n)), n),
          l = _e(s, r);
        it[l](s, r, o, e.transforms, !0);
      }
    });
  }
  function ot(e, t) {
    return ze(
      $e(
        e.map(function (e) {
          return t.map(function (t) {
            return (function (e, t) {
              var r = _e(e.target, t.name);
              if (r) {
                var a = (function (e, t) {
                    var r;
                    return e.tweens.map(function (a) {
                      var s = (function (e, t) {
                          var r = {};
                          for (var a in e) {
                            var s = je(e[a], t);
                            Se.arr(s) &&
                            1 ===
                            (s = s.map(function (e) {
                              return je(e, t);
                            })).length &&
                            (s = s[0]),
                              (r[a] = s);
                          }
                          return (r.duration = parseFloat(r.duration)), (r.delay = parseFloat(r.delay)), r;
                        })(a, t),
                        i = s.value,
                        n = Se.arr(i) ? i[1] : i,
                        o = He(n),
                        l = Re(t.target, e.name, o, t),
                        d = r ? r.to.original : l,
                        c = Se.arr(i) ? i[0] : d,
                        u = He(c) || He(l),
                        p = o || u;
                      return (
                        Se.und(n) && (n = d),
                          (s.from = tt(c, p)),
                          (s.to = tt(We(n, c), p)),
                          (s.start = r ? r.end : 0),
                          (s.end = s.start + s.delay + s.duration + s.endDelay),
                          (s.easing = ke(s.easing, s.duration)),
                          (s.isPath = Se.pth(i)),
                          (s.isPathTargetInsideSVG = s.isPath && Se.svg(t.target)),
                          (s.isColor = Se.col(s.from.original)),
                        s.isColor && (s.round = 1),
                          (r = s),
                          s
                      );
                    });
                  })(t, e),
                  s = a[a.length - 1];
                return { type: r, property: t.name, animatable: e, tweens: a, duration: s.end, delay: a[0].delay, endDelay: s.endDelay };
              }
            })(e, t);
          });
        })
      ),
      function (e) {
        return !Se.und(e);
      }
    );
  }
  function lt(e, t) {
    var r = e.length,
      a = function (e) {
        return e.timelineOffset ? e.timelineOffset : 0;
      },
      s = {};
    return (
      (s.duration = r
        ? Math.max.apply(
          Math,
          e.map(function (e) {
            return a(e) + e.duration;
          })
        )
        : t.duration),
        (s.delay = r
          ? Math.min.apply(
            Math,
            e.map(function (e) {
              return a(e) + e.delay;
            })
          )
          : t.delay),
        (s.endDelay = r
          ? s.duration -
          Math.max.apply(
            Math,
            e.map(function (e) {
              return a(e) + e.duration - e.endDelay;
            })
          )
          : t.endDelay),
        s
    );
  }
  var dt = 0,
    ct = [],
    ut = (function () {
      var e;
      function t(r) {
        for (var a = ct.length, s = 0; s < a; ) {
          var i = ct[s];
          i.paused ? (ct.splice(s, 1), a--) : (i.tick(r), s++);
        }
        e = s > 0 ? requestAnimationFrame(t) : void 0;
      }
      return (
        "undefined" != typeof document &&
        document.addEventListener("visibilitychange", function () {
          mt.suspendWhenDocumentHidden &&
          (pt()
            ? (e = cancelAnimationFrame(e))
            : (ct.forEach(function (e) {
              return e._onDocumentVisibility();
            }),
              ut()));
        }),
          function () {
            e || (pt() && mt.suspendWhenDocumentHidden) || !(ct.length > 0) || (e = requestAnimationFrame(t));
          }
      );
    })();
  function pt() {
    return !!document && document.hidden;
  }
  function mt(e) {
    void 0 === e && (e = {});
    var t,
      r = 0,
      a = 0,
      s = 0,
      i = 0,
      n = null;
    function o(e) {
      var t =
        window.Promise &&
        new Promise(function (e) {
          return (n = e);
        });
      return (e.finished = t), t;
    }
    var l = (function (e) {
      var t = Ne(ge, e),
        r = Ne(ve, e),
        a = (function (e, t) {
          var r = [],
            a = t.keyframes;
          for (var s in (a &&
          (t = qe(
            (function (e) {
              for (
                var t = ze(
                    $e(
                      e.map(function (e) {
                        return Object.keys(e);
                      })
                    ),
                    function (e) {
                      return Se.key(e);
                    }
                  ).reduce(function (e, t) {
                    return e.indexOf(t) < 0 && e.push(t), e;
                  }, []),
                  r = {},
                  a = function (a) {
                    var s = t[a];
                    r[s] = e.map(function (e) {
                      var t = {};
                      for (var r in e) Se.key(r) ? r == s && (t.value = e[r]) : (t[r] = e[r]);
                      return t;
                    });
                  },
                  s = 0;
                s < t.length;
                s++
              )
                a(s);
              return r;
            })(a),
            t
          )),
            t))
            Se.key(s) && r.push({ name: s, tweens: st(t[s], e) });
          return r;
        })(r, e),
        s = at(e.targets),
        i = ot(s, a),
        n = lt(i, r),
        o = dt;
      return dt++, qe(t, { id: o, children: [], animatables: s, animations: i, duration: n.duration, delay: n.delay, endDelay: n.endDelay });
    })(e);
    function d() {
      var e = l.direction;
      "alternate" !== e && (l.direction = "normal" !== e ? "normal" : "reverse"),
        (l.reversed = !l.reversed),
        t.forEach(function (e) {
          return (e.reversed = l.reversed);
        });
    }
    function c(e) {
      return l.reversed ? l.duration - e : e;
    }
    function u() {
      (r = 0), (a = c(l.currentTime) * (1 / mt.speed));
    }
    function p(e, t) {
      t && t.seek(e - t.timelineOffset);
    }
    function m(e) {
      for (var t = 0, r = l.animations, a = r.length; t < a; ) {
        var s = r[t],
          i = s.animatable,
          n = s.tweens,
          o = n.length - 1,
          d = n[o];
        o &&
        (d =
          ze(n, function (t) {
            return e < t.end;
          })[0] || d);
        for (var c = be(e - d.start - d.delay, 0, d.duration) / d.duration, u = isNaN(c) ? 1 : d.easing(c), p = d.to.strings, m = d.round, f = [], h = d.to.numbers.length, g = void 0, v = 0; v < h; v++) {
          var y = void 0,
            w = d.to.numbers[v],
            b = d.from.numbers[v] || 0;
          (y = d.isPath ? et(d.value, u * w, d.isPathTargetInsideSVG) : b + u * (w - b)), m && ((d.isColor && v > 2) || (y = Math.round(y * m) / m)), f.push(y);
        }
        var x = p.length;
        if (x) {
          g = p[0];
          for (var E = 0; E < x; E++) {
            p[E];
            var S = p[E + 1],
              T = f[E];
            isNaN(T) || (g += S ? T + S : T + " ");
          }
        } else g = f[0];
        it[s.type](i.target, s.property, g, i.transforms), (s.currentValue = g), t++;
      }
    }
    function f(e) {
      l[e] && !l.passThrough && l[e](l);
    }
    function h(e) {
      var u = l.duration,
        h = l.delay,
        g = u - l.endDelay,
        v = c(e);
      (l.progress = be((v / u) * 100, 0, 100)),
        (l.reversePlayback = v < l.currentTime),
      t &&
      (function (e) {
        if (l.reversePlayback) for (var r = i; r--; ) p(e, t[r]);
        else for (var a = 0; a < i; a++) p(e, t[a]);
      })(v),
      !l.began && l.currentTime > 0 && ((l.began = !0), f("begin")),
      !l.loopBegan && l.currentTime > 0 && ((l.loopBegan = !0), f("loopBegin")),
      v <= h && 0 !== l.currentTime && m(0),
      ((v >= g && l.currentTime !== u) || !u) && m(u),
        v > h && v < g ? (l.changeBegan || ((l.changeBegan = !0), (l.changeCompleted = !1), f("changeBegin")), f("change"), m(v)) : l.changeBegan && ((l.changeCompleted = !0), (l.changeBegan = !1), f("changeComplete")),
        (l.currentTime = be(v, 0, u)),
      l.began && f("update"),
      e >= u &&
      ((a = 0),
      l.remaining && !0 !== l.remaining && l.remaining--,
        l.remaining
          ? ((r = s), f("loopComplete"), (l.loopBegan = !1), "alternate" === l.direction && d())
          : ((l.paused = !0), l.completed || ((l.completed = !0), f("loopComplete"), f("complete"), !l.passThrough && "Promise" in window && (n(), o(l)))));
    }
    return (
      o(l),
        (l.reset = function () {
          var e = l.direction;
          (l.passThrough = !1),
            (l.currentTime = 0),
            (l.progress = 0),
            (l.paused = !0),
            (l.began = !1),
            (l.loopBegan = !1),
            (l.changeBegan = !1),
            (l.completed = !1),
            (l.changeCompleted = !1),
            (l.reversePlayback = !1),
            (l.reversed = "reverse" === e),
            (l.remaining = l.loop),
            (t = l.children);
          for (var r = (i = t.length); r--; ) l.children[r].reset();
          ((l.reversed && !0 !== l.loop) || ("alternate" === e && 1 === l.loop)) && l.remaining++, m(l.reversed ? l.duration : 0);
        }),
        (l._onDocumentVisibility = u),
        (l.set = function (e, t) {
          return nt(e, t), l;
        }),
        (l.tick = function (e) {
          (s = e), r || (r = s), h((s + (a - r)) * mt.speed);
        }),
        (l.seek = function (e) {
          h(c(e));
        }),
        (l.pause = function () {
          (l.paused = !0), u();
        }),
        (l.play = function () {
          l.paused && (l.completed && l.reset(), (l.paused = !1), ct.push(l), u(), ut());
        }),
        (l.reverse = function () {
          d(), (l.completed = !l.reversed), u();
        }),
        (l.restart = function () {
          l.reset(), l.play();
        }),
        (l.remove = function (e) {
          ht(rt(e), l);
        }),
        l.reset(),
      l.autoplay && l.play(),
        l
    );
  }
  function ft(e, t) {
    for (var r = t.length; r--; ) Ge(e, t[r].animatable.target) && t.splice(r, 1);
  }
  function ht(e, t) {
    var r = t.animations,
      a = t.children;
    ft(e, r);
    for (var s = a.length; s--; ) {
      var i = a[s],
        n = i.animations;
      ft(e, n), n.length || i.children.length || a.splice(s, 1);
    }
    r.length || a.length || t.pause();
  }
  (mt.version = "3.2.1"),
    (mt.speed = 1),
    (mt.suspendWhenDocumentHidden = !0),
    (mt.running = ct),
    (mt.remove = function (e) {
      for (var t = rt(e), r = ct.length; r--; ) ht(t, ct[r]);
    }),
    (mt.get = Re),
    (mt.set = nt),
    (mt.convertPx = Fe),
    (mt.path = function (e, t) {
      var r = Se.str(e) ? Ae(e)[0] : e,
        a = t || 100;
      return function (e) {
        return { property: e, el: r, svg: Je(r), totalLength: Qe(r) * (a / 100) };
      };
    }),
    (mt.setDashoffset = function (e) {
      var t = Qe(e);
      return e.setAttribute("stroke-dasharray", t), t;
    }),
    (mt.stagger = function (e, t) {
      void 0 === t && (t = {});
      var r = t.direction || "normal",
        a = t.easing ? ke(t.easing) : null,
        s = t.grid,
        i = t.axis,
        n = t.from || 0,
        o = "first" === n,
        l = "center" === n,
        d = "last" === n,
        c = Se.arr(e),
        u = c ? parseFloat(e[0]) : parseFloat(e),
        p = c ? parseFloat(e[1]) : 0,
        m = He(c ? e[1] : e) || 0,
        f = t.start || 0 + (c ? u : 0),
        h = [],
        g = 0;
      return function (e, t, v) {
        if ((o && (n = 0), l && (n = (v - 1) / 2), d && (n = v - 1), !h.length)) {
          for (var y = 0; y < v; y++) {
            if (s) {
              var w = l ? (s[0] - 1) / 2 : n % s[0],
                b = l ? (s[1] - 1) / 2 : Math.floor(n / s[0]),
                x = w - (y % s[0]),
                E = b - Math.floor(y / s[0]),
                S = Math.sqrt(x * x + E * E);
              "x" === i && (S = -x), "y" === i && (S = -E), h.push(S);
            } else h.push(Math.abs(n - y));
            g = Math.max.apply(Math, h);
          }
          a &&
          (h = h.map(function (e) {
            return a(e / g) * g;
          })),
          "reverse" === r &&
          (h = h.map(function (e) {
            return i ? (e < 0 ? -1 * e : -e) : Math.abs(g - e);
          }));
        }
        return f + (c ? (p - u) / g : u) * (Math.round(100 * h[t]) / 100) + m;
      };
    }),
    (mt.timeline = function (e) {
      void 0 === e && (e = {});
      var t = mt(e);
      return (
        (t.duration = 0),
          (t.add = function (r, a) {
            var s = ct.indexOf(t),
              i = t.children;
            function n(e) {
              e.passThrough = !0;
            }
            s > -1 && ct.splice(s, 1);
            for (var o = 0; o < i.length; o++) n(i[o]);
            var l = qe(r, Ne(ve, e));
            l.targets = l.targets || e.targets;
            var d = t.duration;
            (l.autoplay = !1), (l.direction = t.direction), (l.timelineOffset = Se.und(a) ? d : We(a, d)), n(t), t.seek(l.timelineOffset);
            var c = mt(l);
            n(c), i.push(c);
            var u = lt(i, e);
            return (t.delay = u.delay), (t.endDelay = u.endDelay), (t.duration = u.duration), t.seek(0), t.reset(), t.autoplay && t.play(), t;
          }),
          t
      );
    }),
    (mt.easing = ke),
    (mt.penner = Oe),
    (mt.random = function (e, t) {
      return Math.floor(Math.random() * (t - e + 1)) + e;
    });
  var gt = mt;
  function vt(e) {
    return (
      (vt =
        "function" == typeof Symbol && "symbol" == typeof Symbol.iterator
          ? function (e) {
            return typeof e;
          }
          : function (e) {
            return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
          }),
        vt(e)
    );
  }
  function yt(e, t) {
    var r = Object.keys(e);
    if (Object.getOwnPropertySymbols) {
      var a = Object.getOwnPropertySymbols(e);
      t &&
      (a = a.filter(function (t) {
        return Object.getOwnPropertyDescriptor(e, t).enumerable;
      })),
        r.push.apply(r, a);
    }
    return r;
  }
  function wt(e, t, r) {
    var a = "initialized",
      s = (function (e) {
        for (var t = 1; t < arguments.length; t++) {
          var r = null != arguments[t] ? arguments[t] : {};
          t % 2
            ? yt(Object(r), !0).forEach(function (t) {
              var a, s, i, n;
              (a = e),
                (s = t),
                (i = r[t]),
                (n = (function (e, t) {
                  if ("object" != vt(e) || !e) return e;
                  var r = e[Symbol.toPrimitive];
                  if (void 0 !== r) {
                    var a = r.call(e, "string");
                    if ("object" != vt(a)) return a;
                    throw new TypeError("@@toPrimitive must return a primitive value.");
                  }
                  return String(e);
                })(s)),
                (s = "symbol" == vt(n) ? n : n + "") in a ? Object.defineProperty(a, s, { value: i, enumerable: !0, configurable: !0, writable: !0 }) : (a[s] = i);
            })
            : Object.getOwnPropertyDescriptors
              ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(r))
              : yt(Object(r)).forEach(function (t) {
                Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(r, t));
              });
        }
        return e;
      })({ rootMargin: "0px 0px -100px 0px", threshold: 0 }, r);
    if (e) {
      var i = document.querySelectorAll(e),
        n = new IntersectionObserver(function (e) {
          e.forEach(function (e) {
            e.isIntersecting && !e.target.classList.contains(a) && (t(e.target), e.target.classList.add(a));
          });
        }, s);
      i.forEach(function (e) {
        return n.observe(e);
      });
    }
  }
  function bt(e) {
    return (
      (bt =
        "function" == typeof Symbol && "symbol" == typeof Symbol.iterator
          ? function (e) {
            return typeof e;
          }
          : function (e) {
            return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
          }),
        bt(e)
    );
  }
  function xt() {
    xt = function () {
      return t;
    };
    var e,
      t = {},
      r = Object.prototype,
      a = r.hasOwnProperty,
      s =
        Object.defineProperty ||
        function (e, t, r) {
          e[t] = r.value;
        },
      i = "function" == typeof Symbol ? Symbol : {},
      n = i.iterator || "@@iterator",
      o = i.asyncIterator || "@@asyncIterator",
      l = i.toStringTag || "@@toStringTag";
    function d(e, t, r) {
      return Object.defineProperty(e, t, { value: r, enumerable: !0, configurable: !0, writable: !0 }), e[t];
    }
    try {
      d({}, "");
    } catch (e) {
      d = function (e, t, r) {
        return (e[t] = r);
      };
    }
    function c(e, t, r, a) {
      var i = t && t.prototype instanceof v ? t : v,
        n = Object.create(i.prototype),
        o = new O(a || []);
      return s(n, "_invoke", { value: C(e, r, o) }), n;
    }
    function u(e, t, r) {
      try {
        return { type: "normal", arg: e.call(t, r) };
      } catch (e) {
        return { type: "throw", arg: e };
      }
    }
    t.wrap = c;
    var p = "suspendedStart",
      m = "suspendedYield",
      f = "executing",
      h = "completed",
      g = {};
    function v() {}
    function y() {}
    function w() {}
    var b = {};
    d(b, n, function () {
      return this;
    });
    var x = Object.getPrototypeOf,
      E = x && x(x(k([])));
    E && E !== r && a.call(E, n) && (b = E);
    var S = (w.prototype = v.prototype = Object.create(b));
    function T(e) {
      ["next", "throw", "return"].forEach(function (t) {
        d(e, t, function (e) {
          return this._invoke(t, e);
        });
      });
    }
    function M(e, t) {
      function r(s, i, n, o) {
        var l = u(e[s], e, i);
        if ("throw" !== l.type) {
          var d = l.arg,
            c = d.value;
          return c && "object" == bt(c) && a.call(c, "__await")
            ? t.resolve(c.__await).then(
              function (e) {
                r("next", e, n, o);
              },
              function (e) {
                r("throw", e, n, o);
              }
            )
            : t.resolve(c).then(
              function (e) {
                (d.value = e), n(d);
              },
              function (e) {
                return r("throw", e, n, o);
              }
            );
        }
        o(l.arg);
      }
      var i;
      s(this, "_invoke", {
        value: function (e, a) {
          function s() {
            return new t(function (t, s) {
              r(e, a, t, s);
            });
          }
          return (i = i ? i.then(s, s) : s());
        },
      });
    }
    function C(t, r, a) {
      var s = p;
      return function (i, n) {
        if (s === f) throw Error("Generator is already running");
        if (s === h) {
          if ("throw" === i) throw n;
          return { value: e, done: !0 };
        }
        for (a.method = i, a.arg = n; ; ) {
          var o = a.delegate;
          if (o) {
            var l = L(o, a);
            if (l) {
              if (l === g) continue;
              return l;
            }
          }
          if ("next" === a.method) a.sent = a._sent = a.arg;
          else if ("throw" === a.method) {
            if (s === p) throw ((s = h), a.arg);
            a.dispatchException(a.arg);
          } else "return" === a.method && a.abrupt("return", a.arg);
          s = f;
          var d = u(t, r, a);
          if ("normal" === d.type) {
            if (((s = a.done ? h : m), d.arg === g)) continue;
            return { value: d.arg, done: a.done };
          }
          "throw" === d.type && ((s = h), (a.method = "throw"), (a.arg = d.arg));
        }
      };
    }
    function L(t, r) {
      var a = r.method,
        s = t.iterator[a];
      if (s === e)
        return (
          (r.delegate = null),
          ("throw" === a && t.iterator.return && ((r.method = "return"), (r.arg = e), L(t, r), "throw" === r.method)) ||
          ("return" !== a && ((r.method = "throw"), (r.arg = new TypeError("The iterator does not provide a '" + a + "' method")))),
            g
        );
      var i = u(s, t.iterator, r.arg);
      if ("throw" === i.type) return (r.method = "throw"), (r.arg = i.arg), (r.delegate = null), g;
      var n = i.arg;
      return n
        ? n.done
          ? ((r[t.resultName] = n.value), (r.next = t.nextLoc), "return" !== r.method && ((r.method = "next"), (r.arg = e)), (r.delegate = null), g)
          : n
        : ((r.method = "throw"), (r.arg = new TypeError("iterator result is not an object")), (r.delegate = null), g);
    }
    function P(e) {
      var t = { tryLoc: e[0] };
      1 in e && (t.catchLoc = e[1]), 2 in e && ((t.finallyLoc = e[2]), (t.afterLoc = e[3])), this.tryEntries.push(t);
    }
    function I(e) {
      var t = e.completion || {};
      (t.type = "normal"), delete t.arg, (e.completion = t);
    }
    function O(e) {
      (this.tryEntries = [{ tryLoc: "root" }]), e.forEach(P, this), this.reset(!0);
    }
    function k(t) {
      if (t || "" === t) {
        var r = t[n];
        if (r) return r.call(t);
        if ("function" == typeof t.next) return t;
        if (!isNaN(t.length)) {
          var s = -1,
            i = function r() {
              for (; ++s < t.length; ) if (a.call(t, s)) return (r.value = t[s]), (r.done = !1), r;
              return (r.value = e), (r.done = !0), r;
            };
          return (i.next = i);
        }
      }
      throw new TypeError(bt(t) + " is not iterable");
    }
    return (
      (y.prototype = w),
        s(S, "constructor", { value: w, configurable: !0 }),
        s(w, "constructor", { value: y, configurable: !0 }),
        (y.displayName = d(w, l, "GeneratorFunction")),
        (t.isGeneratorFunction = function (e) {
          var t = "function" == typeof e && e.constructor;
          return !!t && (t === y || "GeneratorFunction" === (t.displayName || t.name));
        }),
        (t.mark = function (e) {
          return Object.setPrototypeOf ? Object.setPrototypeOf(e, w) : ((e.__proto__ = w), d(e, l, "GeneratorFunction")), (e.prototype = Object.create(S)), e;
        }),
        (t.awrap = function (e) {
          return { __await: e };
        }),
        T(M.prototype),
        d(M.prototype, o, function () {
          return this;
        }),
        (t.AsyncIterator = M),
        (t.async = function (e, r, a, s, i) {
          void 0 === i && (i = Promise);
          var n = new M(c(e, r, a, s), i);
          return t.isGeneratorFunction(r)
            ? n
            : n.next().then(function (e) {
              return e.done ? e.value : n.next();
            });
        }),
        T(S),
        d(S, l, "Generator"),
        d(S, n, function () {
          return this;
        }),
        d(S, "toString", function () {
          return "[object Generator]";
        }),
        (t.keys = function (e) {
          var t = Object(e),
            r = [];
          for (var a in t) r.push(a);
          return (
            r.reverse(),
              function e() {
                for (; r.length; ) {
                  var a = r.pop();
                  if (a in t) return (e.value = a), (e.done = !1), e;
                }
                return (e.done = !0), e;
              }
          );
        }),
        (t.values = k),
        (O.prototype = {
          constructor: O,
          reset: function (t) {
            if (((this.prev = 0), (this.next = 0), (this.sent = this._sent = e), (this.done = !1), (this.delegate = null), (this.method = "next"), (this.arg = e), this.tryEntries.forEach(I), !t))
              for (var r in this) "t" === r.charAt(0) && a.call(this, r) && !isNaN(+r.slice(1)) && (this[r] = e);
          },
          stop: function () {
            this.done = !0;
            var e = this.tryEntries[0].completion;
            if ("throw" === e.type) throw e.arg;
            return this.rval;
          },
          dispatchException: function (t) {
            if (this.done) throw t;
            var r = this;
            function s(a, s) {
              return (o.type = "throw"), (o.arg = t), (r.next = a), s && ((r.method = "next"), (r.arg = e)), !!s;
            }
            for (var i = this.tryEntries.length - 1; i >= 0; --i) {
              var n = this.tryEntries[i],
                o = n.completion;
              if ("root" === n.tryLoc) return s("end");
              if (n.tryLoc <= this.prev) {
                var l = a.call(n, "catchLoc"),
                  d = a.call(n, "finallyLoc");
                if (l && d) {
                  if (this.prev < n.catchLoc) return s(n.catchLoc, !0);
                  if (this.prev < n.finallyLoc) return s(n.finallyLoc);
                } else if (l) {
                  if (this.prev < n.catchLoc) return s(n.catchLoc, !0);
                } else {
                  if (!d) throw Error("try statement without catch or finally");
                  if (this.prev < n.finallyLoc) return s(n.finallyLoc);
                }
              }
            }
          },
          abrupt: function (e, t) {
            for (var r = this.tryEntries.length - 1; r >= 0; --r) {
              var s = this.tryEntries[r];
              if (s.tryLoc <= this.prev && a.call(s, "finallyLoc") && this.prev < s.finallyLoc) {
                var i = s;
                break;
              }
            }
            i && ("break" === e || "continue" === e) && i.tryLoc <= t && t <= i.finallyLoc && (i = null);
            var n = i ? i.completion : {};
            return (n.type = e), (n.arg = t), i ? ((this.method = "next"), (this.next = i.finallyLoc), g) : this.complete(n);
          },
          complete: function (e, t) {
            if ("throw" === e.type) throw e.arg;
            return (
              "break" === e.type || "continue" === e.type ? (this.next = e.arg) : "return" === e.type ? ((this.rval = this.arg = e.arg), (this.method = "return"), (this.next = "end")) : "normal" === e.type && t && (this.next = t),
                g
            );
          },
          finish: function (e) {
            for (var t = this.tryEntries.length - 1; t >= 0; --t) {
              var r = this.tryEntries[t];
              if (r.finallyLoc === e) return this.complete(r.completion, r.afterLoc), I(r), g;
            }
          },
          catch: function (e) {
            for (var t = this.tryEntries.length - 1; t >= 0; --t) {
              var r = this.tryEntries[t];
              if (r.tryLoc === e) {
                var a = r.completion;
                if ("throw" === a.type) {
                  var s = a.arg;
                  I(r);
                }
                return s;
              }
            }
            throw Error("illegal catch attempt");
          },
          delegateYield: function (t, r, a) {
            return (this.delegate = { iterator: k(t), resultName: r, nextLoc: a }), "next" === this.method && (this.arg = e), g;
          },
        }),
        t
    );
  }
  function Et(e, t) {
    (null == t || t > e.length) && (t = e.length);
    for (var r = 0, a = new Array(t); r < t; r++) a[r] = e[r];
    return a;
  }
  function St(e, t, r, a, s, i, n) {
    try {
      var o = e[i](n),
        l = o.value;
    } catch (e) {
      return void r(e);
    }
    o.done ? t(l) : Promise.resolve(l).then(a, s);
  }
  function Tt() {
    return Mt.apply(this, arguments);
  }
  function Mt() {
    var e;
    return (
      (e = xt().mark(function e() {
        var t, r, a, s, i, n, o, l, d, c, u;
        return xt().wrap(function (e) {
          for (;;)
            switch ((e.prev = e.next)) {
              case 0:
                return (
                  (c = function (e) {
                    for (var t = { url: "./img/icons/icon-pin.svg", size: new google.maps.Size(46, 60), origin: new google.maps.Point(0, 0), anchor: new google.maps.Point(0, 60) }, r = 0; r < d.length; r++) {
                      var a = d[r];
                      new google.maps.Marker({ position: { lat: a[0], lng: a[1] }, map: e, icon: t });
                    }
                  }),
                    (s = function () {
                      return window.matchMedia("(max-width:1023px)").matches ? [30.29792180575658, -97.70442469416504] : [30.292882, -97.739186];
                    }),
                    (e.next = 4),
                    google.maps.importLibrary("maps")
                );
              case 4:
                (t = e.sent),
                  (r = t.Map),
                  (a = [
                    { featureType: "water", elementType: "geometry", stylers: [{ color: "#e9e9e9" }, { lightness: 17 }] },
                    { featureType: "landscape", elementType: "geometry", stylers: [{ color: "#f5f5f5" }, { lightness: 20 }] },
                    { featureType: "road.highway", elementType: "geometry.fill", stylers: [{ color: "#ffffff" }, { lightness: 17 }] },
                    { featureType: "road.highway", elementType: "geometry.stroke", stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }] },
                    { featureType: "road.arterial", elementType: "geometry", stylers: [{ color: "#ffffff" }, { lightness: 18 }] },
                    { featureType: "road.local", elementType: "geometry", stylers: [{ color: "#ffffff" }, { lightness: 16 }] },
                    { featureType: "poi", elementType: "geometry", stylers: [{ color: "#f5f5f5" }, { lightness: 21 }] },
                    { featureType: "poi.park", elementType: "geometry", stylers: [{ color: "#dedede" }, { lightness: 21 }] },
                    { elementType: "labels.text.stroke", stylers: [{ visibility: "on" }, { color: "#ffffff" }, { lightness: 16 }] },
                    { elementType: "labels.text.fill", stylers: [{ saturation: 36 }, { color: "#333333" }, { lightness: 40 }] },
                    { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
                    { featureType: "transit", elementType: "geometry", stylers: [{ color: "#f2f2f2" }, { lightness: 19 }] },
                    { featureType: "administrative", elementType: "geometry.fill", stylers: [{ color: "#fefefe" }, { lightness: 20 }] },
                    { featureType: "administrative", elementType: "geometry.stroke", stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }] },
                  ]),
                  (i = s()),
                  (m = 2),
                  (n =
                    (function (e) {
                      if (Array.isArray(e)) return e;
                    })((p = i)) ||
                    (function (e, t) {
                      var r = null == e ? null : ("undefined" != typeof Symbol && e[Symbol.iterator]) || e["@@iterator"];
                      if (null != r) {
                        var a,
                          s,
                          i,
                          n,
                          o = [],
                          l = !0,
                          d = !1;
                        try {
                          if (((i = (r = r.call(e)).next), 0 === t)) {
                            if (Object(r) !== r) return;
                            l = !1;
                          } else for (; !(l = (a = i.call(r)).done) && (o.push(a.value), o.length !== t); l = !0);
                        } catch (e) {
                          (d = !0), (s = e);
                        } finally {
                          try {
                            if (!l && null != r.return && ((n = r.return()), Object(n) !== n)) return;
                          } finally {
                            if (d) throw s;
                          }
                        }
                        return o;
                      }
                    })(p, m) ||
                    (function (e, t) {
                      if (e) {
                        if ("string" == typeof e) return Et(e, t);
                        var r = Object.prototype.toString.call(e).slice(8, -1);
                        return (
                          "Object" === r && e.constructor && (r = e.constructor.name),
                            "Map" === r || "Set" === r ? Array.from(e) : "Arguments" === r || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(r) ? Et(e, t) : void 0
                        );
                      }
                    })(p, m) ||
                    (function () {
                      throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
                    })()),
                  (o = n[0]),
                  (l = n[1]),
                  (d = [[30.2911778, -97.7063988]]),
                  (u = new r(document.getElementById("map-contact"), {
                    zoom: 14,
                    center: new google.maps.LatLng(o, l),
                    mapTypeId: "roadmap",
                    scrollwheel: !1,
                    disableDefaultUI: !0,
                    navigationControl: !1,
                    mapTypeControl: !1,
                    scaleControl: !1,
                    draggable: !0,
                    styles: a,
                  })),
                  c(u);
              case 11:
              case "end":
                return e.stop();
            }
          var p, m;
        }, e);
      })),
        (Mt = function () {
          var t = this,
            r = arguments;
          return new Promise(function (a, s) {
            var i = e.apply(t, r);
            function n(e) {
              St(i, a, s, n, o, "next", e);
            }
            function o(e) {
              St(i, a, s, n, o, "throw", e);
            }
            n(void 0);
          });
        }),
        Mt.apply(this, arguments)
    );
  }
  function Ct(e, t) {
    var r = document.querySelector(e);
    if (document.body.contains(r))
      try {
        t();
      } catch (e) {
        console.log(e);
      }
  }
  document.addEventListener("DOMContentLoaded", function () {
    var e, t, r;
    (e = window.navigator.userAgent),
      (t = window.navigator.platform),
      (r = null),
      -1 !== ["Macintosh", "MacIntel", "MacPPC", "Mac68K"].indexOf(t)
        ? (r = "macOS")
        : -1 !== ["iPhone", "iPad", "iPod"].indexOf(t)
          ? (r = "iOS")
          : -1 !== ["Win32", "Win64", "Windows", "WinCE"].indexOf(t)
            ? (r = "Windows")
            : /Android/.test(e)
              ? (r = "Android")
              : !r && /Linux/.test(t) && (r = "Linux"),
    r && document.documentElement.classList.add(r),
      Ct("#header", he),
      Ct(".testimonial-slider", me),
      Ct(".project-slider", fe),
      Ct("#map-contact", Tt);
  }),

    window.addEventListener("load", function () {
      // Check if the animation has already run in this session
      const hasAnimationRun = sessionStorage.getItem('heroAnimationDone');

      (function () {
        var e = "easeOutQuint";
        document.querySelector("[data-animate-hero]")
          ? wt("[data-animate-hero]", function (t) {
            var r = gt.timeline({ easing: e }),
              a = t.querySelector(".hero-bg"),
              s = window.getComputedStyle(a)["border-bottom-left-radius"];
            r.add({
              targets: t.querySelector(".hero-bg"),
              width: ["0", "100%"],
              opacity: ["0.5", 1],
              delay: hasAnimationRun ? 0 : 400,
              duration: hasAnimationRun ? 0 : 1200
            }),
              r.add({
                targets: t.querySelector(".hero-notch-svg"),
                width: ["0", "100%"],
                duration: hasAnimationRun ? 0 : 400
              }),
            s && r.add({
              targets: t.querySelector(".hero-bg"),
              borderTopLeftRadius: [s, "0px"],
              duration: hasAnimationRun ? 0 : 10
            }, hasAnimationRun ? 0 : "-=380"),
              r.add({
                targets: document.querySelector("#header"),
                opacity: [0, 1],
                duration: hasAnimationRun ? 0 : 800
              }, "+=200"),
              r.add({
                targets: t.querySelector(".hero-container"),
                opacity: [0, 1],
                translateY: ["30px", 0],
                duration: hasAnimationRun ? 0 : 800
              }, hasAnimationRun ? 0 : "-=400");
          })
          : wt("#header", function (t) {
            gt.timeline({
              easing: e,
              duration: hasAnimationRun ? 0 : 800
            }).add({ targets: t, opacity: [0, 1] });
          });

        var t = "cubicBezier(0,0,.2,1)",
          r = 1e3;

        // Slide and Fade Animations
        wt("[data-slide-right]", function (e) {
          gt.timeline({ easing: t, duration: r })
            .add({
              targets: e,
              opacity: [0, 1],
              translateX: ["-".concat(50, "px"), 0]
            });
        });
        wt("[data-slide-left]", function (e) {
          gt.timeline({ easing: t, duration: r })
            .add({
              targets: e,
              opacity: [0, 1],
              translateX: ["".concat(50, "px"), 0]
            });
        });
        wt("[data-slide-up]", function (e) {
          gt.timeline({ easing: t, duration: r })
            .add({
              targets: e,
              opacity: [0, 1],
              translateY: ["".concat(50, "px"), 0]
            });
        });
        wt("[data-slide-down]", function (e) {
          gt.timeline({ easing: t, duration: r })
            .add({
              targets: e,
              opacity: [0, 1],
              translateY: ["-".concat(50, "px"), 0]
            });
        });
        wt("[data-fade-in]", function (e) {
          gt.timeline({ easing: t, duration: r })
            .add({ targets: e, opacity: [0, 1] });
        });

        // Footer Animation - Always runs
        var a = window.matchMedia("(min-width:768px)").matches;
        wt(
          "[data-animate-footer]",
          function (t) {
            var r = t.querySelector(".footer-frame-inner"),
              s = t.querySelector(".footer-bot"),
              i = s.querySelector(".footer-bot-content"),
              n = t.querySelector(".footer-top-inner"),
              o = n.querySelector(".footer-top-content"),
              l = gt.timeline({ easing: e, duration: 600 });
            a &&
            l
              .add({ targets: r, opacity: [0, 1], translateX: ["-100%", 0] })
              .add({ targets: s, rotateX: ["280deg", "360deg"] })
              .add({ targets: i, opacity: [0, 1] }, "-=400")
              .add({ targets: n, rotateX: ["280deg", "360deg"] }, "-=600")
              .add({ targets: o, opacity: [0, 1], scale: [0.96, 1] }, "-=200");
          },
          { rootMargin: "0px 0px -200px 0px", threshold: 0 }
        );

        // Set the flag in session storage so the animation doesn’t run again
        if (!hasAnimationRun) sessionStorage.setItem('heroAnimationDone', 'true');
      })();
    });

})();
